'use strict'
Ilduomo.controller('actualInventarioCtrl', 
function (
  $scope, 
  inventarioActualService, 
  inventarioPedidoService,
  $uibModal, 
  $log,
  $rootScope) {

  inventarioActualService.getProductos(function(response){
    $scope.productos = response.data
  })

  /**
  * modal para agregar un producto
  * @param {String} size Tamaño del modal
  */
  $scope.addProducto = function (size) {
    var modalInstance = $uibModal.open({
      templateUrl: 'modalStock.html',
      controller: 'stockModalInstanceCtrl',
      size: 'md',
      resolve: {
        productoStock: function () {
          return null;
        },
        titulo: function(){
          return "Agregar";
        },
        enable: function () {
          return true;
        }
      }
    })

    modalInstance.result.then(function (saveProducto) {
      inventarioActualService.saveProducto(saveProducto,function (response) {
        if (response.data.Estatus == "True") {
          //console.log(response.data.Estatus);
          $scope.productos.push(response.data.Producto);
          iziToast.success({
                title: 'Producto agregado correctamente',
                message: 'Marca: ['+ response.data.Producto.MarcaNombre+']',
                position: 'bottomRight',
                timeout: 2300
              })
        }else{
          iziToast.error({
                      title: 'Error el producto no ha podido agregarse',
                      message: 'Vuelve a intentarlo',
                      position: 'bottomRight',
                      timeout: 2300
                    })
        }
      },function (error) {
        $scope.error=error;
      });
    }, function () {
      $log.info('Modal dismissed')
    })
  }

  /**
  * modal para editar productos
  * @param  {String} size     tamaño del modal
  * @param  {Object} producto producto completo para editar
  * @return {Null}          
  */
  $scope.editProducto = function (size,producto) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modalStock.html',
        controller: 'stockModalInstanceCtrl',
        size: size,
        resolve: {
          productoStock: function () {
            return producto;
          },
          titulo: function(){
            return "Guardar";
          },
          enable: function () {
            return true;
          }
        }
      });

      modalInstance.result.then(function (updateProducto) {
        updateProducto._method = "PUT";
        inventarioActualService.updateProducto(updateProducto,function (response) {
          $scope.productos[ _.findIndex($scope.productos, function(p) {
                              return p._id == updateProducto._id
                          })] =  updateProducto;
        });
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
      });
  };

  $scope.dropProducto = function (size,p) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modalStock.html',
        controller: 'stockModalInstanceCtrl',
        size: 'md',
        resolve: {
          productoStock: function () {
            return p;
          },
          titulo: function(){
            return "Eliminar";
          },
          enable: function () {
            return false;
          }
          }
      });

  };


 
  /**
  * modal para añadir un pedido
  * @param  {String} size     tamaño del modal
  * @return {Null}          
  */
  $scope.addPedido = function (size) {
    var modalInstance = $uibModal.open({
      templateUrl: 'modalPedido.html',
      controller: 'pedidoModalInstanceCtrl',
      size: size,
      resolve: {
        productoPedido: function () {
          return null
        },
        titulo: function() {
          return "Agregar Pedido"
        },
        enable: function () {
          return true
        },
        pedidoProblema: function() {
          return false
        },
        edit: function() {
          return false
        }
      }
    })

    modalInstance.result.then(function (saveProducto) {
      saveProducto.usuarioId = $rootScope.currentUser._id
      saveProducto.precioTotal = _.sumBy(saveProducto.productos, function(p) { return (p.costo * p.cantidad); });
        inventarioPedidoService.saveProducto(saveProducto,function (response) {
        if (response.data.Estatus == "True") {
          iziToast.success({
            timeout: 1200,
            title: 'Mensaje',
            close: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            message: 'Pedido agregado',
            position: 'topRight'
          })
        }
      },function (error) {
        $scope.error=error;
      });
    }, function () {
      $log.info('Modal dismissed at: ' + new Date());
    });
  };

  $scope.discardRouteProduct = function(productoId,producto){
    inventarioActualService.discardRouteProduct(productoId,producto,function(response){
    })
  }
})

Ilduomo.
controller('stockModalInstanceCtrl', function(
  productoStock
  ,titulo
  ,inventariosService
  ,inventarioActualService
  ,$scope
  ,enable
  ,$uibModal
  ,$log
  ,$uibModalInstance
  ,$uibModalStack
  ){
  $scope.enable = !enable;
  $scope.titulo = titulo;
  $scope.productoStock = productoStock?productoStock:{};
  
  inventariosService.getViewModalInventario(function (response) {
    var info = response.data;
    $scope.productos = info.Productos;
    $scope.proveedores = info.Proveedores;
    $scope.presentaciones_catalogos = info.presentaciones_catalogos;
    $scope.unidades_medida = info.unidades_medida;
  });

  $scope.selectProducto = (item)=>{
    $scope.productoPedido.productoPrecioId = item._id;
  }

  $scope.getPresentacion = function(pid){
        if (!pid) return "";
        var n =_.find($scope.presentaciones_catalogos, function (pc) {
            return pc._id == pid;
        })||{};
        return n.Nombre||"";
    }

  $scope.getMedida = function(mid){
      // console.log(mid);
      if (mid == null || mid == undefined) return "";
      var n=_.find($scope.unidades_medida, function (um) {
          return um._id == mid;
      })||{};
      return  n.Nombre || "";
  }
  $scope.dropConfirmProducto = function (p) {
      //console.log(typeof p );
      var modalInstance = $uibModal.open({
        templateUrl: 'alertDropProducto.html',
        controller: 'stockModalInstanceCtrl',
        size: 'md',
        resolve: {
          productoStock: function () {
            return p;
          },
          titulo: function(){
            return "Eliminar";
          },
          enable: function () {
            return false;
          }
          }
      });

       modalInstance.result.then(function (dropProducto) {
         if (dropProducto.confirmar) {
           dropProducto._method = "DELETE";
           inventarioActualService.dropProducto(dropProducto,function (response) {
             if (response.data.Estatus == "True") {
               $scope.productos = _.remove($scope.productos, {_id:dropProducto._id});
               //let borrado = _.find($scope.productos, {id});
               //console.log(borrado);
               //console.log($scope.productos);
               iziToast.success({
                title: 'Producto eliminado correctamente',
                message: 'Marca: ['+ dropProducto.MarcaNombre+']',
                position: 'bottomRight',
                timeout: 2300
              })
             }else{
                 iziToast.error({
                      title: 'Error el producto no ha podido eliminarse',
                      message: 'Vuelve a intentarlo',
                      position: 'bottomRight',
                      timeout: 2300
                    })
             }
           });
         }
       }, function () {
         $log.info('Modal dismissed at: ' + new Date());
       });


  };

  $scope.ok = function () {
    //console.log("borrao");
    $scope.productoStock.confirmar = true;
    console.log($scope.productoStock)
    $uibModalInstance.close($scope.productoStock);
    $uibModalStack.dismissAll();

  }
  $scope.cancel = function () {
    //console.log("cancelao");
    $uibModalInstance.dismiss('cancel');

  }
});

