'use strict'
Ilduomo.controller('actividadesController', function ($scope, gerenteOperacionesService, $rootScope, $uibModal, $log) {
  gerenteOperacionesService.getActividadesGerente(function (response) {
      $scope.actividadesGerente = response.data[0]
    if ($scope.actividadesGerente) {
       $scope.actividadesGerenteCopia = $scope.actividadesGerente.slice()
    }
  })
  gerenteOperacionesService.getActividadesOperador(function (response) {
      $scope.actividadesOperador = response.data[0]
    if ($scope.actividadesOperador) {
       $scope.actividadesOperadorCopia = $scope.actividadesOperador.slice()
    }
  })
  $scope.huboCambioOperador = false
  $scope.huboCambioGerente = false
  $scope.mover = function (actividades, old_index, new_index, tablaGerente) {
    actividades.splice(new_index, 0, actividades.splice(old_index, 1)[0])
    if (tablaGerente) {
      if (!$scope.arreglosIguales(actividades, $scope.actividadesGerenteCopia)) {
        $scope.huboCambioGerente = true
      } else {
        $scope.huboCambioGerente = false
      }
    } else {
      if (!$scope.arreglosIguales(actividades, $scope.actividadesOperadorCopia)) {
        $scope.huboCambioOperador = true
      } else {
        $scope.huboCambioOperador = false
      }
    }
  }

  $scope.arreglosIguales = function (arr1, arr2) {
    if (arr1.length != arr2.length)         { return false }

      for (var i = 0, l = arr1.length; i < l; i++) {
          if (arr1[i] != arr2[i]) {
              return false
          }
      }
      return true
  }

  $scope.guardar = function (nuevoArreglo, tablaGerente) {
    if (tablaGerente) {
      gerenteOperacionesService.setActividadesGerente(nuevoArreglo, function (response) {
          $scope.actividadesGerenteCopia = nuevoArreglo.slice()
        $scope.huboCambioGerente = false
      })
    } else {
      gerenteOperacionesService.setActividadesOperador(nuevoArreglo, function (response) {
          $scope.actividadesOperadorCopia = nuevoArreglo.slice()
        $scope.huboCambioOperador = false
      })
    }
  }

  $scope.eliminar = function (size, a, arreglo, tablaGerente) {
    var modalInstance = $uibModal.open({
       templateUrl: 'eliminarActividad.html',
       controller: 'eliminarActividadModalInstance',
       size: size,
       resolve: {
          titulo: function () {
              return 'Eliminar actividad'
          },
          actividad: function () {
            return a
          }
       }
     })

     modalInstance.result.then(function () {
         var indice = arreglo.indexOf(a)
         arreglo.splice(indice, 1)
         $scope.guardar(arreglo, tablaGerente)
     }, function () {
       $log.info('Modal dismissed at: ' + new Date())
     })
  }

  $scope.editar = function (size, a, arreglo, tablaGerente) {
    var modalInstance = $uibModal.open({
       templateUrl: 'actualizarActividades.html',
       controller: 'actualizarActividadesModalInstance',
       size: size,
       resolve: {
          titulo: function () {
              return 'Editar actividad'
          },
          actividad: function () {
            return a
          }
       }
     })

     modalInstance.result.then(function (r) {
         var indice = arreglo.indexOf(a)
         arreglo[indice] = r
         $scope.guardar(arreglo, tablaGerente)
     }, function () {
       $log.info('Modal dismissed at: ' + new Date())
     })
  }

  $scope.agregar = function (size, arreglo, tablaGerente) {
    var modalInstance = $uibModal.open({
       templateUrl: 'actualizarActividades.html',
       controller: 'actualizarActividadesModalInstance',
       size: size,
       resolve: {
          titulo: function () {
              return 'Agregar actividad'
          },
          actividad: function () {
            return ''
          }
       }
     })

     modalInstance.result.then(function (r) {
         arreglo.push(r)
         $scope.guardar(arreglo, tablaGerente)
     }, function () {
       $log.info('Modal dismissed at: ' + new Date())
     })
  }
})

Ilduomo.controller('eliminarActividadModalInstance', function (
    titulo,
    $uibModalInstance,
    $scope,
    actividad) {
  $scope.titulo = titulo
  $scope.actividad = actividad
  $scope.ok = function () {
    $uibModalInstance.close()
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('actualizarActividadesModalInstance', function (
    titulo,
    $uibModalInstance,
    $scope,
    actividad) {
  $scope.titulo = titulo
  $scope.actividad = actividad
  $scope.actividadCopia = actividad
  $scope.ok = function () {
    $uibModalInstance.close($scope.actividad)
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})
