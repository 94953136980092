'use strict'
Ilduomo.controller('themePanelCallcenterController', function(
  $scope,
  $rootScope,
  $state,
  clienteService
) {
  App.initThemePanel()

  $scope.buscar = function() {
    if ($scope.Telefono) {
      var search = $scope.Telefono
      var tipo = 'telefono'
    } else if ($scope.Clave) {
      var search = $scope.Clave
      var tipo = 'clave'
    } else if ($scope.NombreCliente) {
      var search = $scope.NombreCliente
      var tipo = 'nombreCliente'
    } else if ($scope.Calle) {
      var search = $scope.Calle
      var tipo = 'calle'
    } else if ($scope.Mascota) {
      var search = $scope.Mascota
      var tipo = 'mascota'
    } else {
      var search = undefined
      var tipo = undefined
    }
    var buscar = { tipo: tipo, busqueda: search }
    $scope.Telefono = null
    $scope.Clave = null
    $scope.Calle = null
    $scope.Mascota = null
    $scope.NombreCliente = null
    var targetContainer = '.theme-panel'
    var targetClass = 'active'
    if ($(targetContainer).hasClass(targetClass)) {
      $(targetContainer).removeClass(targetClass)
    }
    delete $rootScope.clientes
    // clienteService.busqueda(buscar, function (response) {
    //   $rootScope.clientes = response.data.clientes.data;
    //   $rootScope.itemsPerPage = response.data.clientes.per_page;
    //   $rootScope.current_page = response.data.clientes.current_page;
    //   $rootScope.lastPage = response.data.clientes.last_page;
    //   $rootScope.next_page_url = response.data.clientes.prev_page_url;
    //   $rootScope.next_page_url = response.data.clientes.next_page_url;
    //   $rootScope.totalClientes = response.data.clientes.total;
    // });
    $state.go('clienteClientes', { buscar: buscar, pedir: false })
  }
})
