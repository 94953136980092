'use strict';
Ilduomo.controller('operadoresCobrosCtrl', function (
  $scope,
  dataService,
  $uibModal,
  $log,
  $state) {
 
  $scope.cobros = []
  dataService.getCobrosOperadores(function (response) {
    $scope.cobros = response.data
    $scope.totalCobros = _.sumBy($scope.cobros, function(c){
      if(!c.Pagado) {
        return c.Monto
      }
    })
    $scope.totalCobros = $scope.totalCobros.toString()
    $scope.totalCobros =  + $scope.totalCobros.substring(0, $scope.totalCobros.length-3) + ',' + $scope.totalCobros.substring($scope.totalCobros.length-3)
    $scope.totalCobros = '$' + $scope.totalCobros 
  })

  $scope.parseDate = function (f) {
    if (!f) return '' 
    return Date.parse(f)
  }

  /**
    * Edicion del cobro a realizar, se ocupara más que nada para
    * poner una fecha y/o asignar quién deberá realizarlo
    * @param  {String} size Tamaño del modal que se abrirá
    * @param  {Object} c    Objecto de cobro que se realizará
    */
  $scope.editCobro = function (size, c) {
    var modalInstance = $uibModal.open({
      templateUrl: 'agregarCobroOperador.html',
      controller: 'agregarCobroOperadorModalInstance',
      size: size,
      resolve:{
        titulo: function () {
            return "Actualizar"
        },
        cobro: function () {
          return c
        },
        editar: function () {
          return true
        }
      }
    })
    modalInstance.result.then(function (cobro) {
      cobro._method = "PUT";
      dataService.actualizaCobroOperador(cobro, function (response) {
        if(response.data.Estatus === 'True') {
          let index = $scope.cobros.findIndex(c => c._id === cobro._id)
          $scope.cobros[index] = cobro
          $scope.cobros[index].Fecha = response.data.Fecha
        }
      })
    }, function () {
      $log.info('Modal dismissed at: ' + new Date());
    })
  }

  /**
    *Confirma pago del cobro
    *@param {Object} cobro objeto de cobro que se dará por confirmado
    *
  */

  $scope.confirmarCobro = function(cobro) {
    var modalInstance = $uibModal.open({
      templateUrl: 'preguntaModalConfirmarCobro.html',
      controller: 'preguntaModalConfirmarCobroModalInstanceCtrl',
      size: 'md',
      resolve: {
        cobro: function() {
          return cobro
        }
      }
    })
    modalInstance.result.then(
      function(cobro) {
        dataService.pagaCobro(cobro, function(response) {
          if (response.data.Estatus === 'True') {
            let index = $scope.cobros.findIndex(co => co._id === response.data.Cobro._id)
            $scope.cobros[index] = response.data.Cobro
          }
        })
    }, function() {
      $log.info('Modal dismissed at: ' + new Date())
    })
  }
  
  /**
    * Edicion del cobro a realizar, se ocupara más que nada para
    * poner una fecha y/o asignar quién deberá realizarlo
    * @param  {String} size Tamaño del modal que se abrirá
    * @param  {Object} c    Objecto de cobro que se realizará
    */
  $scope.eliminar = function (size, c) {
    var modalInstance = $uibModal.open({
      templateUrl: 'agregarCobroOperador.html',
      controller: 'agregarCobroOperadorModalInstance',
      size: size,
      resolve:{
        titulo: function () {
            return "Eliminar"
        },
        cobro: function () {
          return c
        },
        editar: function () {
          return false
        }
      }
    })

    modalInstance.result.then(function (r) {
      r._method = "DELETE";
      dataService.actualizaCobroOperador(r, function (response) {
        if(response.data.Estatus == 'True'){
          $scope.cobros = _.remove($scope.cobros, function (co) {
            return co._id != r._id;
          });
        }else{
          console.log("There somes errors");
        }
      })
    }, function () {
      $log.info('Modal dismissed at: ' + new Date());
    })
  }

  $scope.mostrarAgendado = function (size, c) {
    dataService.getInfoServicio(c.AgendadoId, c.NegocioId, false, function (response) {
      var servicio = response.data;
      servicio.mascotas = [];
      _.forEach(servicio.agendado, function (a) {
          a.mascota = _.find(servicio.cliente.mascotas, function (m) {
              return m._id == a.MascotaId;
          });
          if(servicio.mascotas.indexOf(a.mascota) < 0){
              servicio.mascotas.push(a.mascota);
          }
      });
      var modalInstance = $uibModal.open({
        templateUrl: 'modalShowServicio.html',
        controller: 'showServicioModalInstanceCtrl',
        size: size,
        resolve: {
          servicio: function () {
            return servicio
          }, servicioP: function () {
            return null
          }, 
          fromServicesGrid: function() {
            return false
          }
        }
      })

      modalInstance.result.then(function (response) {
          if (response.Cancelado) {
              servicio.Estatus = 'Cancelado'
          }else if(response.Reagendado){
              servicio.Estatus = 'Reagendado'
          }else{
              servicio.Estatus = 'Confirmado'
          }
      }, function () {
          $log.info('Modal dismissed at: ' + new Date());
      })
    })
  }

   /**
    * Agregar un nuevo cobro para los operadores
    * @param {String} size Tamaño del modal que se abrirá
    */
   $scope.addCobro = function (size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'agregarCobroOperador.html',
        controller: 'agregarCobroOperadorModalInstance',
        size: size,
        resolve:{
          titulo: function () {
              return "Agregar";
          },
          cobro: function () {
            return {};
          },
          editar: function(){
            return true;
          }
        }
      })

      modalInstance.result.then(function (r) {
        dataService.saveCobroOperador(r, function (response) {
          if (response.data.Estatus == "True") {
            $state.reload()
          }
        })
      }, function () {
        $log.info('Modal dismissed at: ' + new Date());
      })
   }
})


Ilduomo.controller('agregarCobroOperadorModalInstance', function (
  $scope,
  titulo,
  dataService,
  cobro,
  editar,
  $uibModalInstance
) {
  $scope.editar = editar
  $scope.titulo = titulo
  $scope.cobro = cobro

  let date = new Date().setHours(9,0,0)
  
  $scope.cobro.Fecha = $scope.cobro.Fecha 
    ? Date.parse($scope.cobro.Fecha.date)
    : date
  $scope.time = new Date($scope.cobro.Fecha)

  $scope.dateOptions = {
    startingDay: 1
  }

  $scope.minDate = new Date(new Date().setDate(new Date().getDate()));

  $scope.$watch('cobro.Fecha', function (f) {
    date = new Date(f)
    date.setHours($scope.time.getHours(), $scope.time.getMinutes(), 0)
    $scope.time = date
  }) 
  $scope.formatDate='dd-MM-yyyy';

  $scope.openProgramarFecha = function ($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.FechaisOpen = true
  }
  
  dataService.getInformacionOperadores(function (response) {
    $scope.operadores = response.data
  })

  $scope.ok = function () {
    $scope.cobro.Fecha = $scope.time
    $scope.cobro.user = $scope.operadores.filter(o => o.UserId === $scope.cobro.UserId)[0]['Informacion']
    $uibModalInstance.close($scope.cobro)
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('preguntaModalConfirmarCobroModalInstanceCtrl', function(
  $scope,
  $uibModalInstance,
  cobro
) {
  $scope.caption = false
  $scope.tiposPago = [
    'Efectivo',
    'TPV',
    'Transferencia - Depósito',
    'Terminado no pagado',
    'Cheque',
  ]
  $scope.cobro = cobro
  $scope.cobro.tipoPago = $scope.cobro.tipoPago || null

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
  $scope.ok = function() {
    if($scope.cobro.tipoPago !== '') {
      $uibModalInstance.close($scope.cobro)
    } else {
      $scope.caption = true
    }
  }
})