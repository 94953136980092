'use strict'
/**
 * Trae todo el log de llamadas hechas de un clientes
 * @param  {Alcance} $scope        Solo de este controller
 * @param  {Alcance} $rootScope    Alcance global
 * @param  {Service} dataService   Servicio para traer todo el log de llamadas, este hace las peticiones
 * @param  {ClienteId} $stateParams)
 * @return {Void}               Nada
 */
Ilduomo.controller('logsController', [
  '$scope',
  '$rootScope',
  'dataService',
  '$stateParams',
  function($scope, $rootScope, dataService, $stateParams) {
    $rootScope.setting.layout.pageSidebarMinified = true
    dataService.getAllCall($stateParams.clienteId, function(response) {
      $scope.llamadas = response.data.data
      $scope.itemsPerPage = response.data.per_page
      $scope.lastPage = response.data.last_page
      $scope.next_page_url = response.data.next_page_url
      $scope.prev_page_url = response.data.prev_page_url
      $scope.total = response.data.total
      $scope.current_page = response.data.current_page
    })

    $scope.parseDate = d => {
      return Date.parse(d)
    }

    $scope.ultima = () => {
      if ($scope.next_page_url) {
        var p = $scope.next_page_url.split('=')
      } else {
        var p = $scope.prev_page_url.split('=')
      }
      dataService.getFromUrl(p[0] + '=' + $scope.lastPage, function(response) {
        $scope.llamadas = response.data.data
        $scope.itemsPerPage = response.data.per_page
        $scope.lastPage = response.data.last_page
        $scope.next_page_url = response.data.next_page_url
        $scope.prev_page_url = response.data.prev_page_url
        $scope.total = response.data.total
        $scope.current_page = response.data.current_page
      })
    }

    $scope.primera = () => {
      if ($scope.next_page_url) {
        var p = $scope.next_page_url.split('=')
      } else {
        var p = $scope.prev_page_url.split('=')
      }
      dataService.getFromUrl(p[0] + '=1', function(response) {
        $scope.llamadas = response.data.data
        $scope.itemsPerPage = response.data.per_page
        $scope.lastPage = response.data.last_page
        $scope.next_page_url = response.data.next_page_url
        $scope.prev_page_url = response.data.prev_page_url
        $scope.total = response.data.total
        $scope.current_page = response.data.current_page
      })
    }

    $scope.cambia = pag => {
      if ($scope.next_page_url) {
        var p = $scope.next_page_url.split('=')
      } else {
        var p = $scope.prev_page_url.split('=')
      }
      dataService.getFromUrl(p[0] + '=' + pag, function(response) {
        $scope.llamadas = response.data.data
        $scope.currentPage = response.data.current_page
        $scope.itemsPerPage = response.data.per_page
        $scope.lastPage = response.data.last_page
        $scope.next_page_url = response.data.next_page_url
        $scope.prev_page_url = response.data.prev_page_url
        $scope.total = response.data.total
        $scope.current_page = response.data.current_page
      })
    }
  }
])
