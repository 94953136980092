(function(window,$) {
    'use strict';
    /**
    * Ilduomo Module
    *
    * Controlador de busqueda
    */
   Ilduomo.
     controller('buscarCtrl', ['$auth','$rootScope','$scope','$location','dataService', buscarCtrl]);

     function buscarCtrl($auth,$rootScope,$scope,$location,dataService){
        
        $scope.buscar={};
        $scope.buscar.currentEstado={};
        $scope.buscar.currentPais={};
        $scope.buscar.currentColonia={};
        $scope.buscar.currentMunicipio={};
        $scope.buscar.currentEntidad={};
        $scope.buscar.currentServicioOfrecido={};
        $scope.buscar.colonia = {};
        $scope.suggestColony = false;

        dataService.getPaises(function(response) {
            $scope.paises=response.data;
            $scope.buscar.paisId="";
            $scope.buscar.currentPais.Nombre = "Pais";
        },function(error){
            $scope.error = error;
        });
        // Traer Entidades Disponibles
        dataService.getEntidades(function(response) {
            $scope.entidades=response.data;
            $scope.buscar.entidadId="";
            $scope.buscar.currentEntidad.Nombre = "Entidad";
        },function(error){
            $scope.error = error;
        });
        // Traer Municipios Disponibles
        dataService.getMunicipios(function(response) {
            $scope.municipios=response.data;
            $scope.buscar.entidadId="";
            $scope.buscar.currentMunicipio.Nombre = "Municipio";
        },function(error){
            $scope.error = error;
        });

        // dataService.getAllColonias(function(response) {
        //     $scope.colonias=response.data;
        //     $scope.buscar.coloniaId="";
        //     $scope.buscar.currentColonia.Nombre = "";
        //     $scope.buscar.colonia.Nombre = "";
        // },function(error){
        //     $scope.error = error;
        // });

        dataService.getServiciosOfrecidos(function(response){
            $scope.serviciosOfrecidos = response.data.sort();
            $scope.buscar.currentServicioOfrecido.Nombre = "Servicio";
        },function(error){
            $scope.error = error;
        });

        $scope.estatus={};
        $scope.error='';
        $scope.cliente={};
        $scope.buscar.currentEstado="Estatus";
        // obtener los estados posibles del cliente mediante dataService
        dataService.getEstatusDisponibles(function(response) {
            $scope.estatus = response.data;
        },function(error){
            $scope.error = error;
        });

        $scope.showDatePicker = function (abrir) {
            $('#'+abrir+' .datepicker').datepicker({
                autoclose: true,
                todayHighlight: true
            });
        }
        $scope.setEstatus = function(estatus) {
            $scope.buscar.currentEstado = estatus;
        }
        $scope.setColonia = function(colonia) {
            $scope.buscar.currentColonia = colonia;
            $scope.buscar.colonia.Nombre = colonia.Nombre.capitalize();
            $scope.suggestColony = false;
        }
        $scope.setMunicipio = function(municipio) {
            $scope.buscar.currentMunicipio = municipio;
            console.log($scope.buscar.currentMunicipio);
            dataService.getColonias($scope.buscar.currentMunicipio._id,function(response){
                $scope.colonias=response.data;
                $scope.buscar.coloniaId="";
                $scope.buscar.currentColonia.Nombre = "";
                $scope.buscar.colonia.Nombre = "";
            },function(error){
                $scope.error = error;

            });
        }
        $scope.showSuggestColony = function () {
            $scope.suggestColony = true;
        }
        $scope.setEntidad = function(entidad) {
            $scope.buscar.currentEntidad = entidad;
        }
        $scope.setService = function(servicio) {
            $scope.buscar.currentServicioOfrecido = servicio;
        }


     }

})(window,jQuery);