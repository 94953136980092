'use strict';
Ilduomo.controller('HomeController', ['$scope','$rootScope','userService', function($scope,$rootScope,userService){
    console.log("Inside HomeController");
    $scope.error={};
    // Get user rols
    userService.getUserRols($rootScope.currentUser._id,function(response) {
        $rootScope.currentUser.roles = response.data;
    },
    function (error) {
        $scope.error=error;
    });
    // console.log($rootScope.currentUser);
}]);