'use strict';
Ilduomo.controller('alertaController', function (
    $scope,
    $mdToast, 
    $mdDialog,
    $state,
    alerta,
    fecha,
    clienteService) {
    $scope.alerta = alerta;
    $scope.cliente = alerta.cliente;
    $scope.date = fecha;


    var isDlgOpen;
    $scope.closeToast = function() {
        if (isDlgOpen) return;
        $mdToast
          .hide()
          .then(function() {
            isDlgOpen = false;
          });
      };


    $scope.openMoreInfo = function(ev) {
      $mdDialog.show({
        controller: 'alertClientModalController',
        templateUrl: 'modalAlertaCliente.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        resolve: {
          alerta: function () {
            return $scope.alerta;
          },
          cliente: function () {
            return $scope.cliente;
          }
        },
        clickOutsideToClose:true,
        fullscreen: true // Only for -xs, -sm breakpoints.
      })
      .then(function(cliente) {
        console.log(cliente);
        if (cliente.auxiliarAgendar) {
          //$state.
        }
      }, function() {
        $scope.status = 'You cancelled the dialog.';
      });
    };
});

Ilduomo.
controller('alertClientModalController', function (
  $mdDialog, 
  $scope,
  alerta,
  clienteService) {
  $scope.alerta = alerta;
  var cliente;
  $scope.clientes = [];
  clienteService.getCliente(alerta.ClienteId, function (response) {
    cliente = response.data;
    $scope.clientes.push(cliente);
  });

  $scope.ok = function(agendar) {
    cliente.auxiliarAgendar = agendar;
    $mdDialog.hide(cliente);
  };
});