'use strict';
Ilduomo.controller('pedidosInventarioCtrl', 
  function (
    $rootScope, 
    $scope, 
    inventarioPedidoService, 
    inventarioActualService,
    $uibModal, 
    $log) {
   
  $scope.parseDate = (d) => Date.parse(d);

  $scope.changePage = function(pag){
    if ($scope.next_page_url) {
        var p=$scope.next_page_url.split("=");
    }else{
        var p=$scope.prev_page_url.split("=");
    }
    inventarioPedidoService.getProductosUrl(p[0]+'='+pag, function(response){
        $scope.pedidos = response.data.data;
        $scope.total = response.data.total;
        $scope.itemsPerPage = response.data.per_page;
        $scope.current_page = response.data.current_page;
        $scope.next_page_url = response.data.next_page_url;
        $scope.prev_page_url = response.data.prev_page_url;
        $scope.last_page = response.data.last_page;
    });
}

$scope.init = function(){
    inventarioPedidoService.getProductos(function (response) {
        $scope.pedidos = response.data.data;
        $scope.total = response.data.total;
        $scope.per_page = response.data.per_page;
        $scope.current_page = response.data.current_page;
        $scope.next_page_url = response.data.next_page_url;
        $scope.prev_page_url = response.data.prev_page_url;
        $scope.last_page = response.data.last_page;
        $scope.itemsPerPage = response.data.per_page;
    });
}
$scope.init();

	//Modals
	/**
	 * modal para agregar un pedido
	 * @param {String} size Tamaño del modal
	 */
/*   	$scope.addPedido = function (size) {
        var modalInstance = $uibModal.open({
          templateUrl: 'modalPedido.html',
          controller: 'pedidoModalInstanceCtrl',
          size: 'lg',
          resolve: {
            productoPedido: function () {
              return null;
            },
            titulo: function(){
              return "Agregar Producto";
            },
             enable: function () {
               return true;
             }
          }
        });

        modalInstance.result.then(function (saveProducto) {
        	saveProducto.usuarioId = $rootScope.currentUser._id;
            saveProducto.precioTotal = $scope.precioTotal
          	inventarioPedidoService.saveProducto(saveProducto,function (response) {
            if (response.data.Estatus == "True") {
              debugger
              //$scope.productos.push(response.data.pedido);
            }
          },function (error) {
            $scope.error=error;
          });
        }, function () {
          $log.info('Modal dismissed at: ' + new Date());
        });
   	};
*/
	   $scope.editProducto = function (size,producto) {
         var modalInstance = $uibModal.open({
           templateUrl: 'modalPedido.html',
           controller: 'pedidoModalInstanceCtrl',
           size: size,
           resolve: {
             productoPedido: function () {
               return producto;
             },
             titulo: function(){
               return "Editar Producto";
             },
             enable: function () {
               return true;
             },
             edit: function(){
              return true
             }
           }
         });

         modalInstance.result.then(function (updateProducto) {
           updateProducto._method = "PUT";
           inventarioPedidoService.updateProducto(updateProducto,function (response) {
           });
         }, function () {
           $log.info('Modal dismissed at: ' + new Date());
         });
     };

     $scope.dropProducto = function (size,p) {
        var modalInstance = $uibModal.open({
          templateUrl: 'modalPedido.html',
          controller: 'pedidoModalInstanceCtrl',
          size: 'lg',
          resolve: {
            productoPedido: function () {
              return p;
            },
            titulo: function(){
              return "Eliminar";
            },
            enable: function () {
              return false;
            },
             edit: function(){
              return true
             }
          }
        });

         modalInstance.result.then(function (dropProducto) {
           if (dropProducto.confirmar) {
             dropProducto._method = "DELETE";
             inventarioPedidoService.dropProducto(dropProducto,function (response) {
               if (response.data.Estatus == "True") {
                 $scope.pedidos = _.remove($scope.pedidos, function (p) {
                   return p._id != dropProducto._id;
                 });
               }
             });
           }
         }, function () {
           $log.info('Modal dismissed at: ' + new Date());
         });
    };

  $scope.confirmarPedido= function(pedido){
    pedido.usuarioIdConfirma = $rootScope.currentUser._id;
    inventarioPedidoService.confirmRecepcion(pedido,function (response) {
      if (response.data.Estatus == "True") {
        pedido.Estatus = response.data.EstatusPedido;
      }
    });
  }

  $scope.descartarProducto= function(servicioId,producto){
    inventarioActualService.descartarProducto(servicioId,producto,function (response) {
      if (response.data.Estatus == "True") {
        producto.confirmado = true;
      }
    });
  }

  $scope.mandarPorPedir= function(servicioId,producto){
    inventarioActualService.mandarPorPedir(servicioId,producto,function (response) {
      if (response.data.Estatus == "True") {
        producto.confirmado = true;
      }
    });
  }

});

Ilduomo.controller('pedidoModalInstanceCtrl', 
function(
  productoPedido,
  titulo,
  inventariosService,
  inventarioActualService,
  $scope,
  enable,
  $uibModalInstance,
  edit
){
  $scope.titulo = titulo;
  $scope.productoPedido = productoPedido || {}
  $scope.enable = !enable;
  var date = new Date();
  $scope.minDate = date;
  $scope.productoPedido.Fecha_llegada = (productoPedido) ? new Date($scope.productoPedido.Fecha_llegada.date) : date;
  $scope.selectProductos = (productoPedido)?$scope.productoPedido.productoPrecioId:null;
  inventariosService.getViewModalInventario(function (response) {
    var info = response.data;
    $scope.productos_base = info.Productos;
    $scope.proveedores = info.Proveedores;
    $scope.presentaciones_catalogos = info.presentaciones_catalogos;
    $scope.unidades_medida = info.unidades_medida
    edit ? $scope.getProductosPedido() : $scope.getPorPedir()
    $scope.productoPedido.proveedorId = $scope.proveedores[0]._id;
    $scope.$watch('productoPedido.proveedorId',function (newValue) {
      var proveedor = _.find($scope.proveedores, function (p) {
        return p._id == newValue;
      });
      if (proveedor) {
        if (proveedor.Nickname == "VETLINE PROPLAN") {
          var marca = "Pro Plan";
          $scope.productos =  _.filter($scope.productos_base,function (p) {
            return p.MarcaNombre == marca;
          });
        }else if (proveedor.Nickname == "PETZOO HILLS") {
          var marca = "Hill's";
          $scope.productos =  _.filter($scope.productos_base,function (p) {
            return p.MarcaNombre == marca;
          });
        }else if(proveedor.Nickname == "AMIKOO PEEK EUKANUBA"){
          var marca = "Eukanuba";
          $scope.productos =  _.filter($scope.productos_base,function (p) {
            return p.MarcaNombre == marca;
          });
        }else if(proveedor.Nickname == "CAVA"){
          var marca =  ["Diamond","Óptimo","Nupec"]
          $scope.productos =  _.filter($scope.productos_base,function (p) {
            return p.MarcaNombre == marca[0] || p.MarcaNombre == marca[1] || p.MarcaNombre == marca[2];
          });
        }else if (proveedor.Nickname == "Royal Canin" || proveedor.Nickname == "Hubika") {
          var marca = "Royal Cannin";
          $scope.productos =  _.filter($scope.productos_base,function (p) {
            return p.MarcaNombre == marca;
          });
        }
      }
    });
  });

  $scope.getPorPedir = function(){
    inventarioActualService.getProductosPorPedir(function (response) {
      $scope.productosporpedir = []
      let producto = {}
      _.forEach(response.data.Productos, function(p){
        producto = {}
        producto.nombre = 
          p.producto.ProductoNombre + ' ' +
          p.producto.Presentacion + ' ' +
          $scope.getMedida(p.producto.MedidaId) +
          ' (' + $scope.getPresentacion(p.producto.PresentacionId) + ')' + 
          p.producto.MarcaNombre
        producto.productoPrecioId = p.producto._id
        producto.cantidad = p.PorPedir
        producto.costo = p.Costo
        producto.pedir = false
        producto.confirmado = false
        $scope.productosporpedir.push(producto)
      })
    })
  }

  $scope.getProductosPedido = function(){
    $scope.productosporpedir = productoPedido.Productos
  }

  $scope.selectOption = (p)=>{
    var producto = {}
    producto.nombre = p.ProductoNombre+" "+
                      p.Presentacion+" "+$scope.getMedida(p.MedidaId)+
                      " ("+$scope.getPresentacion(p.PresentacionId)+")"+ 
                      p.MarcaNombre
    producto.productoPrecioId = p._id
    producto.cantidad = 1
    producto.costo = p.Costo
    producto.pedir = true
    producto.confirmado = false
    $scope.productosporpedir.push(producto)
    $scope.calculaPrecioTotal()
  }

  
  $scope.dateOptions = {
      startingDay: 1
  };
  $scope.formatDate = 'dd-MMMM-yyyy';
  $scope.openCalendar = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.isOpen = true;
  };

  $scope.getPresentacion = function(pid){
        if (!pid) return "";
        var n =_.find($scope.presentaciones_catalogos, function (pc) {
            return pc._id == pid;
        })||{};
        return n.Nombre||"";
    }

  $scope.getMedida = function(mid){
      if (mid == null || mid == undefined) return "";
      var n=_.find($scope.unidades_medida, function (um) {
          return um._id == mid;
      })||{};
      return  n.Nombre || "";
  }
  $scope.calculaPrecioTotal = function(){
    $scope.precioTotal = 0
    _.forEach(_.filter($scope.productosporpedir,function(p){return p.pedir}),function(p){
      $scope.precioTotal += (p.costo * p.cantidad)
    })
  }

  $scope.ok = function () {
    $scope.productoPedido.productos = _.filter($scope.productosporpedir, function(p) { return p.pedir; });
    $scope.productoPedido.confirmar = true
    $uibModalInstance.close($scope.productoPedido);
  }
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  }
});

Ilduomo.controller('detailsOrderModalInstanceCtrl', function(
  $scope,
  $uibModalInstance,
  titulo,
  pedido
  ){
  $scope.titulo = titulo 
  $scope.productos = pedido.Productos
  $scope.ok = function() {
    $uibModalInstance.close()
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})