'use strict';

Ilduomo.controller('historicoClientesController', function (
  clienteService,
  $stateParams,
  $rootScope,
  $scope) {
  $rootScope.setting.layout.pageSidebarMinified = true;
  $scope.clienteId = $stateParams.clienteId;
  $scope.negocioId = $stateParams.negocioId;
  clienteService.getHistoricoServicio($stateParams.clienteId, $stateParams.negocioId, function (response) {
    $scope.historico = response.data;
  });

});