'use strict';
	Ilduomo.controller('ClienteCtrl',['$http','$scope','dataService',
        function ($http,$scope,dataService) {
            // Declarar las variables a utilizar
            var newTelefono={};
            $scope.cliente = {};
            $scope.cliente.telefonos = [];
            $scope.cliente.datosBasicos = {};
            $scope.cliente.ubicacion = {};
            $scope.suggestColony=false;
            
            $scope.listTelefonos=[];
            $scope.listContactos=[];
            
            $scope.estatus = {};
            $scope.Titulos = {};
            $scope.colonias = {};
            $scope.currentColonia={};
            $scope.municipios ={};
            $scope.entidades ={};
            $scope.error ={};
            $scope.search = {};
            console.log("Inside ClienteCtrl.js");
            $scope.currentColonia.Nombre= ""
            // Traer los Titulos disponibles
            dataService.getTitulos(function(response) {
                $scope.Titulos = response.data;
                $scope.currentTitulo="Selecciona Titulo";
            },function(error){  
                $scope.error = error;
            });
             // Traer los Tipos de telefonos disponibles
            dataService.getTiposTelefonos(function(response) {
                $scope.tiposTelefonos = response.data;
                $scope.cliente.tipoTelefono="Tipo Telefono";
            },function(error){  
                $scope.error = error;
            });
            // Traer Paises Disponibles
            dataService.getPaises(function(response) {
                $scope.paises=response.data;
                $scope.cliente.paisId="";
                $scope.currentPais = "Pais";
            },function(error){
                $scope.error = error;
            });
            // Traer Entidades Disponibles
            dataService.getEntidades(function(response) {
                $scope.entidades=response.data;
                $scope.cliente.entidadId="";
                $scope.currentEntidad = "Entidad";
            },function(error){
                $scope.error = error;
            });
            // Traer Municipios Disponibles
            dataService.getMunicipios(function(response) {
                $scope.municipios=response.data;
                $scope.cliente.entidadId="";
                $scope.currentMunicipio = "Municipio";
            },function(error){
                $scope.error = error;
            });




            $scope.addTelefono = function(){
                var auxtelefono = {};
                auxtelefono.TipoTelefonoId = "";
                auxtelefono.Numero = "";
                $scope.cliente.telefonos.push(auxtelefono);
                console.log($scope.cliente.telefonos);
            }
            $scope.deleteTelefono = function (elemento) {
                // console.log(elemento);
                $scope.listTelefonos.splice(elemento,1);
            };

            $scope.addContacto = function(){
                // newContacto.relacion = ;
                // newContacto.tipo= ;
                // newContacto.nombre
                // newContacto.apellidos
                // newContacto.telefono

                $scope.listContactos.push($scope.newContacto);
                console.log($scope.newContacto);
                $scope.newContacto = {};
            }
            $scope.deleteContacto = function (elemento) {
                // console.log(elemento);
                $scope.listContactos.splice(elemento,1);
            };


            $scope.addRowMascotaForm = function(){
                // agregar mascota renglon hacer la directiva
                console.log("Macota Directive");
            };

            $scope.setTitulo = function (titulo) {
                $scope.cliente.datosBasicos.titulo = titulo;
                $scope.currentTitulo = titulo;
            };
            $scope.setTipoTelefono = function (tipo) {
                $scope.cliente.tipoTelefono = tipo;
            };
            $scope.setPaisCliente = function (pais){
                $scope.cliente.ubicacion.paisId = pais._id;
                $scope.currentPais = pais.Nombre;
            };
            $scope.setEntidadCliente = function (entidad){
                $scope.cliente.ubicacion.entidadId = entidad._id;
                $scope.currentEntidad = entidad.Nombre;
            };
            $scope.setMunicipioCliente = function (municipio){
                $scope.cliente.ubicacion.municipioId = municipio._id;
                $scope.currentMunicipio = municipio.Nombre;
                $scope.currentColonia.Nombre="";
                dataService.getColonias($scope.cliente.ubicacion.municipioId,function(response){
                    $scope.colonias = response.data;
                },function(error){  
                    $scope.error = error;
                })
            };
            $scope.setColoniaCliente = function(colonia){
                $scope.currentColonia = colonia;
                $scope.cliente.ubicacion.coloniaId = colonia._id;
                $scope.suggestColony=false;
            };

            $scope.showSuggestColony = function () {
                $scope.suggestColony = true;
            }


            $scope.guardaCliente=function(){
                // dataService.guardaCliente($scope.cliente);
                console.log("GuardarCliente");
            };
     }]);