'use strict';
Ilduomo.controller("comisionClienteNuevoController", function(
    $scope,
    $uibModal,
    $log,
    dataService){
    angular.element(document).ready(function () {
        $scope.comisiones = [];
        dataService.getComisiondia(moment().format("DD-MM-YYYY"), function (response) {
            $scope.comisiones = response.data;
        });
        $scope.rangoComisiones = moment().format("MMMM D, YYYY");
        $('#fecha-comision').daterangepicker({
            opens: 'right',
            format: 'MM/DD/YYYY',
            separator: ' to ',
            startDate: moment().subtract(3,'days'),
            endDate: moment(),
            minDate: '01/01/2013',
            maxDate: '12/31/2030',
        },
        function (start, end) {
            $('#fecha-comision input').val(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            dataService.getComisiondediaadia(start.format('DD-MM-YYYY'),end.format('DD-MM-YYYY'), function (response) {
                $scope.comisiones = response.data;
            })
        });

        $scope.parsedate = function (f) {
            return Date.parse(f);
        }

        $scope.dropComision = function (c) {
            c._method = "DELETE";
            dataService.borraComision(c, function (response) {
                if (response.data.Estatus == "True") {
                    $scope.comisiones = _.remove($scope.comisiones, function (p) {
                        return p._id != c._id;
                    });
                }
            });
        }

        $scope.addComision = function (size) {
            var modalInstance = $uibModal.open({
                templateUrl: 'nuevaComision.html',
                controller: 'nuevaComisionModalInstance',
                size: size,
                resolve:{
                    titulo: function () {
                        return "Agregar comisión";
                    }
                }
              });

              modalInstance.result.then(function (c) {
                dataService.agregaComision(c, function (response) {
                    if (response.data.Estatus == "True") {
                        iziToast.success({
                            title: 'OK',
                            timeout: 800,
                            resetOnHover: true,
                            position: 'topRight',
                            transitionIn: 'flipInX',
                            transitionOut: 'flipOutX',
                            message: 'se agrego la comision',
                        });
                        $scope.comisiones.push(response.data.comision);
                    }
                });
              }, function () {
                $log.info('Modal dismissed at: ' + new Date());
              });
        }
   
    });
});

Ilduomo.controller('nuevaComisionModalInstance', function (
    $scope,
    $uibModalInstance,
    dataService,
    titulo) {
    dataService.getInformacionOperadores(function (response) {
        $scope.operadores = response.data;
    });
    $scope.titulo = titulo;
    $scope.comision = {};
    $scope.comision.concepto = "Comisión por cliente nuevo generado";

    // =========datePicker
    $scope.dateOptions = {
      startingDay: 1,
      minDate: new Date(new Date().setDate(new Date().getDate()))
    };
    // CHECK: fecha minima?
    $scope.formatDate='dd-MM-yyyy';

    $scope.openFechaComision = function ($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.FechaisOpen = true;
    }
    // ===========

    $scope.ok = function () {
        $uibModalInstance.close($scope.comision);
    }
    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    }
});