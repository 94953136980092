'use strict';
Ilduomo.controller('operadoresRutaCtrl',function (
    $scope,
    dataService,
    $rootScope,
    $stateParams,
    $state
    ) {
    /**
     * Obtiene el mes formateado para enviar las peticiones necesarias
     * @return {String} Mes en dos digitos siempre
     */
    Date.prototype.getMonthFormatted = function() {
      var month = this.getMonth() + 1;
      return month < 10 ? '0' + month : '' + month; // ('' + month) for string result
    }

    /**
     * Obtiene el dia formateado para enviar peticiones necesarias
     * @return {String} dia siempre en dos digitos
     */
    Date.prototype.getDayFormatted = function() {
      var day = this.getDate();
      return day < 10 ? '0' + day : '' + day; // ('' + month) for string result
    }

    $rootScope.setting.layout.pageSidebarMinified = true;
    $scope.TotalMascotas = 0;
    var date = new Date();
    $scope.minDate = date;
    var max = new Date(date.getDate()+1);
    $scope.max = max;
    $scope.fecha = date;

    $scope.dateOptions = {
        startingDay: 1
    };

    if ($stateParams.fecha) {
       $scope.fecha = (function (fecha) {
          var d = fecha.split('-')
          return new Date(d[2], d[1] - 1, d[0])
        })($stateParams.fecha)
    } else {
      $scope.fecha = date
  }

    /**
     * Hace la petición de las camionetas disponibles
     */
    dataService.getCamionetas(function (response) {
      $scope.camionetas = response.data;
      $scope.Camioneta = _.find($scope.camionetas, function (c) {
        return c.Nombre == 'SPA 1';
      });
    });
  

    $scope.formatDate = 'dd-MMMM-yyyy';
    $scope.openRutaFecha = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.isOpen = true;
    };
    $scope.formatedDate = function (f) {
      if (f) {
        return Date.parse(f);
      }
    };
    $scope.formatedDuracion = function (d) {
      if (d) {
        return Math.floor(d/60)+':'+Math.floor(d%60);
      }
    };
    
    /**
     * Observa la fecha por posibles cambios, solo en caso de que cambie de valor hace la peticion 
     * de la ruta de la camioneta
     * @param  {Date} newValue  Nuevo valor de la fecha
     * @param  {Date} oldValue Valor anterior de la fecha
     */
    $scope.$watch('fecha', function(newValue, oldValue) {

      if (newValue != oldValue) {
        $scope.fechaFormatted = newValue.getDayFormatted()+'-'+newValue.getMonthFormatted()+'-'+newValue.getFullYear();
        $state.go('.', {fecha: $scope.fechaFormatted}, {notify: false})

        if ($scope.Camioneta._id) {
          dataService.getRutaOperadorView($scope.Camioneta._id,$scope.fechaFormatted, function (response) {
            var info = response.data;
            $scope.agendado = info.Servicios[$scope.fechaFormatted]
            $scope.$apply();
          });
        }
      }

    });

    /**
     * Observa las camionetas por posibles cambios, solo en caso de que cambie de valor hace la peticion 
     * de la ruta de la camioneta y la fecha indicada
     * @param  {Object/Camioneta} newValue  Nuevo valor de la Camioneta
     * @param  {Object/Camioneta} oldValue Valor anterior de la Camioneta
     */
    $scope.$watch('Camioneta', function(newValue, oldValue) {
      if (newValue != oldValue) {
        $scope.fechaFormatted = $scope.fecha.getDayFormatted()+'-'+$scope.fecha.getMonthFormatted()+'-'+$scope.fecha.getFullYear();
          dataService.getRutaOperadorView($scope.Camioneta._id, $scope.fechaFormatted, function (response) {
            var info = response.data;
            $scope.agendado = info.Servicios[$scope.fechaFormatted]
            //$scope.$apply();
          });
      }
    });

    /**
     * realiza los calculos necesarios para presentar totales de dinero, mascotas y traslados
     * @param  {Object} newValue  Servicios agendados
     * @param  {Object} oldValue) Servicios agendados
     */
    $scope.$watch('agendado', function(newValue, oldValue) {
      $scope.totalMascotas = 0;
      $scope.totalTraslados = 0;
      $scope.totalDinero = 0;
      _.forEach($scope.agendado, function (a) {
        if (a.servicios) {
          $scope.totalMascotas += a.servicios[0].Servicios;
          $scope.totalTraslados += 1;
          $scope.totalDinero += a.servicios[0].PrecioTotal;
        }
      });
    });

});