'use strict'
Ilduomo.controller('headerCallcenterController', function(
  $scope,
  $state,
  clienteService,
  dataService,
  $rootScope,
  $uibModal,
  $log,
  socket,
  $cookies
) {
  $rootScope.notifications = $cookies.getObject('notifications') || []
  $scope.minutes = 0
  /**
   * obtiene el mes formateado a dos digitos
   * @return {Date} mes con formato 01,10, etc..
   */
  Date.prototype.getMonthFormatted = function() {
    var month = this.getMonth() + 1
    return month < 10 ? '0' + month : '' + month // ('' + month) for string result
  }
  /**
   * Obtiene el dia formateado a dos digitos siempre
   * @return {Date} dia con formato 01,02,10,23,etc..
   */
  Date.prototype.getDayFormatted = function() {
    var day = this.getDate()
    return day < 10 ? '0' + day : '' + day // ('' + month) for string result
  }
  function calcRoute(start, end) {
    var directionsService = new google.maps.DirectionsService()
    // var directionsDisplay = new google.maps.DirectionsRenderer()
    var request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING',
      drivingOptions: {
        departureTime: new Date(Date.now()) // for the time N milliseconds from now.
        // trafficModel: 'best_guess'
      }
    }
    directionsService.route(request, function(result, status) {
      if (status == 'OK') {
        var myRoute = result.routes[0].legs[0]
        if (myRoute.duration_in_traffic.value !== undefined)
          $scope.minutes = Math.trunc(myRoute.duration_in_traffic.value / 60)
        return Math.trunc(myRoute.duration_in_traffic.value / 60)
        return 0
      } else return 0
    })
  }
  function setAddress(dirs) {
    var geocoder = new google.maps.Geocoder()
    // var i = 0
    let direcciones = []
    _.forEach(dirs, function(d, i) {
      if (d !== undefined) {
        if (
          d.direccion !== undefined &&
          d.direccion.Coordenadas !== undefined &&
          d.direccion.Coordenadas !== null
        ) {
          var vlat = parseFloat(d.direccion.Coordenadas.lat)
          var vlng = parseFloat(d.direccion.Coordenadas.lng)
          var myLatLng = new google.maps.LatLng({ lat: vlat, lng: vlng }, false)
          direcciones[i] = myLatLng
        } else {
          var col = d.colonia !== undefined ? d.colonia : ''
          var calle =
            d.direccion !== undefined && d.direccion.Calle !== undefined
              ? d.direccion.Calle
              : ''
          var num =
            d.direccion !== undefined &&
            d.direccion['Numero Externo'] !== undefined
              ? d.direccion['Numero Externo']
              : ''
          geocoder.geocode(
            { address: calle + ' ' + num + ', ' + col + ' Ciudad de México' },
            function(results, status) {
              if (status == 'OK') {
                direcciones[i] =
                  calle + ' ' + num + ', ' + col + ' Ciudad de México'
              } else {
                return 0
              }
            }
          )
        }
      }
      // ++i
    })
    setTimeout(function() {
      if (direcciones[0] !== undefined && direcciones[1] !== undefined)
        return calcRoute(direcciones[0], direcciones[1])
      else return 0
    }, 1000)
  }

  // socket.on('callClientButton', function(data) {
  //   let c = JSON.parse(data)
  //   // console.log(c)
  //   iziToast.show({
  //     class: 'cliente' + c.phone,
  //     color: '#1399E3',
  //     timeout: false,
  //     title: 'Marcame!!',
  //     icon: 'fa fa-phone',
  //     close: false,
  //     transitionIn: 'flipInX',
  //     transitionOut: 'flipOutX',
  //     layout: 3,
  //     message: 'Marcar: ' + c.name + ' ' + c.phone,
  //     position: 'topRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
  //     buttons: [
  //       [
  //         '<button>Confirmar</button>',
  //         function(instance, toast) {
  //           instance.hide({ transitionOut: 'flipOutX' }, toast)
  //           alert('Mostrar la info del cliente obtenida por el evento')
  //         }
  //       ],
  //       [
  //         '<button>Close</button>',
  //         function(instance, toast) {
  //           // cerrar
  //           instance.hide({ transitionOut: 'flipOutX' }, toast)
  //         }
  //       ]
  //     ]
  //   })
  // })

  // socket.on('reagendar', function (data) {
  //   data = JSON.parse(data)
  //   if (data.reloadCamionetas) {
  //     let today = new Date()
  //     today = today.getDayFormatted() + '-' + today.getMonthFormatted() + '-' + today.getFullYear()
  //     dataService.getAgendadoElDia(today, (response) => {
  //       localStorage.setItem('camionetasToday', JSON.stringify(response.data.camionetas))
  //     })
  //   }
  // })

  socket.on('cobros', function(data) {
    var c = JSON.parse(data)
    // console.log(c);
    $.gritter.add({
      title: 'Alerta',
      text:
        'Cobrado $' +
        c.Monto +
        ' a cliente: ' +
        c.ClienteId +
        ' <br> por ' +
        c.user.Nombre +
        ' ' +
        c.user.Apellidos +
        " <br/> por concepto: '" +
        c.Concepto +
        "'",
      sticky: true,
      time: '',
      class_name: 'my-sticky-class'
    })
    var a = new Date()
    $rootScope.notifications.push({
      text: 'Cobro de ' + c.Monto + ' Realizado',
      user: c.user.Nombre + ' ' + c.user.Apellidos,
      CobroId: c._id,
      cobro: true,
      time: a
    })
    $cookies.putObject('notifications', $rootScope.notifications)
    $rootScope.$apply()
    // console.log(data);
  })
  // socket.emit('disconnect');

  socket.on('userLocked', function(data) {
    var cam = JSON.parse(data)
    iziToast.error({
      class: 'notif',
      color: 'red',
      timeout: 4000,
      title: 'Operador',
      close: true,
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      layout: 3,
      message: cam.Nombre + ' bloqueado por falta de permisos',
      position: 'bottomLeft',
      buttons: [
      [
        '<button>Cerrar</button>',
        function(instance, toast) {
          instance.hide({ transitionOut: 'flipOutX' }, toast)
        }
        ]
      ]
    })
  })

  socket.on('Confirmacion', function(data) {
    var conf = JSON.parse(data)
    // console.log(conf)

    // $rootScope.confirmarServicio = $cookies.getObject('confirmarServicio') || []
    // $rootScope.confirmarServicio = _.remove($rootScope.confirmarServicio, (cs) => {
    //   return cs.idServicio != conf.idServicio
    // })
    // var a = new Date()
    // var expiresValue = new Date(a)
    // expiresValue.setMinutes(a.getMinutes() + 30)
    // $cookies.putObject('confirmarServicio', $rootScope.confirmarServicio, {'expires': expiresValue})
    // Aqui filtraba para encontrar solo el agente que confirma, pero no es necesario
    // ya que con dos ventanas se llenaria la otra de notificaciones
    // if (conf.agenteId != $rootScope.currentUser._id) {
    var nodes = document.querySelectorAll('.notif' + conf.camionetaId)
    if (nodes.length != 0) {
      // var toast = _.find(nodes, (n) => {
      //   var interCamioneta = _.words(n.innerHTML)[27] + ' ' + _.words(n.innerHTML)[28]
      //   return interCamioneta == conf.camionetaNombre
      // })
      // iziToast.hide({
      //   transitionOut: 'flipOutX'
      // }, toast)
      iziToast.hide({ transitionOut: 'flipOutX' }, nodes[0])
    }
    var preNotificacion = document.querySelectorAll(
      '.preNotif' + conf.camionetaId
    )
    if (preNotificacion.length > 0)
      _.forEach(preNotificacion, function(v, k) {
        iziToast.hide({ transitionOut: 'fadeOutUp' }, preNotificacion[k])
      })
  })

  socket.on('clienteGenerado', function(data) {
    if (
      $rootScope.Administrador ||
      $rootScope.GerenteCallcenter ||
      $rootScope.Monitor
    ) {
      var conf = JSON.parse(data)
      console.log(conf)
      iziToast.show({
        class: 'notif',
        color: 'blue',
        timeout: false,
        title: 'Generado',
        icon: 'ico-info',
        close: true,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        layout: 3,
        message: conf.Nombre + ' ha capturado un cliente nuevo',
        position: 'bottomLeft'
      })
    }
  })

  socket.on('preConfirmarServicio', function(data) {
    var pre = JSON.parse(data)
    var nodes = document.querySelectorAll('.preNotif' + pre.camionetaId)
    if (nodes.length != 0) {
      return
    }

    var pconf = $cookies.getObject('preconfId')
    console.log(pconf)
    console.log("datos: " + pre.camionetaId + pre.idServicio)

    if (
      pconf &&
      pconf.find(p => p.id === pre.camionetaId && p.serv === pre.idServicio)
    ) {
      console.log("encontrado")
      return
    }
    $rootScope.$apply()
    if (
      document.querySelectorAll('.preNotif' + pre.camionetaId).length != 0
    ) {
      return
    }
    let fecha = new Date()
    var camionetasToday = null
    fecha = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
    if (localStorage.getItem('camionetasToday')) {
      if(!$rootScope  || !$rootScope.camionetas) {
        $rootScope.camionetas = JSON.parse(localStorage.getItem('camionetasToday'))
      }
      else if ($rootScope.camionetas && $rootScope.camionetas[0][fecha] === undefined) {
        camionetasToday = JSON.parse(localStorage.getItem('camionetasToday'))
        _.forEach($rootScope.camionetas, function(ca){
          _.find(camionetasToday, function(ct){
            if (ca._id === ct._id) {
              ca[fecha] = Object.assign({}, ct[fecha])
            }
          })
        })
      }
    }
    let cam = Object.assign({}, $rootScope.camionetas.find( c => c._id === pre.camionetaId))
    let order = _.orderBy(cam[fecha], (s) => s.servicios && Date.parse(s.servicios[0].Fecha.date))
    let s = order.find( o => o.servicios && o.servicios[0].Estatus === 'Espera')
    console.log("s encontrado:")
    console.log(s)
    console.log("de: ")
    console.log(cam)
    let act = order.find(aux => aux.servicios && aux.servicios[0]._id === pre.idServicio)
    if(s === undefined) { return }
    if(s !== undefined && act !== undefined) {
      let pconf = $cookies.getObject('preconfId') ? $cookies.getObject('preconfId') : []
      let expireDate = new Date()
      expireDate.setDate(expireDate.getDate() + 1)
      pconf.push({ id:pre.camionetaId, serv: pre.idServicio})
      //5am (GMT es -5 en verano)
      $cookies.putObject('preconfId', pconf, { 'expires':  new Date(expireDate.setHours(0, 0, 0)) } )
      if(s.servicios[0].cliente && act.servicios[0].cliente) {
        var obj = [
          {
            colonia: act.servicios[0].Colonia || null,
            direccion: act.servicios[0].cliente.direccion || null //pa k no se mueran
          },
          {
            colonia: s.servicios[0].Colonia || null,
            direccion: s.servicios[0].cliente.direccion || null
          }
        ]
        var minutes = setAddress(obj)
      }
    }
    s.servicios[0].preconfirmado = true
    $scope.$apply()
    $rootScope.$apply()
    setTimeout(function() {
      if (sessionStorage.getItem('preConfirmados')) {
        var preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
        if(preconf !== null && preconf.findIndex(pre => pre.servicio === s.servicios[0]._id) !== -1) {
          preconf.push({id: pre.camionetaId, servicio: s.servicios[0]._id})
          sessionStorage.setItem(
            'preConfirmados',
            JSON.stringify(preconf)
          )
        } else if (preconf === null || preconf.length === 0) {
          sessionStorage.setItem(
            'preConfirmados',
            JSON.stringify([{id: pre.camionetaId, servicio: s.servicios[0]._id}])
          )
        }
      } else {
        sessionStorage.setItem(
          'preConfirmados',
          JSON.stringify([{id: pre.camionetaId, servicio: s.servicios[0]._id}])
        )
      }
      iziToast.show({
        class: 'preNotif' + pre.camionetaId,
        color: 'rgba(255, 242, 124, 0.9)',
        timeout: false,
        title: 'Pre-confirmacion',
        icon: 'ico-info',
        close: false,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        layout: 3,
        message:
          'Está por terminar servicio: ' +
          pre.nombreCamioneta +
          ($scope.minutes !== 0
            ? ' <br /> (Traslado (aprox): ' + $scope.minutes + ' min)'
            : ''),
        position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        buttons: [
          [
            '<button>Detalles</button>',
            function(instance, toast) {
              instance.hide({ transitionOut: 'flipOutX' }, toast)
              let fecha = new Date()
              fecha = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
              if (($rootScope === undefined || $rootScope.camionetas[0][fecha] === undefined) &&
                localStorage.getItem('camionetasToday')) {
                camionetasToday = JSON.parse(localStorage.getItem('camionetasToday'))
              } else {
                camionetasToday = $rootScope.camionetas
              }
              let cam = Object.assign({}, camionetasToday.find( c => c._id === pre.camionetaId))
              let order = _.orderBy(cam[fecha], (s) => s.servicios && Date.parse(s.servicios[0].Fecha.date))
              let s = order.find( o => o.servicios && o.servicios[0].Estatus === 'Espera')
              dataService.getInfoServicio(
                s.servicios[0]._id,
                s.servicios[0].NegocioId,
                true,
                function(response) {
                  $scope.servicio = response.data
                  $scope.servicio.mascotas = []
                  _.forEach($scope.servicio.agendado, function(a) {
                    a.mascota = _.find(
                      $scope.servicio.cliente.mascotas,
                      function(m) {
                        return m._id == a.MascotaId
                      }
                    )
                    if ($scope.servicio.mascotas.indexOf(a.mascota) < 0) {
                      $scope.servicio.mascotas.push(a.mascota)
                    }
                  })
                  var modalInstance = $uibModal.open({
                    templateUrl: 'modalShowServicio.html',
                    controller: 'showServicioModalInstanceCtrl',
                    size: 'md',
                    resolve: {
                      servicio: function() {
                        return $scope.servicio
                      },
                      servicioP: function() {
                        return null
                      }, 
                      fromServicesGrid: function() {
                        return false
                      }
                    }
                  })

                  modalInstance.result.then(
                    function(response) {
                      // instance.hide({ transitionOut: 'flipOutX' }, toast)
                      if (response.Cancelado) {
                        $scope.servicio.Estatus = 'Cancelado'
                      } else if (response.Reagendado) {
                        $scope.servicio.Estatus = 'Reagendado'
                      } else {
                        $scope.servicio.Estatus = 'Confirmado'
                        if ($scope.servicio.preconfirmado) {
                          $scope.servicio.preconfirmado = false
                          $scope.$apply()
                        } //ya se quitó su estatus de preconfirmación
                        // if(sessionStorage.getItem('preConfirmados')){
                        //   preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
                        //   var index = preconf.findIndex(p => p.id === $scope.servicio.CamionetaId)
                        //   if (index !== -1) { preconf.splice(index, 1) }
                        //   sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
                        // }
                      }
                      // $rootScope.notifications = _.remove($rootScope.notifications, function(n){
                      //     if (!c.idServicio) {
                      //         return n;
                      //     }else if(c.idServicio != $scope.muestra.idServicio){
                      //         return n;
                      //     }
                      // });
                      // $cookies.putObject("notifications",$rootScope.notifications);
                    },
                    function() {
                      $log.info('Modal dismissed at: ' + new Date())
                    }
                  )
                }
              )
            }
          ],
          [
            '<button>Close</button>',
            function(instance, toast) {
              // cerrar
              instance.hide({ transitionOut: 'flipOutX' }, toast)
            }
          ]
        ],
        onOpen: function(instance, toast) {}
      })
    }, 2000)
  })

  socket.on('confirmarServicio', function(data) {
    var c = JSON.parse(data)
    var a = new Date()
    console.log(c)
    var nodes = document.querySelectorAll('.notif' + c.camionetaId)
    if (nodes.length != 0) {
      return
    }
    var preNotificacion = document.querySelector('.preNotif' + c.camionetaId) // Selector of your toast
    if (preNotificacion != null) {
      iziToast.hide({ transitionOut: 'fadeOutUp' }, preNotificacion)
    }
    if($cookies.getObject('preconfId')) {
      var pconf = $cookies.getObject('preconfId')
      pconf = pconf.filter(s => s.id !== c.camionetaId)
      var expireDate = new Date()
      expireDate.setDate(expireDate.getDate() + 1)
      $cookies.putObject('preconfId', pconf, { 'expires': new Date(expireDate.setHours(0, 0, 0)) } )
    }
    // var expiresValue = new Date(a)
    // expiresValue.setMinutes(a.getMinutes() + 10)
    // $rootScope.confirmarServicio = $cookies.getObject('confirmarServicio') || []
    // $rootScope.confirmarServicio.push(c)
    // $cookies.putObject('confirmarServicio', $rootScope.confirmarServicio, {'expires': expiresValue})
    $rootScope.$apply()
    iziToast.show({
      class: 'notif' + c.camionetaId,
      color: 'rgba(253, 102, 102, 0.9)',
      timeout: false,
      title: 'Confirmar',
      icon: 'ico-info',
      close: false,
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      layout: 3,
      message: 'Confirmar servicio: ' + c.nombreCamioneta,
      position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
      buttons: [
        [
          '<button>Confirmar</button>',
          function(instance, toast) {
            instance.hide({ transitionOut: 'flipOutX' }, toast)
            dataService.getInfoServicio(
              c.idServicio,
              c.negocioId,
              true,
              function(response) {
                $scope.servicio = response.data
                $scope.servicio.mascotas = []
                _.forEach($scope.servicio.agendado, function(a) {
                  a.mascota = _.find($scope.servicio.cliente.mascotas, function(
                    m
                  ) {
                    return m._id == a.MascotaId
                  })
                  if ($scope.servicio.mascotas.indexOf(a.mascota) < 0) {
                    $scope.servicio.mascotas.push(a.mascota)
                  }
                })
                var modalInstance = $uibModal.open({
                  templateUrl: 'modalShowServicio.html',
                  controller: 'showServicioModalInstanceCtrl',
                  size: 'md',
                  resolve: {
                    servicio: function() {
                      return $scope.servicio
                    },
                    servicioP: function() {
                      return null
                    },
                    fromServiceGrid: function() {
                      return false
                    }
                  }
                })

                modalInstance.result.then(
                  function(response) {
                    // instance.hide({ transitionOut: 'flipOutX' }, toast)
                    if (response.Cancelado) {
                      $scope.servicio.Estatus = 'Cancelado'
                    } else if (response.Reagendado) {
                      $scope.servicio.Estatus = 'Reagendado'
                    } else {
                      $scope.servicio.Estatus = 'Confirmado'
                      if ($scope.servicio.preconfirmado) {
                        $scope.servicio.preconfirmado = false
                        $scope.$apply()
                      } //ya se quitó su estatus de preconfirmación
                      // if(sessionStorage.getItem('preConfirmados')){
                      //   preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
                      //   var index = preconf.findIndex(p => p.id === pre.camionetaId)
                      //   if (index !== -1) { preconf.splice(index, 1) }
                      //   sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
                      // }
                    }
                    // $rootScope.notifications = _.remove($rootScope.notifications, function(n){
                    //     if (!c.idServicio) {
                    //         return n;
                    //     }else if(c.idServicio != $scope.muestra.idServicio){
                    //         return n;
                    //     }
                    // });
                    // $cookies.putObject("notifications",$rootScope.notifications);
                  },
                  function() {
                    $log.info('Modal dismissed at: ' + new Date())
                  }
                )
              }
            )
          }
        ],
        [
          '<button>Close</button>',
          function(instance, toast) {
            // cerrar
            instance.hide({ transitionOut: 'flipOutX' }, toast)
          }
        ]
      ],
      onOpen: function(instance, toast) {}
    })
  })

  /**
   * Muestra a detalle la notificación seleccionada
   * @param  {Object} n    Notificación a mostrar a detalle
   * @param  {String} size Tamaño a abrir el modal para mostrar la información
   * @return {Null}
   */
  $scope.showDetails = function(servicio, size) {
    if (servicio.confirmar) {
      dataService.getInfoServicio(
        servicio.idServicio,
        servicio.negocioId,
        false,
        function(response) {
          if (response.data.userConfirmarId != $rootScope.user._id) {
            return // termina la ejecución de la funcion
          }
          $scope.servicio = response.data
          $scope.servicio.mascotas = []
          _.forEach($scope.servicio.agendado, function(a) {
            a.mascota = _.find($scope.servicio.cliente.mascotas, function(m) {
              return m._id == a.MascotaId
            })
            if ($scope.servicio.mascotas.indexOf(a.mascota) < 0) {
              $scope.servicio.mascotas.push(a.mascota)
            }
          })
          var modalInstance = $uibModal.open({
            templateUrl: 'modalShowServicio.html',
            controller: 'showServicioModalInstanceCtrl',
            size: 'md',
            resolve: {
              servicio: function() {
                return $scope.servicio
              },
              servicioP: function() {
                return null
              },
              fromServicesGrid: function(){
                return false
              }
            }
          })

          modalInstance.result.then(
            function(response) {
              if (response.Cancelado) {
                $scope.servicio.Estatus = 'Cancelado'
              } else if (response.Reagendado) {
                $scope.servicio.Estatus = 'Reagendado'
              } else {
                $scope.servicio.Estatus = 'Confirmado'
                if ($scope.servicio.preconfirmado) {
                  $scope.servicio.preconfirmado = false
                  $scope.$apply()
                } //ya se quitó su estatus de preconfirmación
                // if(sessionStorage.getItem('preConfirmados')){
                //   preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
                //   var index = preconf.findIndex(p => p.id === pre.camionetaId)
                //   if (index !== -1) { preconf.splice(index, 1) }
                //   sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
                // }
              }
              // $rootScope.notifications = _.remove($rootScope.notifications, function(n){
              //     if (!servicio.idServicio) {
              //         return n;
              //     }else if(servicio.idServicio != $scope.muestra.idServicio){
              //         return n;
              //     }
              // });
              // $cookies.putObject("notifications",$rootScope.notifications);
            },
            function() {
              $log.info('Modal dismissed at: ' + new Date())
            }
          )
        }
      )
    } else if (servicio.cobro) {
      console.log('cobro')
    }
  }

  $scope.eliminarNotificacion = n => {
    if (n.confirmar) {
      $rootScope.notifications = _.remove($rootScope.notifications, function(
        noti
      ) {
        return noti.idServicio != n.idServicio
      })
      $cookies.putObject('notifications', $rootScope.notifications)
    } else if (n.cobro) {
      $rootScope.notifications = _.remove($rootScope.notifications, function(
        noti
      ) {
        return noti.CobroId != n.CobroId
      })
      $cookies.putObject('notifications', $rootScope.notifications)
    }
  }

  $scope.busquedaAvanzada = function(size) {
    var modalInstance = $uibModal.open({
      templateUrl: 'busquedaAvanzadaCliente.html',
      controller: 'busquedaAvanzadaClienteModalInstance',
      size: size,
      resolve: {
        titulo: function() {
          return 'Buscar'
        }
      }
    })

    modalInstance.result.then(
      function(buscar) {
        // if(buscar){

        if (buscar.desdeLlamadaProgramada) {
          buscar.desdeLlamadaProgramadaParse =
            buscar.desdeLlamadaProgramada.getDayFormatted() +
            '-' +
            buscar.desdeLlamadaProgramada.getMonthFormatted() +
            '-' +
            buscar.desdeLlamadaProgramada.getFullYear()
        }
        if (buscar.hastaLlamadaProgramada) {
          buscar.hastaLlamadaProgramadaParse =
            buscar.hastaLlamadaProgramada.getDayFormatted() +
            '-' +
            buscar.hastaLlamadaProgramada.getMonthFormatted() +
            '-' +
            buscar.hastaLlamadaProgramada.getFullYear()
        }
        if (buscar.desdePeriodicidad) {
          buscar.desdePeriodicidadParse =
            buscar.desdePeriodicidad.getDayFormatted() +
            '-' +
            buscar.desdePeriodicidad.getMonthFormatted() +
            '-' +
            buscar.desdePeriodicidad.getFullYear()
        }
        if (buscar.hastaPeriodicidad) {
          buscar.hastaPeriodicidadParse =
            buscar.hastaPeriodicidad.getDayFormatted() +
            '-' +
            buscar.hastaPeriodicidad.getMonthFormatted() +
            '-' +
            buscar.hastaPeriodicidad.getFullYear()
        }
        // $cookies.putObject('busqueda', buscar);
        clienteService.busqueda(buscar, function(response) {
          buscar.desdeLlamadaProgramadaParse = null
          buscar.hastaLlamadaProgramadaParse = null
          buscar.desdePeriodicidadParse = null
          buscar.hastaPeriodicidadParse = null
          $rootScope.clientes = response.data.clientes.data
          $rootScope.itemsPerPage = response.data.clientes.per_page
          $rootScope.lastPage = response.data.clientes.last_page
          $rootScope.next_page_url = response.data.clientes.next_page_url
          $rootScope.prev_page_url = response.data.clientes.prev_page_url
          $rootScope.path = response.data.clientes.path
          $rootScope.last_page_url = response.data.clientes.last_page_url
          $rootScope.last_page = response.data.clientes.last_page
          $rootScope.totalClientes = response.data.clientes.total
          $rootScope.current_page = response.data.clientes.current_page
          $cookies.put('url', $rootScope.path)
          $cookies.put('pagina', '1')
          $cookies.remove('inObject') || null
          $cookies.remove('inOption') || null
          $scope.getPeriodicidades($rootScope.clientes)
        })
        $state.go('clienteClientes', { pedir: false })
        // }
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  $scope.diasFaltantes = function (contacto, adicionales, subPer) {
      var one_day = 1000 * 60 * 60 * 24
      var parse = new Date(Date.parse(contacto))
      var diff = parse.getTime() - new Date().getTime()
      if(adicionales > 0) { diff += one_day * adicionales }
      if(subPer && diff < 0) {
        return (Math.round(diff / one_day))
      }
      if(diff < 0) {
        return 0
      }
      return Math.abs(Math.round(diff / one_day))
    }

  $scope.getPeriodicidades = function(clientes){      //le da forma a las periodicidades
      _.forEach(clientes, c => {
         c.period = {}
         var sub = {}
        _.forEach(c.Periodicidades, p => {
          if(p.NegocioId == '2' && p.ProductoId === undefined) {
            c.period.estatus = p.estatus //El estatus debe ser el mismo para todas
            c.period.negocio = p.negocio //el negocio es el mismo
            c.period.PeriodicidadD = Number(p.Periodicidad)
            c.period.Reagendado = p.Reagendado
            c.period.FechaUltimo = p.FechaUltimo
            c.period.CamionetaUltimo = p.CamionetaUltimo
            if (p.FechaPeriodicidad) {
              c.period.TiempoFaltante = $scope.diasFaltantes(p.FechaPeriodicidad.date, 0, true) //bandera para traer nums negativos
            } else {
              c.period.TiempoFaltante = Number(p.Periodicidad)
            }
          }
          if(p.NegocioId == '2' && p.ProductoId !== undefined) {
            sub = {}
            if (c.period.negocio === undefined) { c.period.negocio = p.negocio }
            c.period.subPeriodicidad = c.period.subPeriodicidad || []
            sub.PeriodicidadId = p._id
            sub.ProductoId = p.ProductoId
            sub.ProductoNombre = p.ProductoNombre
            sub.Periodicidad = Number(p.Periodicidad)
            sub.FechaUltimo = p.FechaUltimo !== null ? p.FechaUltimo : 'n/a'
            sub.Reagendado = p.Reagendado
            sub.Remanente = p.Remanente || 0
            sub.Nota = p.Nota || ""
            sub.CamionetaUltimo = p.CamionetaUltimo
            if (p.FechaPeriodicidad) {
              sub.TiempoFaltante = $scope.diasFaltantes(p.FechaPeriodicidad.date, sub.Remanente, true) //bandera para traer nums negativos
            } else {
              sub.TiempoFaltante = Number(p.Periodicidad)
            }
            c.period.subPeriodicidad.push(sub)
          }
        })
        if(c.period.subPeriodicidad !== undefined) {
          $scope.ordenaPeriodicidadProducto(c) //se ordenarán las periodicidades con varios productos
          // $scope.getUltimoServicio(c)
        }
        if (c.period.PeriodicidadD !== undefined) {  //si sí hubo periodicidad de ese negocio
          c.Periodicidades = c.Periodicidades.filter(function(p){
            return p.negocio._id !== '2'
          }) //se quita la periodicidad de nutri por defecto y se establece la periodicidad modificada
          c.Periodicidades.push(c.period)
        }
      })
    }
    $scope.ordenaPeriodicidadProducto = function (cliente) {
      cliente.period.subPeriodicidad = _.orderBy(cliente.period.subPeriodicidad, sb => { return sb.TiempoFaltante })
      var aux = _.partition(cliente.period.subPeriodicidad, function(sb) {
        return sb.TiempoFaltante > -9
      })
      if (aux[1].length > 0) {
        aux[1] = _.orderBy(aux[1], i => { return i.TiempoFaltante }, 'desc')
      }
      cliente.period.subPeriodicidad = aux[0].concat(aux[1])
      var inmediato = aux[0].length > 0 ?
      _.minBy(aux[0], function(sb) {
        if (sb.TiempoFaltante >= -8) {
          return sb.TiempoFaltante || 0
        }
      })
      :
      _.minBy(aux[1], function(sb) {
        if (sb.TiempoFaltante >= -8) {
          return sb.TiempoFaltante || 0
        }
      })
      if (inmediato !== undefined) {
        cliente.period.PeriodicidadD = inmediato.Periodicidad
        cliente.period.TiempoFaltante = inmediato.TiempoFaltante > 0 ? inmediato.TiempoFaltante : 0
        cliente.period.Reagendado = inmediato.Reagendado
        cliente.period.FechaUltimo = inmediato.FechaUltimo
        cliente.period.CamionetaUltimo = inmediato.CamionetaUltimo
      } else {
        console.log("No se pudo obtener periodicidad más cercana")
      }
    }
})

Ilduomo.controller('busquedaAvanzadaClienteModalInstance', function(
  $scope,
  $uibModalInstance,
  titulo,
  $rootScope,
  $state,
  clienteService,
  $cookies,
  ubicacionService
) {
  $scope.titulo = titulo
  $scope.buscar = {}
  clienteService.getModalBuscarClientes(function(response) {
    var info = response.data
    var expireDate = new Date()
    expireDate.setDate(expireDate.getDate() + 30)
    $scope.estatusCliente = info.EstatusCliente
    $scope.negocio = info.Negocio
    $scope.municipio = info.Municipio
    $scope.municipios = $scope.municipio
    $scope.zona = info.Zona
    $scope.colonia = []
  })
  $scope.ok = function() {
    // Buscar aqui y setter el resultado en rootScope
    $uibModalInstance.close($scope.buscar)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.limpiarFiltros = function() {
    $scope.buscar.zonaClienteId = null
    $scope.buscar.municipioClienteId = null
    $scope.buscar.coloniaClienteId = null
    $scope.buscar.negocioCliente = null
    $scope.buscar.estatusClienteId = null
    $scope.buscar.desdePeriodicidad = null
    $scope.buscar.hastaPeriodicidad = null
    $scope.buscar.desdeLlamadaProgramada = null
    $scope.buscar.desdeLlamadaProgramadaParse = null
    $scope.buscar.hastaLlamadaProgramada = null
    $scope.buscar.hastaLlamadaProgramadaParse = null
    $scope.buscar.ordenarZona = null
    $scope.buscar.ordenarColonia = null
    $scope.buscar.ordenarCalle = null
  }

  $scope.$watch('buscar.municipioClienteId', function(newValue) {
    if ($scope.buscar.municipioClienteId) {
      ubicacionService.getColoniasMunicipio(newValue, function(response) {
        $scope.colonia = response.data
      })
    }
  })

  $scope.$watch('buscar.zonaClienteId', function(newValue) {
    if ($scope.buscar.zonaClienteId) {
      ubicacionService.getMunicipiosZona(newValue, function(response) {
        $scope.municipio = response.data
      })
    } else {
      $scope.municipio = $scope.municipios
    }
  })
  // $scope.buscar = $cookies.getObject('busqueda') || {};
  // if ($scope.buscar){
  //     $scope.buscar.desdePeriodicidad = ($scope.buscar.desdePeriodicidad) ? new Date($scope.buscar.desdePeriodicidad) : null;
  //     $scope.buscar.hastaPeriodicidad = ($scope.buscar.hastaPeriodicidad) ? new Date($scope.buscar.hastaPeriodicidad) : null;
  //     $scope.buscar.desdeLlamadaProgramada = ($scope.buscar.desdeLlamadaProgramada) ? new Date($scope.buscar.desdeLlamadaProgramada) : null;
  //     $scope.buscar.hastaLlamadaProgramada = ($scope.buscar.hastaLlamadaProgramada) ? new Date($scope.buscar.hastaLlamadaProgramada) : null;
  // }
})

Ilduomo.controller('confirmarController', function(
  $scope,
  $rootScope,
  $uibModal,
  $log,
  $cookies,
  // $mdToast,
  // $mdDialog,
  servicio,
  dataService
) {
  $scope.servicio = JSON.parse(JSON.stringify(servicio))
  $scope.muestra = JSON.parse(JSON.stringify(servicio))
  console.log($scope.servicio)
  // var isDlgOpen;
  // $scope.closeToast = function() {
  //     if (isDlgOpen) return;
  //     $mdToast
  //       .hide()
  //       .then(function() {
  //         isDlgOpen = false;
  //       });
  //   };

  $scope.showServicio = function(ev) {
    dataService.getInfoServicio(
      servicio.idServicio,
      servicio.negocioId,
      false,
      function(response) {
        $scope.servicio = response.data
        $scope.servicio.mascotas = []
        _.forEach($scope.servicio.agendado, function(a) {
          a.mascota = _.find($scope.servicio.cliente.mascotas, function(m) {
            return m._id == a.MascotaId
          })
          if ($scope.servicio.mascotas.indexOf(a.mascota) < 0) {
            $scope.servicio.mascotas.push(a.mascota)
          }
        })
        var modalInstance = $uibModal.open({
          templateUrl: 'modalShowServicio.html',
          controller: 'showServicioModalInstanceCtrl',
          size: 'md',
          resolve: {
            servicio: function() {
              return $scope.servicio
            },
            servicioP: function() {
              return null
            }, 
            fromServicesGrid: function() {
              return false
            }
          }
        })

        modalInstance.result.then(
          function(response) {
            if (response.Cancelado) {
              $scope.servicio.Estatus = 'Cancelado'
            } else if (response.Reagendado) {
              $scope.servicio.Estatus = 'Reagendado'
            } else {
              $scope.servicio.Estatus = 'Confirmado'
              if ($scope.servicio.preconfirmado) {
                $scope.servicio.preconfirmado = false
                $scope.$apply()
              } //ya se quitó su estatus de preconfirmación
              // if(sessionStorage.getItem('preConfirmados')){
              //   preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
              //   var index = preconf.findIndex(p => p.id === pre.camionetaId)
              //   if (index !== -1) { preconf.splice(index, 1) }
              //   sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
              // }
            }
            $rootScope.notifications = _.remove(
              $rootScope.notifications,
              function(n) {
                if (!n.idServicio) {
                  return n
                } else if (n.idServicio != $scope.muestra.idServicio) {
                  return n
                }
              }
            )
            $cookies.putObject('notifications', $rootScope.notifications)
            // $mdToast
            //   .hide()
            //   .then(function() {
            //     isDlgOpen = false;
            //   });
          },
          function() {
            $log.info('Modal dismissed at: ' + new Date())
          }
        )
      }
    )
  }
})

Ilduomo.controller('showServicioModalInstanceCtrl', function(
  $scope,
  $uibModalInstance,
  $rootScope,
  servicio,
  servicioP,
  fromServicesGrid,
  $state,
  dataService,
  clienteService,
  $log,
  $uibModal
) {
  $scope.return = {}
  $scope.return.Cancelado = false
  $scope.return.Reagendado = false
  $scope.return.Confirmado = false
  $scope.servicio = servicio

  angular.element(document).ready(function() {
    let h4 = document.getElementById('negocios_colores')
    let td = document.getElementById('negocios_borde')
    if ($scope.servicio.Negocios.length === 2) {
      h4.style.background =
        'linear-gradient(to right, ' +
        $scope.servicio.Negocios[0].Color + ' 50%, ' +
        $scope.servicio.Negocios[1].Color + ' 50%)'
      td.style.borderTop = td.style.borderRight = '7px solid ' + $scope.servicio.Negocios[0].Color
      td.style.borderBottom = td.style.borderLeft = '7px solid ' + $scope.servicio.Negocios[1].Color
    } else if ($scope.servicio.Negocios.length > 0) {
      h4.style.background = $scope.servicio.Negocios[0].Color
      td.style.border = '7px solid ' + $scope.servicio.Negocios[0].Color
    }
  })

  $scope.servicioP = servicioP
  if ($scope.servicioP) {
    // $scope.servicioP = servicioP
    $scope.servicios = []
    $scope.servicios.push($scope.servicio)
    $scope.servicios.push($scope.servicioP)
  }
  $scope.tiposPago = [
    'Efectivo',
    'TPV',
    'Transferencia - Depósito',
    'Terminado no pagado',
    'Cheque',
  ]

  if ($scope.servicio.tipoPago) { $scope.tiposPago.splice($scope.tiposPago.indexOf($scope.servicio.tipoPago), 1) }
  $scope.parseDate = f => {
    return Date.parse(f)
  }
  // $scope.parseMexico = (f) => {
  //   var n = new Date(Date.parse(f))
  //   n.setHours(n.getHours() - 7)
  //   return n
  // }
  if($scope.servicio.cliente){
    $scope.servicio.cliente.mascotas.forEach(function(m) {
      let r = _.find($scope.servicio.agendado, function(a) {
        return m._id == a.MascotaId
      })
      if (r) {
        m.servicio = true
      } else {
        m.servicio = false
      }
    })
  }


  $scope.cancelarServicio = function(servicio) {
    console.log('cancelar')
    var modalInstance = $uibModal.open({
      templateUrl: 'preguntaModalCancelarServicio.html',
      controller: 'preguntaModalCancelarServicioModalInstanceCtrl',
      size: 'md',
      resolve: {
        servicio: function() {
          return servicio
        }
      }
    })

    modalInstance.result.then(
      function(response) {
        if (response.confirmar) {
          var modalInstance = $uibModal.open({
            templateUrl: 'modalCancelarServicio.html',
            controller: 'modalCancelarServicioModalInstanceCtrl',
            size: 'md',
            resolve: {
              servicio: function() {
                return response.servicio
              }
            }
          })

          modalInstance.result.then(
            function(razonCancelacion) {
              dataService.cancelacionServicio(razonCancelacion, function(
                response
              ) {
                // TODO: si el estatus es true, eliminar el servicio
                // console.log('$rootScope.negocios', $rootScope.negocios);
                if (response.data.Estatus == 'True') {
                  $scope.return.Cancelado = true
                  var modalInstancePrg = $uibModal.open({
                    templateUrl: 'programarLlamadaClient.html',
                    controller: 'programarLlamadaClientModalInstance',
                    size: 'md',
                    resolve: {
                      negocios: function() {
                        return $rootScope.negocios
                      },
                      titulo: function() {
                        return 'Programar'
                      },
                      cliente: function() {
                        return servicio.cliente
                      }
                    }
                  })
                  modalInstancePrg.result.then(
                    function(lp) {
                      dataService.agregaLlamadaProgramada(lp, function(
                        response
                      ) {
                        console.log(response.data)
                        if (response.data.Estatus == 'True') {
                          if (servicio.cliente.LlamadaProgramada) {
                            servicio.cliente.LlamadaProgramada.push(
                              response.data.LlamadaProgramada
                            )
                          } else {
                            servicio.cliente.LlamadaProgramada = []
                            servicio.cliente.LlamadaProgramada.push(
                              response.data.LlamadaProgramada
                            )
                          }
                        } else if (response.data.Estatus == 'Error') {
                          iziToast.error({
                            title: response.data.Estatus,
                            message: response.data.mensaje,
                            close: false,
                            timeout: 2300
                          })
                        }
                      })
                    },
                    function() {
                      $log.info('Modal dismissed at: ' + new Date())
                    }
                  )
                  $uibModalInstance.close($scope.return)
                  // $state.reload();
                } else {
                  console.log('Algo salío mal')
                }
              })
            },
            function() {
              $log.info('Modal dismissed at: ' + new Date())
            }
          )
        }
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }
  $scope.cerrar = function() {
    $uibModalInstance.dismiss()
  }

  $scope.reagendarServicio = function(servicio) {
    $scope.return.Reagendado = true
    $uibModalInstance.close($scope.return)
    $state.go('clienteAgendar', {
      clienteId: servicio.ClienteId,
      negociosId: servicio.Negocios.map(n => n._id).join('-'),
      // negocioPeriodicidad: servicio.Negocio.Periodicidad || null,
      cliente: servicio.cliente,
      agendado: servicio,
      serv: servicio._id,
      fromServicesGrid,
      dia:
        new Date(servicio.FechaCompleta).getDayFormatted() +
        '-' +
        new Date(servicio.FechaCompleta).getMonthFormatted() +
        '-' +
        new Date(servicio.FechaCompleta).getFullYear()
    })
  }
  $scope.agendarServicioReagendadoAutomaticamente = function(servicio) {
    $scope.return.Reagendado = true
    $uibModalInstance.close($scope.return)
    $state.go('clienteAgendar', {
      clienteId: servicio.ClienteId,
      negociosId: servicio.Negocios.map(n => n._id).join('-'),
      cliente: servicio.cliente,
      agendado: servicio,
      serv: servicio._id,
      fromServicesGrid,
      dia:
        new Date(servicio.FechaCompleta).getDayFormatted() +
        '-' +
        new Date(servicio.FechaCompleta).getMonthFormatted() +
        '-' +
        new Date(servicio.FechaCompleta).getFullYear()
    })
  }
  $scope.confirmarServicio = function(servicio) {
    $scope.return.Confirmado = true
    // console.log("confirmar");
    // Confirmar servicio
    servicio.Estatus = 'Confirmado'
    dataService.confirmarServicio(servicio, function(response) {
      console.log(response.data)
      $uibModalInstance.close($scope.return)
    })
  }

  $scope.cambiaEstatus = function(servicio) {
    dataService.cambiaEstatus('Proceso', servicio, function(response) {
      console.log(response.data)
    })
    $uibModalInstance.close($scope.return)
  }

  $scope.updateTipoPago = function(servicioId) {
    if (document.getElementById('tipoDePago').value === $scope.servicio.tipoPago) return
    if (document.getElementById('tipoDePago').value === ""){
      $scope.errorMessage = "Debes seleccionar el medio de pago del servicio"
      return
    }
    $scope.return.tipoPago = document.getElementById('tipoDePago').value
    dataService.updateTipoPago(servicioId, $scope.return.tipoPago, function(response){
      if (response.data.Estatus == 'True') {
        iziToast.success({
          title: 'Método de pago actualizado: ',
          message: $scope.return.tipoPago,
          close: false,
          timeout: 2300,
          backgroundColor:'#BEF781'
        })
        $uibModalInstance.close($scope.return)
      } else {
        iziToast.error({
          title: 'Fallo al actualizar método de pago',
          message: 'Reintentar',
          close: false,
          timeout: 2300,
          backgroundColor:'#BEF781'
        })
      }
    })

  }

  $scope.cambiaEstatusTerminado = function(servicio) {
    if (document.getElementById('tipoDePago').value === "") {
      $scope.errorMessage = "Debes seleccionar el medio de pago del servicio"
      return
    }
    servicio.tipoPago = document.getElementById('tipoDePago').value
    var estatus =
      servicio.tipoPago === 'Terminado no pagado' || servicio.tipoPago === 'Transferencia - Depósito'
      ? 'porCobrar'
      : 'Realizado'
      dataService.cambiaEstatus(estatus, servicio, function(response) {
        if(response.data.Error){
          iziToast.error({
            title: 'Fallo al terminar servicio',
            message: response.data.Error,
            close: false,
            timeout: 2300,
          })
        }
      })
    $uibModalInstance.close($scope.return)
  }

  /**
   * Para editar los telefonos del cliente
   * @param  {String} size  [El tamaño del modal a mostrar]
   * @param  {Array} lista [Lista de los telefonos de contacto del cliente]
   * @return {Null}
   */
  $scope.editTelefonos = function(size, cliente) {
    var modalInstance = $uibModal.open({
      templateUrl: 'editPhonesClient.html',
      controller: 'editPhonesClientModalInstance',
      size: size,
      resolve: {
        telephones: function() {
          return cliente.telefonos
        },
        title: function() {
          return 'Editar'
        }
      }
    })

    modalInstance.result.then(
      function(telephones) {
        clienteService.updateTelefonosCliente(cliente, { telephones }, function(response) {
          if (response.data.Estatus !== 'True') {
            iziToast.error({
              title: 'Error',
              message: response.data.message,
              close: false,
              timeout: 2300
            })
          }
          else {
            cliente.telefonos = response.data.listaTelefonos
            iziToast.success({
              title: 'Télefonos',
              message: 'actualizados correctamente',
              close: false,
              timeout: 2300
            })
          }
        })
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  /**
   * Modal de editar mascotas de un cliente
   * @param  {String} size    Tamaño del modal
   * @param  {Object} cliente Objeto del cliente completo para poder editar sus mascotas
   */
  $scope.editMascotas = function(size, cliente) {
    var modalInstance = $uibModal.open({
      templateUrl: 'editMascotasClient.html',
      controller: 'editMascotasClientModalInstance',
      size: size,
      resolve: {
        cliente: function() {
          return cliente
        },
        titulo: function() {
          return 'Editar'
        }
      }
    })

    modalInstance.result.then(
      function(clienteUpdate) {
        clienteUpdate._method = 'PUT'
        dataService.updateMascotas(clienteUpdate, function(response) {
          if (response.data.estatus !== 'True') {
            iziToast.error({
              title: response.data.Estatus,
              message: response.data.message,
              timeout: 2300
            })
          } else {
            cliente.listaMascotas = response.data.mascotas
            servicio.cliente.mascotas = response.data.mascotas
            servicio.cliente.mascotas.forEach(function(m) {
              m.servicio = Boolean(_.find($scope.servicio.agendado, function(a) {
                return m._id == a.MascotaId
              }))
            })
          }
          delete clienteUpdate._method
        })
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  /**
   * editar la informacion básica del cliente
   * @param  {String} size    Tamaño del modal a abrir
   * @param  {Object} cliente Objeto completo para modificar la informacion
   * @return {Null}
   */
  $scope.editCliente = function(size, cliente) {
    var modalInstance = $uibModal.open({
      templateUrl: 'editInfoClient.html',
      controller: 'editInfoClientModalInstance',
      size: size,
      resolve: {
        cliente: function() {
          return cliente
        },
        titulo: function() {
          return 'Editar'
        }
      }
    })

    modalInstance.result.then(
      function(c) {
        if (c.confirmar) {
          clienteService.updateInfo(c, function(response) {
            console.log(response.data)
          })
        }
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  /**
   * modal para editar direccion
   * @param  {String} size    Tamaño del modal
   * @param  {Object} cliente Objeto completo del cliente a editar su ubicacion
   */
  $scope.editDireccion = function(size, cliente) {
    var modalInstance = $uibModal.open({
      templateUrl: 'editDireccionClient.html',
      controller: 'editDireccionClientModalInstance',
      size: size,
      resolve: {
        cliente: function() {
          return cliente
        },
        titulo: function() {
          return 'Editar'
        }
      }
    })

    modalInstance.result.then(
      function(clienteUpdated) {
        clienteUpdated._method = 'PUT'
        clienteService.updateUbicacion(clienteUpdated, function(response) {
          if (response.data.Estatus == 'True') {
            cliente.direccion  = response.data.direccion
            cliente.municipioNombre = response.data.municipioNombre
            cliente.coloniaNombre = response.data.coloniaNombre
            cliente.CP = response.data.CP
            iziToast.success({
              position: 'topRight',
              title: 'Hecho',
              message: 'Dirección actualizada correctamente',
              close: false,
              timeout: 2300
            })
          } else {
            iziToast.error({
              title: response.data.Estatus,
              message: response.data.message,
              close: false,
              timeout: 2300
            })
          }
        })
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  /**
   * edita contactos extras del cliente
   * @param  {String} size    [description]
   * @param  {[type]} cliente [description]
   * @return {[type]}         [description]
   */
  $scope.editContactos = function(size, cliente) {
    var modalInstance = $uibModal.open({
      templateUrl: 'editContactosClient.html',
      controller: 'editContactosClientModalInstance',
      size: size,
      resolve: {
        cliente: function() {
          return cliente
        },
        titulo: function() {
          return 'Editar'
        }
      }
    })

    modalInstance.result.then(
      function(c) {
        c._method = 'PUT'
        clienteService.updateContactos(c, function(response) {
          if (response.data.Estatus !== 'True') {
            iziToast.error({
              title: 'Error',
              message: response.data.message,
              close: false,
              timeout: 2300
            })
          } 
          else {
            cliente.telefonos = response.data.listaTelefonos
          }
        })
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }
})

Ilduomo.controller('preguntaModalCancelarServicioModalInstanceCtrl', function(
  $scope,
  $uibModalInstance,
  servicio
) {
  $scope.respuesta = {}
  $scope.respuesta.servicio = servicio
  $scope.no = function() {
    $scope.respuesta.confirmar = false
    $uibModalInstance.close($scope.respuesta)
  }
  $scope.si = function() {
    $scope.respuesta.confirmar = true
    $uibModalInstance.close($scope.respuesta)
  }
})

/**
 * muestra una alerta para confirmar si se desea eliminar
 * una periodicidad por producto
 */
Ilduomo.controller('dropPeriodicidadModalInstance',function(
  periodicidad,
  $scope,
  $uibModalInstance
) {
  $scope.periodicidad = periodicidad
  $scope.ok = function() {
    $uibModalInstance.close($scope.periodicidad)
  }
  $scope.cancel = function(){
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('modalCancelarServicioModalInstanceCtrl', function(
  $scope,
  $uibModalInstance,
  dataService,
  servicio
) {
  $scope.cancelacion = {}
  // $scope.razonesCancelacion = [];
  $scope.cancelacion.AgendadoId = servicio._id
  $scope.cancelacion.servicio = servicio
  dataService.getRazonCancelacion(function(response) {
    $scope.razonCancelacion = response.data
  })

  $scope.ok = function() {
    $uibModalInstance.close($scope.cancelacion)
  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('Cancelar')
  }
})

/**
 * Ilduomo Module
 *
 * editDireccionClientModalInstance para editar la direccion del cliente
 */
Ilduomo.controller('editDireccionClientModalInstance', function(
  $scope,
  $uibModalInstance,
  titulo,
  cliente,
  ubicacionService,
  dataService
) {
  $scope.titulo = titulo
  $scope.cliente = JSON.parse(JSON.stringify(cliente))
  $scope.cliente.listaDirecciones = []

  if($scope.cliente.direccion.Coordenadas){
    if($scope.cliente.direccion.Coordenadas.lat !== null &&  $scope.cliente.direccion.Coordenadas.lat !== undefined && $scope.cliente.direccion.Coordenadas.lng !== null &&  $scope.cliente.direccion.Coordenadas.lng !== undefined){
      $scope.coordenadas=JSON.parse(JSON.stringify(cliente.direccion.Coordenadas))
      $scope.coordenadas.LatLng=$scope.cliente.direccion.Coordenadas.lat+","+$scope.cliente.direccion.Coordenadas.lng
    }
  }else{
    $scope.coordenadas = null;
  }


  dataService.getModalUbicacion(function(response) {
    var info = response.data
    $scope.paises = info.Paises
    $scope.entidades = info.Entidades
    $scope.municipios = info.Municipios
    $scope.TipoDirecciones = info.TipoDirecciones
    for (var i in $scope.cliente.direccion) {
      if (i == 'Calle' || i == 'Numero Externo' || i == 'Numero Interno') {
        $scope.cliente.listaDirecciones.push({
          required: i == 'Numero Interno' ? 'false' : 'true',
          TipoDireccion: i,
          TipoDireccionId: _.find($scope.TipoDirecciones, function(t) {
            return t.Nombre == i
          })._id,
          Valor: $scope.cliente.direccion[i]
        })
      } else {
        for (var j = 0; j < $scope.cliente.direccion[i].length; j++) {
          $scope.cliente.listaDirecciones.push({
            required: 'false',
            TipoDireccion: i,
            TipoDireccionId: _.find($scope.TipoDirecciones, function(t) {
              return t.Nombre == i
            })._id,
            Valor: $scope.cliente.direccion[i][j]
          })
        }
      }
    }
  })

  $scope.$watch('cliente.MunicipioId', function(newValue) {
    if ($scope.cliente.MunicipioId) {
      ubicacionService.getColoniasMunicipio(newValue, function(response) {
        $scope.colonias = response.data
      })
    }
  })

  $scope.ingresarCoordenadas = function() {
    $scope.coordenadas = { lat: 0.0, lng: 0.0 , LatLng:"0,0"}
  }

  $scope.addRowDireccion = function() {
    var td = _.find($scope.TipoDirecciones, function(td) {
      return td.Nombre == 'Otros'
    })
    $scope.cliente.listaDirecciones.push({ TipoDireccionId: td._id })
  }

  $scope.isAddressRequired = function(addressName) {
    let address = _.find($scope.TipoDirecciones, (addressType) => {
      return addressType.Nombre === addressName
    })
    return address.Required === 'true'
  }

  $scope.dropDireccion = function(dir) {
    var index = $scope.cliente.listaDirecciones.indexOf(dir)
    if ($scope.cliente.listaDirecciones[index]._id) {
      // llamar al servicio para eliminarlo completamente
    } else {
      $scope.cliente.listaDirecciones.splice(index, 1)
    }
  }
  $scope.validaCoordenadas= function(){
    var lat=parseFloat($scope.coordenadas.LatLng.split(',')[0])
    var lng=parseFloat($scope.coordenadas.LatLng.split(',')[1])
    return $scope.coordenadas.LatLng.includes(",") && lat > -90 && lat  < 90 && lng > -180 && lng < 180 ? true : false
  }

  $scope.dropCoordenadas = function() {
    $scope.coordenadas = { lat: 0.0, lng: 0.0 , LatLng:"0,0"}

  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
  $scope.ok = function() {
    $scope.cliente.direccion = {}
    $scope.coordenadasValidas= true;

    _.forEach($scope.cliente.listaDirecciones, function(d) {
      if (
        d.TipoDireccion == 'Calle' ||
        d.TipoDireccion == 'Numero Externo' ||
        d.TipoDireccion == 'Numero Interno'
      ) {
        $scope.cliente.direccion[d.TipoDireccion] = d.Valor
      } else if (
        d.TipoDireccion == 'Cruzamiento' ||
        d.TipoDireccion == 'Otros'
      ) {
        if ($scope.cliente.direccion[d.TipoDireccion]) {
          if ($scope.cliente.direccion[d.TipoDireccion].indexOf(d.Valor) < 0) {
            $scope.cliente.direccion[d.TipoDireccion].push(d.Valor)
          }
        } else {
          $scope.cliente.direccion[d.TipoDireccion] = []
          $scope.cliente.direccion[d.TipoDireccion].push(d.Valor)
        }
      }
    })
   if($scope.coordenadas && $scope.coordenadas.LatLng !== "0,0" && $scope.coordenadas.LatLng !== ''){
        if($scope.validaCoordenadas()===true){
          $scope.coordenadas.lat=parseFloat($scope.coordenadas.LatLng.split(',')[0])
          $scope.coordenadas.lng=parseFloat($scope.coordenadas.LatLng.split(',')[1])
          delete $scope.coordenadas.LatLng
          $scope.cliente.direccion.Coordenadas = $scope.coordenadas
          $scope.coordenadasValidas= true;
        }else{
          $scope.coordenadasValidas = false;
        }
      }
    if($scope.coordenadasValidas===true)
      $uibModalInstance.close($scope.cliente)
  }
})

/**
 * Ilduomo Module
 *
 * editMascotasClientModalInstance para editar mascotas del cliente
 */
Ilduomo.controller('editMascotasClientModalInstance', function(
  $scope,
  $uibModalInstance,
  titulo,
  cliente,
  clienteService,
  $uibModal,
  dataService
) {
  $scope.titulo = titulo
  $scope.cliente = JSON.parse(JSON.stringify(cliente))

  $scope.mascotaSexo = clienteService.getSexoMascotas()
  $scope.mascotaEdades = clienteService.getEdadesMascota()

  dataService.getModalMascota(function(response) {
    var info = response.data
    $scope.especies = info.Especies
    $scope.razas = info.Razas
    $scope.tamaniosMascota = info.TamaniosMascota
  })
  // datepicker
  $scope.dateOptions = {
    formatYear: 'yy',
    startingDay: 1
  }
  $scope.maxDate = new Date()
  $scope.formatDate = 'dd-MMMM-yyyy'
  $scope.openFechaNacimiento = function($event, mascota) {
    $event.preventDefault()
    $event.stopPropagation()
    // console.log(mascota);
    $scope.cliente.listaMascotas[
      $scope.cliente.listaMascotas.indexOf(mascota)
    ].isOpen = true
  }

  $scope.dropMascota = function(m) {
    var index = $scope.cliente.listaMascotas.indexOf(m)
    if ($scope.cliente.listaMascotas[index]._id) {
      $scope.cliente.listaMascotas[index]._method = 'DELETE'
      clienteService.dropMascota($scope.cliente.listaMascotas[index], function(
        response
      ) {
        if (response.data.Estatus == 'True') {
          $scope.cliente.listaMascotas.splice(index, 1)
          cliente.listaMascotas.splice(index, 1)
        } else {
          console.log('Error al eliminar')
        }
      })
    } else {
      $scope.cliente.listaMascotas.splice(index, 1)
    }
  }
  $scope.addRow = function() {
    $scope.cliente.listaMascotas.push({})
  }

  $scope.capturarAlimentoMascota = function(cliente, mascota, size) {
    var index = mascota
    var modalInstance = $uibModal.open({
      templateUrl: 'capturarAlimentoModal.html',
      controller: 'capturarAlimentoMascotaModalInstance',
      size: size,
      resolve: {
        mascota: function() {
          return cliente.listaMascotas[mascota]
        },
        titulo: function() {
          return 'Capturar Alimento de ' + mascota.Nombre ? mascota.Nombre : ''
        }
      }
    })

    modalInstance.result.then(
      function(mca) {
        cliente.listaMascotas.splice(index, 1, mca)
      },
      function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  $scope.ok = function() {
    var validez = false
    _.forEach($scope.cliente.listaMascotas, function(m) {
      if (m.RazaId) validez = true
      else validez = false
    })
    if (validez === true) {
      $uibModalInstance.close($scope.cliente)
    } else {
      iziToast.error({
        title: 'Error',
        message: 'Datos de Mascota Incompletos',
        position: 'center'
      })
    }
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})
/**
 * Ilduomo Module
 *
 * editContactosClientModalInstanceController
 */
Ilduomo.controller('editContactosClientModalInstance', function(
  $scope,
  cliente,
  $uibModalInstance,
  titulo,
  clienteService
) {
  $scope.titulo = titulo
  $scope.cliente = cliente
  // console.log(cliente);
  clienteService.getModalContactoInfo(function(response) {
    var info = response.data
    $scope.titulos_catalogo = info.Titulos
    $scope.parentescos = info.Parentescos
  })

  $scope.addRow = function() {
    $scope.cliente.listaContactos.push({})
  }

  $scope.removeRow = function(c) {
    var index = $scope.cliente.listaContactos.indexOf(c)
    var contact = $scope.cliente.listaContactos[index]
    if (contact._id) {
      contact._method = 'DELETE'
      clienteService.dropContacto(contact, function(response) {
        if (response.data.Estatus == 'True') {
          $scope.cliente.listaContactos.splice(index, 1)
        } else {
          console.log('Ha ocurrido un error')
        }
      })
    } else {
      $scope.cliente.listaContactos.splice(index, 1)
    }
  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.ok = function() {
    $uibModalInstance.close($scope.cliente)
  }
})

/**
 * Ilduomo Module
 *
 * editInfoClientModalInstance
 */
Ilduomo.controller('editInfoClientModalInstance', function(
  $scope,
  cliente,
  titulo,
  $uibModalInstance,
  titulosService
) {
  $scope.cliente = cliente
  $scope.titulo = titulo
  titulosService.getTitulos(function(response) {
    $scope.titulos_catalogo = response.data
  })

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.ok = function() {
    $uibModalInstance.close($scope.cliente)
  }
})

/**
 * Ilduomo Module
 *
 * editNoteController
 */
Ilduomo.controller('editNotaClientModalInstance', function(
  $scope,
  nota,
  titulo,
  $uibModalInstance
) {
  $scope.nota = JSON.parse(JSON.stringify(nota))
  $scope.titulo = titulo

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
  $scope.ok = function() {
    $uibModalInstance.close($scope.nota)
  }
})

/**
 * Ilduomo Module
 *
 * editMaterialController
 */
Ilduomo.controller('editMaterialClientModalInstance', function(
  $scope,
  material,
  titulo,
  $uibModalInstance
) {
  $scope.equipmentNote = material
  $scope.title = titulo

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
  $scope.ok = function() {
    $uibModalInstance.close($scope.equipmentNote)
  }
})

/**
 * Ilduomo Module
 *
 * Controller for editPhones of the client
 */
Ilduomo.controller('editPhonesClientModalInstance', function(
  $scope,
  title,
  telephones,
  $uibModalInstance,
  telefonosService
) {
  $scope.telephones = JSON.parse(JSON.stringify(telephones))
  $scope.title = title
  
  telefonosService.getTipos(function(response) {
    $scope.tiposTelefonos = response.data
  })

  $scope.addRow = function() {
    $scope.telephones.push({ TipoTelefonoId: $scope.tiposTelefonos[1]._id })
  }

  $scope.removeRow = function(phone) {
    var index = $scope.telephones.indexOf(phone)
    var phoneToRemove = $scope.telephones[index]
    if (!phoneToRemove._id) {
      $scope.telephones.splice(index, 1)
      return
    }
    phoneToRemove._method = 'DELETE'
    telefonosService.dropTelefono(phoneToRemove, function(response) {
      if ((response.data.Estatus = 'True')) {
        $scope.telephones.splice(index, 1)
        telephones.splice(index, 1)
      }
    })
  }
  $scope.ok = function() {
    $uibModalInstance.close($scope.telephones)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 * Ilduomo Module
 *
 * programarRespuestaLlamadaClientModalInstance modal
 */
Ilduomo.controller('programarRespuestaLlamadaClientModalInstance', function(
  $scope,
  $rootScope,
  $uibModalInstance,
  $interval,
  $cookies,
  dataService,
  titulo,
  cliente,
  negocios
) {
  $scope.cliente = cliente
  $scope.titulo = titulo
  $scope.negocios = negocios.filter(n =>  n._id !== "3")
  $scope.negocios.forEach(n=> {
    n.RespuestaLlamadaId = ''
    n.Nota = ''
  })
  $scope.return = {}
  $scope.respuestallamada = {}
  //aqui van las respuestas de los negocios
  $scope.respuestallamada.respuestas = []
  // $scope.respuestallamada.Duracion={};
  // $scope.respuestallamada.Duracion.segundos=0;
  // $scope.respuestallamada.Duracion.minutos=0;
  // $scope.respuestallamada.Duracion.horas=0;
  $scope.respuestallamada.ClienteId = cliente._id
  $scope.respuestallamada.UserId = $rootScope.currentUser._id

  // var stopTime = $interval(function () {
  //     if($scope.respuestallamada.Duracion.segundos>=59){
  //       if ($scope.respuestallamada.Duracion.minutos>=59) {
  //         $scope.respuestallamada.Duracion.horas++;
  //         $scope.respuestallamada.Duracion.minutos=0;
  //       }else{
  //         $scope.respuestallamada.Duracion.minutos++;
  //       }
  //       $scope.respuestallamada.Duracion.segundos=0;
  //     }else{
  //       $scope.respuestallamada.Duracion.segundos++;
  //     }
  // },1000);

  if (sessionStorage.getItem('modalRespuestaLlamada')) {
    var data = JSON.parse(sessionStorage.getItem('modalRespuestaLlamada'))
    $scope.respuestas_catalogo = data.RP
    $scope.Estatus = data.ELC
  } else {
    dataService.getModalRespuestaLlamada(function(response) {
      $scope.respuestas_catalogo = response.data.RP
      $scope.Estatus = response.data.ELC
      sessionStorage.setItem(
        'modalRespuestaLlamada',
        JSON.stringify(response.data)
      )
    })
  }

  $scope.getRespuestaLlamada = function (respuestaLlamadaId) {
    if (respuestaLlamadaId) {
      let estatusId = _.find($scope.respuestas_catalogo, (rc) => {
        return rc._id === respuestaLlamadaId
      }).EstatusLlamadaId
      let estatus = _.find($scope.Estatus, (e) => {
        return e._id === estatusId
      })
      if (
        estatus.Nombre === 'Inhabilitado' ||
        estatus.Nombre === 'no generan cambio'
      ) {
        _.forEach($scope.negocios, (n) => {
          if (n.touched) {
            n.RespuestaLlamadaId = respuestaLlamadaId
          }
        })
      }
    }
  }

  $scope.ok = function() {
    // find to response of call
    // $interval.cancel(stopTime);
    $scope.negocios.forEach(n => {
      if(n.touched) {
        n.RespuestaLlamada = _.find($scope.respuestas_catalogo, r => { return r._id == n.RespuestaLlamadaId })
        $scope.respuestallamada.respuestas.push({
          RespuestaLlamadaId: n.RespuestaLlamadaId,
          Nota: n.Nota,
          RespuestaLlamada: n.RespuestaLlamada,
          NegocioId: n._id
        })
        n.touched = null
      }
    })
    $scope.return.respuestallamada = $scope.respuestallamada
    $scope.return.cliente = $scope.cliente
    console.log($scope.return)
    $uibModalInstance.close($scope.return)
  }
  $scope.cancel = function() {
    // $interval.cancel(stopTime);
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 * Ilduomo Module
 *
 * programarLlamadaClientModalInstance modal
 */
Ilduomo.controller('programarLlamadaClientModalInstance', function(
  $scope,
  $uibModalInstance,
  $rootScope,
  dataService,
  clienteService,
  titulo,
  cliente,
  negocios
) {
  $scope.cliente = cliente
  $scope.titulo = titulo
  $scope.negocios = negocios

  if (!$scope.negocios) {
    if (localStorage.getItem('negocios')) {
      $scope.negocios = JSON.parse(localStorage.getItem('negocios'))
      $rootScope.negocios = JSON.parse(localStorage.getItem('negocios'))
      var color =
        _.find($scope.negocios, function(alias) {
          return alias.Nombre == 'Nutrición'
        }) || {}
      $scope.colorCapturar = color.Color
    } else {
      clienteService.getNegocios(function(response) {
        if (response.data.error == 'token_not_provided') {
          window.localStorage.clear()
          $rootScope.authenticated = false
          $rootScope.currentUser = null
          $state.go('login')
        }

        localStorage.setItem('negocios', JSON.stringify(response.data))
        $scope.negocios = response.data
        $rootScope.negocios = response.data
        // prueba
        // $scope.negocios.pop();
        // color del boton agregar alimento
        var color =
          _.find($scope.negocios, function(alias) {
            return alias.Nombre == 'Nutrición'
          }) || {}
        // console.log(color);
        $scope.colorCapturar = color.Color
        //
      })
    }
  }
  $scope.programar = {}
  $scope.programar.ClienteId = $scope.cliente._id
  $scope.minDate = new Date(new Date().setDate(new Date().getDate()))
  $scope.programar.UserId = $rootScope.currentUser._id
  $scope.dateOptions = {
    startingDay: 1,
    minDate: $scope.minDate
  }
  $scope.programar.Negocios = []

  var date = new Date()
  date.setHours(9)
  date.setMinutes(0)

  $scope.$watch('programar.FechaLlamada', function(fl) {
    date = new Date(fl)
    date.setHours(9)
    date.setMinutes(0)
    $scope.programar.time = date
  })
  $scope.programar.FechaLlamada = date
  $scope.formatDate = 'dd-MM-yyyy'

  $scope.openProgramarFecha = function($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.programar.FechaisOpen = true
  }

  // $scope.addNegocio = function() {
  //   if ($scope.negocioKey == $scope.negocios.length) return
  //   $scope.programar.Negocios.push({NegocioId: $scope.negocios[$scope.negocioKey++]._id})
  // }

  // $scope.addNegocio()

  $scope.ok = function() {
    $scope.programar.Fecha = {
      dia:
        $scope.programar.FechaLlamada.getDayFormatted() +
        '-' +
        $scope.programar.FechaLlamada.getMonthFormatted() +
        '-' +
        $scope.programar.FechaLlamada.getFullYear(),
      horas: $scope.programar.time.getHours(),
      minutos: $scope.programar.time.getMinutes()
    }
    _.forEach($scope.negocios, (n) => {
      if (n.touched) {
        $scope.programar.Negocios.push({NegocioId: n._id})
        n.touched = null
      }
    })
    $uibModalInstance.close($scope.programar)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 * Ilduomo Module
 *
 * programarLlamadaClientModalInstance modal
 */
Ilduomo.controller('reprogramarLlamadaClientModalInstance', function(
  $scope,
  $uibModalInstance,
  $rootScope,
  dataService,
  titulo,
  cliente,
  negocios,
  negociosReprogramar
) {
  $scope.titulo = titulo
  $scope.cliente = cliente
  $scope.negocios = negocios
  $scope.programar = {}
  $scope.programar.ClienteId = $scope.cliente._id
  $scope.programar.UserId = $rootScope.currentUser._id
  $scope.programar.Negocios = []
  $scope.reprogramar = true

  _.forEach(negociosReprogramar, nr => {
    let index = $scope.negocios.findIndex(n => n._id === nr._id)
    $scope.negocios[index].touched = true
  })


  $scope.minDate = new Date(new Date().setDate(new Date().getDate()))
  $scope.dateOptions = {
    startingDay: 1,
    minDate: $scope.minDate
  }

  var date = new Date()
  date.setHours(9)
  date.setMinutes(0)

  $scope.$watch('programar.FechaLlamada', function(fl) {
    date = new Date(fl)
    date.setHours(9)
    date.setMinutes(0)
    $scope.programar.time = date
  })
  $scope.programar.FechaLlamada = date
  $scope.formatDate = 'dd-MM-yyyy'

  $scope.openProgramarFecha = function($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.programar.FechaisOpen = true
  }

  $scope.addNegocio = function() {
    if ($scope.negocioKey == $scope.negocios.length) return
    $scope.programar.Negocios.push({NegocioId: $scope.negocios[$scope.negocioKey++]._id})
  }

  $scope.ok = function() {
    $scope.programar.Fecha = {
      dia:
        $scope.programar.FechaLlamada.getDayFormatted() +
        '-' +
        $scope.programar.FechaLlamada.getMonthFormatted() +
        '-' +
        $scope.programar.FechaLlamada.getFullYear(),
      horas: $scope.programar.time.getHours(),
      minutos: $scope.programar.time.getMinutes()
    }
    _.forEach($scope.negocios, (n) => {
      if (n.touched) {
        $scope.programar.Negocios.push({NegocioId: n._id})
        n.touched = null
      }
    })
    $uibModalInstance.close($scope.programar)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('capturarAlimentoMascotaModalInstance', function(
  $scope,
  mascota,
  titulo,
  $uibModalInstance,
  dataService
) {
  $scope.mascota = mascota
  $scope.mascota.AlimentoId = $scope.mascota.AlimentoId
    ? $scope.mascota.AlimentoId
    : []
  $scope.mascota.Alimento = $scope.mascota.Alimento
    ? $scope.mascota.Alimento
    : []

  $scope.titulo = titulo
  dataService.getMarcasAlimento(function(response) {
    $scope.marcas = response.data
    _.forEach($scope.marcas, function(m) {
      _.find($scope.mascota.AlimentoId, function(ai) {
        if (ai == m._id) {
          m.checked = true
        }
      })
    })
  })

  $scope.setAlimento = function(marca) {
    var insertar = $scope.mascota.AlimentoId.indexOf(marca._id)
    // indexOf en un array regresa un -1 si no encuentra los pasado como parametro
    // si esta te devuelve el indice en el que se encuentra
    if (insertar < 0) {
      $scope.mascota.AlimentoId.push(marca._id)
      $scope.mascota.Alimento.push(marca)
    } else {
      $scope.mascota.AlimentoId.splice(insertar, 1)
      $scope.mascota.Alimento.splice(insertar, 1)
    }
  }

  $scope.ok = function() {
    $uibModalInstance.close($scope.mascota)
  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('RealizarLLamadaProgramadaModalInstance', function(
  $scope,
  cliente,
  titulo,
  dataService,
  llamadaprogramada,
  $interval,
  $uibModalInstance
) {
  $scope.cliente = cliente
  $scope.titulo = titulo
  $scope.llamadaprogramada = llamadaprogramada
  _.forEach($scope.llamadaprogramada, l => l.Nota = '')
  $scope.dynamicCol = 'col-sm-' + Math.floor(12/$scope.llamadaprogramada.length)
  $scope.Duracion = {}
  $scope.Duracion.segundos = 0
  $scope.Duracion.minutos = 0
  $scope.Duracion.horas = 0

  $scope.parseDate = d => {
    return Date.parse(d)
  }

  var stopTime = $interval(() => {
    if ($scope.Duracion.segundos >= 59) {
      if ($scope.Duracion.minutos >= 59) {
        $scope.Duracion.horas++
        $scope.Duracion.minutos = 0
      } else {
        $scope.Duracion.minutos++
      }
      $scope.Duracion.segundos = 0
    } else {
      $scope.Duracion.segundos++
    }
  }, 1000)
  if (sessionStorage.getItem('modalRespuestaLlamada')) {
    var data = JSON.parse(sessionStorage.getItem('modalRespuestaLlamada'))
    $scope.respuestas_catalogo = data.RP
    $scope.Estatus = data.ELC
  } else {
    dataService.getModalRespuestaLlamada(function(response) {
      sessionStorage.setItem(
        'modalRespuestaLlamada',
        JSON.stringify(response.data)
      )
      $scope.respuestas_catalogo = response.data.RP
      $scope.Estatus = response.data.ELC
    })
  }

  $scope.getRespuestaLlamada = function (resultadoLlamadaId) {
    if (resultadoLlamadaId) {
      let estatusId = _.find($scope.respuestas_catalogo, (rc) => {
        return rc._id === resultadoLlamadaId
      }).EstatusLlamadaId
      let estatus = _.find($scope.Estatus, (e) => {
        return e._id === estatusId
      })
      if (
        estatus.Nombre === 'Inhabilitado' ||
        estatus.Nombre === 'no generan cambio'
      ) {
        _.forEach($scope.llamadaprogramada, (lp) => {
          lp.ResultadoLlamadaId = resultadoLlamadaId
        })
      }
    }
  }

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel')
  }

  $scope.ok = () => {
    $interval.cancel(stopTime)
    _.forEach($scope.llamadaprogramada, function(lp) {
      lp.Agendado =
        _.find($scope.respuestas_catalogo, (rc) => {
          return rc._id == lp.ResultadoLlamadaId
        }) || {}
      if (!lp.Agendado.Agendar) {
        lp.Agendado.Agendar = false
        lp.Agendado.Estatus = false
      }
      lp.Duracion = $scope.Duracion
    })
    $uibModalInstance.close($scope.llamadaprogramada)
  }
})
