'use strict'
Ilduomo.controller('pistaMonitoreoCtrl', function (
  $scope,
  $rootScope,
  dataService,
  ubicacionService,
  socket,
  $uibModal,
  $stateParams,
  $state,
  $log,
  $cookies
  ) {
  Date.prototype.getMonthFormatted = function () {
    var month = this.getMonth() + 1
    return month < 10 ? '0' + month : '' + month // ('' + month) for string result
  }

  Date.prototype.getDayFormatted = function () {
    var day = this.getDate()
    return day < 10 ? '0' + day : '' + day // ('' + month) for string result
  }
  $scope.getTimeFormatted = function (n) {
    var x = ('0'+n).slice(-2)
    return x
  }

  /** Converts numeric degrees to radians */
  Number.prototype.toRadians = function() {
    return this * Math.PI / 180;
  }
  $scope.setCaption = function(stationaryTime) {
    return stationaryTime < 60 ?
    '0:' + $scope.getTimeFormatted(stationaryTime)
    :
    Math.floor(stationaryTime/60) + ':' + $scope.getTimeFormatted((Math.round(stationaryTime) - Math.floor(stationaryTime/60) * 60)) 
  }
  $scope.fechaHoy = new Date()
  $scope.fechaHoy = $scope.fechaHoy.getDayFormatted() + '-' + $scope.fechaHoy.getMonthFormatted() + '-' + $scope.fechaHoy.getFullYear()
  // $scope.locations = false

  $scope.ocultaCronograma = () => {
    delete $scope.muestraCronograma
    //delete $scope.Camioneta
    //delete $scope.agendado
  }
  $scope.check = (p) => !Array.isArray(p) && typeof p === 'object'
  $scope.cronograma = () => {
    $scope.muestraCronograma = true
    $scope.agendado = JSON.parse(JSON.stringify($scope.Camioneta[$scope.fechaCamioneta]))
  }
  
   document.getElementById('reoptimiza').addEventListener('click', function(){
     $scope.dibujaRutas($scope.Camioneta,true);
   })

  $scope.iniciaMapa = function() {
    //document.getElementById('mapa').style.display = 'block'
    //document.getElementById('mapa').style.width = '100%'
    $scope.muestraMapa = true
    //$scope.showRightPanel = false
    $scope.distance = 0
  /*  $scope.map = new google.maps.Map(document.getElementById('mapa'), {
      zoom: 13,
      center: {lat: 19.301263, lng: -99.187898}
    })*/
    if(arguments[0] == 'all') {
      var i = 1;
      _.forEach($rootScope.camionetas, function(c){
        $scope.createMarkers(c[$scope.fechaCamioneta], i)
        ++i
      })
    } else {
      $scope.createMarkers($scope.agendado)
    }
  }
  $scope.calcRoute = function (start, end, color, zindex,optima) {
    var directionsService = new google.maps.DirectionsService()
    var directionsDisplay = new google.maps.DirectionsRenderer()
    var request = {
      origin: start,
      destination: end,
      travelMode: 'DRIVING',
      avoidHighways: true,
      avoidTolls: true
    }
    directionsService.route(request, function (result, status) {
      if (status == 'OK') {
        directionsDisplay.setMap($scope.map)
           var lineSymbol = {
        path: optima ? google.maps.SymbolPath.CIRCLE : google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
        fillOpacity: .2,
        scale:  optima ? 3 : 2,
    };
        directionsDisplay.setOptions({
          suppressMarkers: true,
          polylineOptions: {
          strokeColor: color,
          stokeOpacity: 0,
          fillOpacity: 0,
          geodesic: true,
          zIndex: zindex,
          icons: [{
            icon: lineSymbol,
            offset: '0',
            repeat: '10px'
            }],
          },
          preserveViewport: true
        })
        directionsDisplay.setDirections(result)
      } 
      // else {
      //   console.log('No se pudo trazar ruta: ' + zindex)
      // }
    })
  }
  $scope.rutaColores = function (agendaCamioneta, args) {
    var indexes = []
    var i = 0, j = 0, k = 0, zindex = 0
    var colors = ['#00FF40', '#00FF00', '#40FF00', '#80FF00', '#BFFF00', '#FFFF00', '#FFBF00', '#FF8000', '#FF4000', '#B40404', '#DF0101', '#DF0174', '#DF01A5', '#A901DB', '#7401DF']
    _.forEach(agendaCamioneta, function (a, index) {
      args !== undefined ? zindex += 1 : null 
      indexes[i] = index
      i++
      if (i === 20) {
        for (j = 19; j > -1; j--) {
          k = j - 1
          if ($scope.direcciones[indexes[j]]) {
            while (!$scope.direcciones[indexes[k]] && k > -1) { // mientras sea nulo
              k--
            }
            if (k > -1) {
            $scope.calcRoute($scope.direcciones[indexes[j]], $scope.direcciones[indexes[k]], colors[zindex], zindex,false)
              args === undefined ? zindex += 1 : zindex = zindex
            }
          }
        }
      }
    })
  }
  $scope.mapaWayPoints = function (agendaCamioneta, muestraMapa) {
    // muestraMapa ? $scope.showRightPanel = true : $scope.showRightPanel = false
    $scope.distance = 0
    var waypts = []
    var direcciones = [] //Direccion o location asociada a cada indice de servicio (ex. Servicios14to1430)
    var inicio = null
    var destino = null
    var indexInicio,indexFinal //guardan el horario del servicio de inicio y final de ruta optima
    var directionsService = new google.maps.DirectionsService() 
    // if (muestraMapa){
/*      document.getElementById('mapa').style.width = '70%'
      document.getElementById('mapa').style.display = 'inline-block'
      document.getElementById('mapa').style.float = 'left'
      document.getElementById('mapa').style.height = '700px'
      // document.getElementById('right-panel').style.visibility = 'visible'
      document.getElementById('right-panel').style.height = '600px'
      document.getElementById('right-panel').style.width = '20%'*/
    // }  
    var geocoder = new google.maps.Geocoder()
    _.forEach(agendaCamioneta, function (a, index) {
      if (a.servicios) {
        if (a.servicios[0].cliente.direccion.Coordenadas) {
          var myLatLng = new google.maps.LatLng({
            lat: parseFloat(a.servicios[0].cliente.direccion.Coordenadas.lat), 
            lng: parseFloat(a.servicios[0].cliente.direccion.Coordenadas.lng)
          }, false)
          if (index !== document.getElementById('inicio').value && index !== document.getElementById('final').value){
            waypts.push({
              location: myLatLng,
              stopover: true, //parada obligatoria
            })
            direcciones.push({
              location: myLatLng,
              index: index
            })
          } else {
            if (index == document.getElementById('inicio').value){
              inicio = myLatLng 
              indexInicio = index;
            }
            if (index == document.getElementById('final').value){
              destino = myLatLng
              indexFinal = index;   
            }
          }
        } else {
          if (index !== document.getElementById('inicio').value && index !== document.getElementById('final').value){
            waypts.push({
              location: a.servicios[0].cliente.direccion.Calle + ' ' + a.servicios[0].cliente.direccion['Numero Externo'] + ', ' + a.servicios[0].Colonia + ' Ciudad de México, CDMX',
              stopover: true
            })
            direcciones.push({
              location: a.servicios[0].cliente.direccion.Calle + ' ' + a.servicios[0].cliente.direccion['Numero Externo'] + ', ' + a.servicios[0].Colonia + ' Ciudad de México, CDMX',
              index: index
            }) 
          } else {
            if (index == document.getElementById('inicio').value){
              inicio = a.servicios[0].cliente.direccion.Calle + ' ' + a.servicios[0].cliente.direccion['Numero Externo'] + ', ' + a.servicios[0].Colonia + ' Ciudad de México, CDMX' 
              indexInicio = index;
            }
            if (index == document.getElementById('final').value){
              destino = a.servicios[0].cliente.direccion.Calle + ' ' + a.servicios[0].cliente.direccion['Numero Externo'] + ', ' + a.servicios[0].Colonia + ' Ciudad de México, CDMX'              
              indexFinal = index;           
            }
          }
          // geocoder.geocode({ 'address': a.servicios[0].cliente.direccion.Calle + ' ' + a.servicios[0].cliente.direccion['Numero Externo'] + ', ' + a.servicios[0].Colonia + ' Ciudad de México, CDMX' }, function (results, status) {
          //   if (status == 'OK') {
          //     if(results[0].geometry.location_type != 'APPROXIMATE'){
          //       if (index !== document.getElementById('inicio').value && index !== document.getElementById('final').value){
          //         waypts.push({
          //           location: results[0].geometry.location,
          //           stopover: true
          //         })
          //       } else {
          //         if (index == document.getElementById('inicio').value)
          //           inicio = results[0].geometry.location 
          //         if (index == document.getElementById('final').value)
          //           destino = results[0].geometry.location
          //       }
          //     }
          //   } else {
          //     console.log("No se encontró " + a)
          //   }
          // })
        }
      }
    })
  
      if(document.getElementById('inicio').value == 'Pension' && $scope.Camioneta.EncierroCoordenadas){
         inicio = new google.maps.LatLng({ lat: parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[0]) , lng: parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[1])}, false)
        }
      if(document.getElementById('final').value == 'Pension' && $scope.Camioneta.EncierroCoordenadas){
          destino = new google.maps.LatLng({ lat: parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[0]) , lng: parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[1])}, false)
        }

  
    if(inicio == null || destino == null){
      $scope.mensaje = "El punto de inicio o destino no son validos. Asegúrese que se encuentren sus coordenadas."
    } else {
      $scope.mensaje = ''
    }
    directionsService.route({
      origin: inicio,
      destination: destino,
      waypoints: waypts,
      avoidTolls: true,
      avoidHighways: true,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    }, function(response, status){
      if (status === 'OK') {
        var myroute = response.routes[0]
        var newOrder = response.routes[0].waypoint_order 
        // var markers = []
        var marker
        var infowindow = new google.maps.InfoWindow()
        for (var i = 0; i < myroute.legs.length; i++){
          var numserv = numserv || (i+1)
          if(i === 0 && document.getElementById('inicio').value === 'Pension'){
              marker = new MarkerWithLabel({
            //  map: $scope.map,
              position: myroute.legs[i].start_location,
              icon: 'http://www.spacanino.com.mx/images/pointer.png',
              labelContent: '' + (i === 0 && document.getElementById('inicio').value === 'Pension' ? 'P' : numserv),
              labelAnchor: new google.maps.Point(15, 30),
              labelClass: 'marker-label accurate-marker',
              labelInBackground: false
            })  
            $scope.markers['Inicio'] = marker 
          }
          i === 0 && document.getElementById('inicio').value ? $scope.markers['Pension'] = marker : null
          if (i == myroute.legs.length -1){
            if (document.getElementById('inicio').value !== document.getElementById('final').value){ //si es igual ya no se hace nuevo marcador
              if(document.getElementById('final').value === 'Pension'){
                marker = new MarkerWithLabel({
               // map: $scope.map,
                position: myroute.legs[i].end_location,
                icon: 'http://www.spacanino.com.mx/images/pointer.png',
                labelContent: '' + (document.getElementById('final').value === 'Pension' ? 'P' : ++numserv), 
                labelAnchor: new google.maps.Point(15, 30),
                labelClass: 'marker-label accurate-marker',
                labelInBackground: false
                })
              $scope.markers['Final'] = marker
              }
              //  document.getElementById('final').value === 'Pension' ? $scope.markers['Pension'] = marker : null
            }
          }
          var response_dir = (i == myroute.legs.length -1 && document.getElementById('inicio').value !== document.getElementById('final').value) ? myroute.legs[i].end_address : myroute.legs[i].start_address
          // debugger
          response_dir = response_dir.split(', ') //response_dir[2] --> c.p Ciudad de méxico 
          response_dir.length == 6 ? response_dir[3] = response_dir[3].slice(0,5) : response_dir[2] = response_dir[2].slice(0,5) 
          var content = '<div style="font-size: 15px;"><b>Calle: </b>' + response_dir[0] + '<br>' +
                        '<b>Colonia </b>' + response_dir[1] + ', c.p: ' + (response_dir.length == 6 ? response_dir[3] : response_dir[2])
          // google.maps.event.addListener(marker,'click', (function(marker,content,infowindow) { 
          //   return function() {
          //      infowindow.close()
          //      infowindow.setContent(content);
          //      infowindow.open($scope.map,marker);
          //   };
          // })(marker,content,infowindow))
          $scope.distance += myroute.legs[i].distance.value || 0
          i === 0 && document.getElementById('inicio').value === 'Pension' ? null : numserv++
          // $scope.kms = (Number($scope.distance) / 1000)
          }
          var colors = ['#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010', '#FF1010']
          for(var j=0; j < myroute.legs.length-1; j++){
            $scope.calcRoute(myroute.legs[j].start_location, myroute.legs[j+1].start_location, colors[j], j,true)
            if (j === myroute.legs.length-2) 
              $scope.calcRoute(myroute.legs[j+1].start_location, myroute.legs[j+1].end_location, colors[j+1], j,true)  
          }
          // $scope.setServicioOrden(agendaCamioneta, response.request.waypoints, direcciones, myroute)
          // establece el orden en el cliente
          var agendaArray = $.map(agendaCamioneta, function(value, index) {
            return [index];
          });
          for (var w = 0; w < response.request.waypoints.length; w++){
            _.forEach(direcciones, function(dir){
              if ( ( response.request.waypoints[w].location.location !== undefined && (typeof(dir.location) !== 'string' ? response.request.waypoints[w].location.location.equals(dir.location) : false) ) || ( response.request.waypoints[w].location.query !== undefined && response.request.waypoints[w].location.query === dir.location) ){
                var indexOrdered = myroute.waypoint_order.indexOf(w) //da el orden del numero de waypoint
                  if (document.getElementById('inicio').value !== 'Pension'){
                    $rootScope.camionetas[$rootScope.camionetas.findIndex(aux => aux._id === $scope.Camioneta._id )][$scope.fechaCamioneta][agendaArray.find(horario => horario === dir.index)].waypoint_order = indexOrdered + 2 //por defecto inicia en 0 
                   //cambiando etiqueta 
                    if($scope.markers[dir.index]){
                      var lbl = $scope.markers[dir.index].get('labelContent')
                      $scope.markers[dir.index].set('labelContent',lbl + ' - '+ (indexOrdered + 2))
                    }
                  } else {
                      $rootScope.camionetas[$rootScope.camionetas.findIndex(aux => aux._id === $scope.Camioneta._id )][$scope.fechaCamioneta][agendaArray.find(horario => horario === dir.index)].waypoint_order = indexOrdered + 1 //Hubo uno que inició primero 
                      if($scope.markers[dir.index]){
                        var lbl = $scope.markers[dir.index].get('labelContent')
                        $scope.markers[dir.index].set('labelContent',lbl + ' - '+ (indexOrdered + 1))   
                      }
                    }                  
                }
            })  
          } //terminaOrden

          if(document.getElementById('inicio').value !== 'Pension'){
            $rootScope.camionetas[$rootScope.camionetas.findIndex(aux => aux._id === $scope.Camioneta._id )][$scope.fechaCamioneta][document.getElementById('inicio').value].waypoint_order = 1 //Este servicio es el de inicio
            var lbl = $scope.markers[indexInicio].get('labelContent')
            $scope.markers[indexInicio].set('labelContent',lbl + ' - '+ 1)
          } 
          if(document.getElementById('final').value !== 'Pension'){
            $rootScope.camionetas[$rootScope.camionetas.findIndex(aux => aux._id === $scope.Camioneta._id )][$scope.fechaCamioneta][document.getElementById('final').value].waypoint_order = numserv //Este servicio es el de inicio
              var lbl = $scope.markers[indexFinal].get('labelContent')
              $scope.markers[indexFinal].set('labelContent',lbl + ' - '+ numserv)
          } 

          $scope.$apply()
          $scope.map.setCenter({lat: 19.303425, lng: -99.179197})
          $scope.map.setZoom(13)
          // if (muestraMapa) $scope.setActualDistance(direcciones)
          $scope.setActualDistance(direcciones)
        }
        $scope.dibujaMarcadores()
      }) //If status OK
  }
  $scope.setServicioOrden = function (agendaCamioneta, waypoints, direcciones, myroute){ //waypoints son los de la respuesta del serv 
    for (var i = 0; i < waypoints.length; i++){
      _.forEach(direcciones, function(dir){
        if (waypoints[i].location.location.equals(dir.location)){
          var indexOrdered = myroute.waypoint_order.findIndex(i) //da el orden del numero de waypoint

        } else if (waypoints[i].location.location.equals(dir.address)) {

        }
      })
    }
  }
  /** 
  La distancia se calculará con los waypoints en orden inverso, para ir de 9-18 horas, y la salida/regreso en la pensión 
  */
  $scope.setActualDistance = function (direcciones) {
    var directionsService = new google.maps.DirectionsService()
    $scope.actualDistance = 0
    direcciones = direcciones.reverse()
    _.forEach(direcciones, function(v, i){
      delete v.index
      v.stopover = true
    })
    setTimeout(function(){
      directionsService.route({
        origin: new google.maps.LatLng(parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[0]), parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[1])),
        destination: new google.maps.LatLng(parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[0]), parseFloat($scope.Camioneta.EncierroCoordenadas.split(',')[1])),
        waypoints: direcciones,
        avoidTolls: true,
        avoidHighways: true,
        optimizeWaypoints: false,
        travelMode: 'DRIVING'
      }, function(response, status){
        if (status === 'OK'){
          var myroute = response.routes[0]
          for (var i = 0; i < myroute.legs.length ; i++){
            $scope.actualDistance += myroute.legs[i].distance.value
            $scope.$apply()
            console.log("real: " + $scope.actualDistance)
          }
        } else if (status === 'OVER_QUERY_LIMIT'){
          alert("Reintenta en unos momentos...")
        }
      })
    }, 1000)
  }
  $scope.createMarkers = function (agendaCamioneta, numCamioneta) { //numCamioneta cuando se le pasa el numero de camioneta a trazar
    $scope.respuestas = 0
    $scope.direcciones = []
    var j = 0
    var markerNum = 0
    var geocoder = new google.maps.Geocoder()
    var spaIcon = 'http://www.spacanino.com.mx/images/pointer.png'
    // var pinColor = 'EAD320'
    // var pinImage = {
    //   url: 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + pinColor,
    //   scaledSize: new google.maps.Size(20, 32),
    //   origin: new google.maps.Point(0, 0),
    //   anchor: new google.maps.Point(10, 34)
    // }
    _.forEach(agendaCamioneta, function (a) {
      if (a.servicios) {
        markerNum++
        $scope.respuestas++
      }
    })
    _.forEach(agendaCamioneta, function (a, index) {
      if (a.servicios) {
        if (a.servicios[0].cliente.direccion.Coordenadas) {
          var myLatLng = new google.maps.LatLng({
            lat: parseFloat(a.servicios[0].cliente.direccion.Coordenadas.lat), 
            lng: parseFloat(a.servicios[0].cliente.direccion.Coordenadas.lng)
          }, false)
          var numDeServicio = markerNum--
          var marker = new MarkerWithLabel({
            //map: $scope.map,
            position: myLatLng,
            icon: spaIcon,
            labelContent: '' + (numCamioneta !== undefined ? numCamioneta + '-' + numDeServicio : numDeServicio),
            labelAnchor: new google.maps.Point(15, 30),
            labelClass: 'marker-label accurate-marker ',
            labelInBackground: false
          })
          $scope.markers[index]=marker
          $scope.respuestas -= 1
          //$scope.markers[numDeServicio] = marker
          $scope.direcciones[index] = myLatLng
        } else {
          var col = a.servicios[0].Colonia
          var calle = a.servicios[0].cliente.direccion.Calle
          var num = a.servicios[0].cliente.direccion['Numero Externo']
          var numDeServicio = markerNum--
          geocoder.geocode({ 'address': calle + ' ' + num + ', ' + col + ' Ciudad de México, CDMX' }, function (results, status) {
            if (status == 'OK' && results[0].geometry.location_type != 'APPROXIMATE') {
              //  se comenta esta línea ya que si no se genera el marcador porque location_type = APPROXIMATE
              // en los waypoints de ruta optima, sí se considera y por lo tanto
              // debe verse en el mapa
              //if(results[0].geometry.location_type != 'APPROXIMATE'){
                $scope.map.setCenter(results[0].geometry.location)
                var marker = new MarkerWithLabel({
             //     map: $scope.map,
                  position: results[0].geometry.location,
                  icon: spaIcon,
                  labelContent: '' + (numCamioneta !== undefined ? numCamioneta + '-' + numDeServicio : numDeServicio) ,
                  labelAnchor: new google.maps.Point(15, 30),
                  labelClass: 'marker-label ',
                  labelInBackground: false
                })
                $scope.markers[index]=marker
                $scope.direcciones[index] = results[0].geometry.location
                $scope.respuestas -= 1
             //}
            } else if (status == 'ZERO_RESULTS' || results[0].geometry.location_type == 'APPROXIMATE') {
              geocoder.geocode({ 'address': calle + ', ' + col + ' Ciudad de México, CDMX' }, function (results, status) {
                if (status == 'OK' && results[0].geometry.location_type != 'APPROXIMATE') {
                    $scope.map.setCenter(results[0].geometry.location)
                    var marker = new MarkerWithLabel({
                    //  map: $scope.map,
                      position: results[0].geometry.location,
                      icon: spaIcon,
                      labelContent: '' + numDeServicio,
                      labelAnchor: new google.maps.Point(15, 30),
                      labelClass: 'marker-label approximate-marker ',
                      labelInBackground: false
                    })
                    $scope.markers[index]=marker
                    $scope.direcciones[index] = results[0].geometry.location
                    $scope.respuestas -= 1
                } else {
                  geocoder.geocode({ 'address': calle + ', Ciudad de México, CDMX'}, function (results, status) {
                    if (status == 'OK' && results[0].geometry.location_type != 'APPROXIMATE') {
                        var marker = new MarkerWithLabel({
                          //map: $scope.map,
                          position: results[0].geometry.location,
                          icon: spaIcon,
                          labelContent: '' + (numCamioneta !== undefined ? numCamioneta + '-' + numDeServicio : numDeServicio),
                          labelAnchor: new google.maps.Point(15, 30),
                          labelClass: 'marker-label approximate-marker ',
                          labelInBackground: false
                        })
                        $scope.markers[index]=marker
                        $scope.direcciones[index] = results[0].geometry.location
                        $scope.respuestas -= 1
                    } else {
                      alert('No se pudo determinar la ubicación de: ' + calle + ' ' + num + ', ' + col)
                      $scope.direcciones[index] = null
                      markerNum--
                      $scope.respuestas -= 1
                    }
                  })
                }
              })
            } else {
              alert('No se pudo determinar. Verifique el nombre de calle') // Sale del primero
              $scope.direcciones[index] = null
              markerNum--
              $scope.respuestas -= 1
            }
          })
        }
      } else {
        $scope.direcciones[index] = null
      }
    })
    if (numCamioneta === undefined){
      if($scope.respuestas == 0){
        $scope.rutaColores(agendaCamioneta) 
        iziToast.info({
          title: 'Cargando',
          timeout: 2000,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'Obteniendo direcciones'
        })
         setTimeout(function(){ 
          $scope.mapaWayPoints(JSON.parse(JSON.stringify($scope.Camioneta[$scope.fechaCamioneta]))); 
        }, 2000)

      }else{
        iziToast.info({
          title: 'Cargando',
          timeout: 8000,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'Obteniendo direcciones'
        })
        setTimeout(function(){ 
          $scope.rutaColores(agendaCamioneta) 
        }, 3000)
        setTimeout(function(){ 
          $scope.mapaWayPoints(JSON.parse(JSON.stringify($scope.Camioneta[$scope.fechaCamioneta]))); 
        }, 6000)
      }
    }else{
      setTimeout(function(){ $scope.rutaColores(agendaCamioneta, 'general') }, 8000)    
    }
  }

  $scope.dibujaRutas = function(c){
    $scope.dibujando = true
    $scope.markers = {}
    $scope.optionServicio = []
    $scope.Camioneta = JSON.parse(JSON.stringify(c))  
    $scope.agendado = JSON.parse(JSON.stringify($scope.Camioneta[$scope.fechaCamioneta]))
    $scope.showRightPanel = true
    $scope.map = new google.maps.Map(document.getElementById('mapa'), {
      zoom: 13,
      center: {lat: 19.301263, lng: -99.187898}
    })  
    $scope.iniciaMapa();
    var maxServicio = 0 //indica el numero maximo de servicios de esa camioneta
    _.forEach($scope.agendado, function (a) {
      if (a.servicios) {
          maxServicio++
      }
    })
    _.forEach($scope.agendado, (a, index) => {
      if(a.servicios){
        $scope.optionServicio.unshift({
          numero: maxServicio,
          index: index
        })
        maxServicio--
      }
    })
  }

  $scope.dibujaMarcadores = function(){
    _.forEach($scope.markers,function(m){
      if(m != undefined)
        m.setMap($scope.map);
    })
    $scope.dibujando = false
  }

  $scope.ocultaMapa = () => {
    // debugger
    $scope.muestraMapa = false
    $scope.map = null
    $scope.showRightPanel = false    
  }

  socket.on('currentPosition', function(response) {
    var info = JSON.parse(response)
    if(info.Estatus === 'False') {
      iziToast.error({
        title: 'Error',
        timeout: 2000,
        resetOnHover: true,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        message: 'Al obtener las ubicaciones del Usuario'
      })
      return
    } 
    document.getElementById('mapa').style.display = 'block'
    document.getElementById('mapa').style.width = '100%'
    $scope.map = $scope.map || new google.maps.Map(document.getElementById('mapa'), {
      zoom: 14,
      center: {lat: info.location.lat, lng: info.location.lng}
    })  
    var truck = 'https://cdn2.iconfinder.com/data/icons/general-5-1/247/Transfer-16.png'
    var myLatLng = new google.maps.LatLng({
      lat: parseFloat(info.location.lat), 
      lng: parseFloat(info.location.lng)
    }, false)
    var today = new Date(info.location.time)
    var marker = new MarkerWithLabel({
      map: $scope.map,
      position: myLatLng,
      icon: truck,
      labelContent: $scope.getTimeFormatted(today.getHours()) + ':' + $scope.getTimeFormatted(today.getMinutes()),
      labelAnchor: new google.maps.Point(15, 30),
      labelClass: 'time-marker',
      // icon: '/../../../../Theme/img/cara64.png',
      labelInBackground: false
    })
  })

  $scope.getCurrentPosition = function(id, camionetaNombre) {
    document.getElementById('locationsToday').style.visibility = 'visible'
    document.getElementById('registros').style.visibility = 'visible'
    document.getElementById('nombrecamioneta').style.marginRight = '10px'
    $scope.idCamioneta = id
    $scope.nombrecamioneta = camionetaNombre
    ubicacionService.getCurrentPosition(id, function(response) {
      var data = response.data
      if(data.Estatus === 'Tried') {
        iziToast.info({
          title: 'Obteniendo',
          timeout: 3000,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'ubicacion ' + camionetaNombre + '...'
        })
      } else {
        iziToast.error({
          title: 'Ubicacion',
          timeout: 2100,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'No se pudo conectar con el dispositivo '
        })
      }
    })  
  }

  $scope.dibujaTodos = function (id, nombre) {
    document.getElementById('locationsTable').style.display = 'none'
    document.getElementById('registros').style.visibility = 'visible'
    document.getElementById('mapa').style.display = 'none'
    document.getElementById('locationsToday').style.visibility = 'hidden'
    ubicacionService.getLocations(id, $scope.fechaCamioneta, function(response){
      if(response.data.Estatus === 'False') {
        iziToast.error({
          title: 'Error',
          timeout: 800,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'Al obtener las ubicaciones de ' + nombre
        })
        return
      }

      $scope.map = new google.maps.Map(document.getElementById('mapa'), {
        zoom: 18,
        center: { lat: 19.301263, lng: -99.187898 }
      })
      $scope.loading = true
      // document.getElementById('mapa').style.width = '100%'
      document.getElementById('mapa').style.display = 'block'
      var data = response.data
      $scope.locations = data.Locations
      $scope.lo = data.Locations
      var truck = 'https://cdn2.iconfinder.com/data/icons/general-5-1/247/Transfer-16.png'
        // if(loc[i].provider !== undefined) {
        //   //   var myLatLng = new google.maps.LatLng({
        //   //     lat: parseFloat($scope.locations[i].latitude), 
        //   //     lng: parseFloat($scope.locations[i].longitude)
        //   //   }, false)          
      //   // }
      for(var i = 0; i < $scope.locations.length; i++){
        if ($scope.locations[i].time !== undefined && $scope.locations[i].acc < 85) { 
          var hora = new Date($scope.locations[i].time)
          var marker = new MarkerWithLabel({
            map: $scope.map,
            position: new google.maps.LatLng(Number($scope.locations[i].lat), Number($scope.locations[i].lng)),
            icon: truck,
            // labelContent: hora.getDayFormatted() + "/" + hora.getMonthFormatted() + i + $scope.getTimeFormatted(hora.getHours()) + ':' + $scope.getTimeFormatted(hora.getMinutes()),
            labelContent: $scope.getTimeFormatted(hora.getHours()) + ':' + $scope.getTimeFormatted(hora.getMinutes()) +
            '\nacc.' + $scope.locations[i].acc + '\ns.' + $scope.locations[i].sp,
            labelAnchor: new google.maps.Point(15, 50),
            labelClass: 'time-marker',
            // icon: '/../../../../Theme/img/cara64.png',
            labelInBackground: false,
            labelStyle: {height: 'auto', width:'32px', 'border-radius': '4px'},
          })
        }
      }
      $scope.loading = false
    })
  }

  $scope.locationTable = function() {
    $scope.locationHeaders = $scope.locationHeaders || 
      [
        'Lat',
        'Lng',
        'Velocidad(m/s)',
        'Precision',
        'Origen',
        'Fecha',
      ]
    document.getElementById('mapa').style.display = 'none'
    document.getElementById('locationsTable').style.display = 'table'
    $scope.loading = true
    var hora
    for(var i = 0; i < $scope.locationsTable.length; i++){
      if($scope.locationsTable[i].time) {
        hora = new Date($scope.locationsTable[i].time)
        $scope.locationsTable[i].fechaFormat = hora.getDayFormatted() + "/" + hora.getMonthFormatted() + ' - ' + $scope.getTimeFormatted(hora.getHours()) + ':' + $scope.getTimeFormatted(hora.getMinutes())
      }
      if($scope.locationsTable[i].lat === 0) {
        var copy = $scope.locationsTable[i]
        $scope.locationsTable[i] = {}
        $scope.locationsTable[i].fechaFormat = copy.fechaFormat
        $scope.locationsTable[i].error = copy.message
      }        
    }
    $scope.loading = false
    $scope.showTable = true
  }
 
  $scope.getLocations = function(c, nombre) {
    document.getElementById('registros').style.visibility = 'visible'
    $scope.dibujaRutas(c)
    document.getElementById('locationsTable').style.display = 'none'
    document.getElementById('mapa').style.display = 'none'
    $scope.id = c._id
    ubicacionService.getLocations(c._id, $scope.fechaCamioneta, function(response){
      if(response.data.Estatus === 'False') {
        iziToast.error({
          title: 'Error',
          timeout: 800,
          resetOnHover: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          message: 'Al obtener las ubicaciones de ' + nombre
        })
        return
      }
      $scope.map = $scope.map || new google.maps.Map(document.getElementById('mapa'), {
        zoom: 18,
        center: { lat: 19.301263, lng: -99.187898 }
      })
      $scope.loading = true
      document.getElementById('mapa').style.display = 'block'
      var data = response.data
      $scope.locations = data.Locations
      $scope.locationsTable = $scope.locations.map(l => Object.assign({}, l))
      $scope.locations = $scope.locations.filter(l => {
        return l.time !== undefined
      })
      $scope.locations = $scope.locations.filter(l => {
        return l.lat !== 0 
      })
      var result = $scope.getStopsByDistanceFilter($scope.locations)
      var stops = result[0]
      var motions = result[1]
      var truck = 'https://cdn2.iconfinder.com/data/icons/general-5-1/247/Transfer-16.png'
      var stopsAux = []
      _.forEach(stops, function(st) {
        var near = $scope.getStationaryLocation(st)
        stopsAux.push(near)
      })
      stops = $scope.getStopsByDistanceFilter(stopsAux, { agrupa: true })
      var stopstop = []
      _.forEach(stops, function(st) {
        if(Array.isArray(st)) {
          var near = $scope.getStationaryLocation(st, {agrupa: true})
          stopstop.push(near)
        } else {
          stopstop.push(st)
        }
      })
      var sameStops = []
      for(var ind = 0; ind < stopstop.length; ++ind) {
        for(var i = ind+1; i < stopstop.length; ++i) {
          if(stopstop[ind].lat === stopstop[i].lat && stopstop[ind].lng === stopstop[i].lng) { 
            if(sameStops.length === 0) { 
              sameStops.push(stopstop[ind])
              stopstop.splice(ind, 1) 
              sameStops.push(stopstop[i-1])
              stopstop.splice(i-1, 1)
              i--
              ind--
            } else {
              sameStops.push(stops[i])
              stopstop.splice(i, 1)
              i--
            }
          }
        }
        if(sameStops.length > 0) { 
          var infoWindow = new google.maps.InfoWindow()
          var totalStationaryTime = 0
          var content = '<div style="font-size: 15px;">'
          _.forEach(sameStops, ss => {
            var hora = new Date(ss.arriving)
            content += 'Llegada ' + $scope.getTimeFormatted(hora.getHours()) + ':' + $scope.getTimeFormatted(hora.getMinutes()) + ' -> (' + ss.caption + ') <br>'  
            totalStationaryTime += ss.stationaryTime
          })
          content += '</div>'
          var marker = new MarkerWithLabel({
            map: $scope.map,
            position: new google.maps.LatLng(parseFloat(sameStops[0].lat), parseFloat(sameStops[0].lng)),
            icon: truck,
            labelContent: sameStops.length + '\n(' + $scope.setCaption(totalStationaryTime) + ')',
            labelAnchor: new google.maps.Point(15, 50),
            labelClass: 'time-marker',
            labelStyle: {height: 'auto', width:'32px', 'border-radius': '4px', 'background': '#F99631'},
            labelInBackground: false
          })
          google.maps.event.addListener(marker, 'click', (function (marker, content, infoWindow) { 
            return function() {
              infoWindow.close()
              infoWindow.setContent(content);
              infoWindow.open($scope.map, marker);
            }
          })(marker, content, infoWindow))
          sameStops = []
        }
      }
      stopstop = stopstop.filter(ss => { return ss.stationaryTime > 5 })
      motions = motions.reduce((acc, val) => acc.concat(val), []);
      _.forEach(motions, w=>{
        var marker = new MarkerWithLabel({
          map: $scope.map,
          position: new google.maps.LatLng(parseFloat(w.lat), parseFloat(w.lng)),
          labelAnchor: new google.maps.Point(0,0),
          icon: ' ',
          labelClass: 'truck-point',
          labelInBackground: false
        })  
      })
      _.forEach(stopstop, function(stp) {
        var hora = new Date(stp.arriving)
        var marker = new MarkerWithLabel({
          map: $scope.map,
          position: new google.maps.LatLng({ lat: parseFloat(stp.lat), lng: parseFloat(stp.lng)}, false),
          icon: truck,
          labelContent: $scope.getTimeFormatted(hora.getHours()) + ':' + $scope.getTimeFormatted(hora.getMinutes()) + '\n(' + stp.caption + ')',
          labelAnchor: new google.maps.Point(15, 50),
          labelClass: 'time-marker',
          labelStyle: {height: 'auto', width:'32px', 'border-radius': '4px'},
          labelInBackground: false
        })
      })
    $scope.loading = false
    })
  }

  //TODO. dibujar un recorrido con los puntos en movimiento, considerando los posibles errores en la precisión
  $scope.sortPoints = function(p) {
    var obj = {}
    obj.origin = new google.maps.LatLng(Number(p[0].lat), Number(p[0].lng))
    obj.destination = new google.maps.LatLng(Number(p[p.length-1].lat), Number(p[p.length-1].lng))
    var waypts = []
    var myLatLng = 0
    for(var i = 1; i < p.length-1; i++) {
      waypts.push({
        location: new google.maps.LatLng(Number(p[i].lat), Number(p[i].lng)),
        stopover: false
      })
    }
    obj.waypoints = waypts
    return obj
  }

  $scope.getStationaryLocation = function (points, ...agrupa) {
    var near = _.minBy(points, p => {
      return p.acc      
    })
    if(agrupa.length === 0) {
      near.arriving = points[0].time 
      near.stationaryTime = Math.round((points[points.length-1].time - points[0].time)/1000/60)
    } else {
      near.arriving = points[0].arriving  
      near.stationaryTime = _.sumBy(points, p => p.stationaryTime)
    }
    near.caption = $scope.setCaption(near.stationaryTime)
    return near
  }

  /** Routes es un objeto con: origin, destination y un arreglo con waypoints */
  $scope.dibujaRecorrido = function(routes) {
    _.forEach(routes, function(r) {
      var directionsService = new google.maps.DirectionsService()
      // var directionsDisplay = new google.maps.DirectionsRenderer()
      // directionsService.route({
      //   origin: r.origin,
      //   destination: r.destination,
      //   waypoints: r.waypoints,
      //   travelMode: 'DRIVING',
      //   avoidHighways: true,
      //   avoidTolls: true
      // }, function (result, status) {
      //   if (status == 'OK') {
      //     directionsDisplay.setMap($scope.map)
      //     directionsDisplay.setOptions({
      //       suppressMarkers: true,
      //       // polylineOptions: {
      //       //   strokeColor: color,
      //       //   strokeWeight: 5,
      //       //   stokeOpacity: 0.2,
      //       //   geodesic: true,
      //       //   zIndex: zindex,
      //       // },
      //       preserveViewport: true
      //     })
      //     directionsDisplay.setDirections(result)
      //   } else if(status === 'OVER_QUERY_LIMIT'){
      //     alert('Intenta en unos momentos')
      //   }
      // })
      _.forEach(r.waypoints, w=>{
        var marker = new MarkerWithLabel({
          map: $scope.map,
          position: w.location,
          labelAnchor: new google.maps.Point(0,0),
          icon: ' ',
          labelClass: 'truck-point',
          labelInBackground: false
        })  
      })
    })
  }

  $scope.getStopsByDistanceFilter = function (locations, ...agrupa) {
    var movimiento = []
    var stops = []
    var stop = []
    var pasadoMovimiento = false
    if(agrupa.length > 0) {
      if(locations.length === 1) {
        return locations
      }
      for(var i = 0; i < locations.length-1; ++i) {
        if($scope.getDistance(locations[i], locations[i+1])) { //se encuentran cerca
          if(i === 0 || pasadoMovimiento) stop.push(locations[i])
          stop.push(locations[i+1])
          if(pasadoMovimiento) stops.splice(stops.length-1, 1)
          pasadoMovimiento = false
        } else {
          if(stop.length > 0) {
            stops.push(stop)
            stop = []
          }
          if(i === 0) stops.push(locations[i])
          stops.push(locations[i+1])
          pasadoMovimiento = true
          // if(i === locations.length-2) stops.push(locations[i+1]) // el ultimo no tenia relacion con el antepenúltimo
        }
      }
      if(stop.length > 0) stops.push(stop)
      return stops
    }
    for(var i = 0; i < locations.length-1; ++i) {
      if(!locations[i].sp || locations[i].sp < 1.8) { //maybe stationary
        if($scope.getDistance(locations[i], locations[i+1])) {
          if(i === 0 || pasadoMovimiento) stop.push(locations[i])
          stop.push(locations[i+1])
          if(pasadoMovimiento) movimiento.splice(movimiento.length-1, 1) //quito el ultimo que en realidad era estacionario
          pasadoMovimiento = false
        } else {
          if(locations[i+2] && $scope.getDistance(locations[i], locations[i+2])) { //todavia estaba ahí
            if(i === 0) stop.push(locations[i])
            stop.push(locations[i+1])
            stop.push(locations[i+2])
            i++ //El recorrido se salta ese punto ya que puede estar impreciso
            if(pasadoMovimiento) movimiento.splice(movimiento.length-1, 1) //quito el ultimo
            pasadoMovimiento = false
          } else {
            if(i === 0) movimiento.push(locations[i]) //El primer y segundo no son estacionarios
            movimiento.push(locations[i+1])          
            if(stop.length > 1) {
              stops.push(stop)
              stop = []
            }
            pasadoMovimiento = true
          }
        }
      } else {
        movimiento.push(locations[i])
        if(stop.length > 1) {
          stops.push(stop)
          stop = []
        }
      }
    }
    if(stop.length > 1) stops.push(stop)
    return [stops, movimiento] 
  }

  $scope.getDistance = function (point1, point2) {
    var R = 6371e3
    var lat1 = point1.lat
    var lng1 = point1.lng
    var lat2 = point2.lat
    var lng2 = point2.lng
    var φ1 = lat1.toRadians()
    var φ2 = lat2.toRadians()
    var Δφ = (lat2-lat1).toRadians()
    var Δλ = (lng2-lng1).toRadians()
    var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ/2) * Math.sin(Δλ/2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    var d = R * c
    if(d <= (point1.acc + point2.acc)) 
      return 1 //se encuentran cerca  
    return 0
  }

  socket.on('cambiaEstatus', function (s) {
        // aqui cambiare el por id el servicio de estatus y por tanto de color
    var servicio = JSON.parse(s)
    if (servicio.Fecha) {
      var fecha = new Date(Date.parse(servicio.Fecha.date))
      var fechaServicio = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
      var camioneta = _.find($rootScope.camionetas, function (c) {
        return c._id == servicio.camionetaId
      })
      if (!camioneta) { return }
      if (camioneta[fechaServicio]) {
        if (fecha.getMinutes() == 0) {
          var time = 'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
        } else {
          var time = 'Servicios' + fecha.getHours() + '' + fecha.getMinutes() + 'to' + (fecha.getHours() + 1)
        }
        if (camioneta[fechaServicio][time]) {
          camioneta[fechaServicio][time].servicios[0].Estatus = servicio.Estatus //tenia servicios[0] pero solo devuelvo uno
          camioneta[fechaServicio][time].servicios[0].tipoPago = servicio.TipoPago //tenia servicios[0] pero solo devuelvo uno
          if (servicio.Estatus === 'Realizado' || servicio.Estatus === 'porCobrar') {
            $scope.setTipoPago(camioneta[fechaServicio][time].servicios[0])
            if($cookies.getObject('preconfId')) {
              var pconf = $cookies.getObject('preconfId')
              pconf = pconf.filter(s => s.id !== servicio.camionetaId)
              var expireDate = new Date()
              expireDate.setDate(expireDate.getDate() + 1)
              $cookies.putObject('preconfId', pconf, { 'expires': new Date(expireDate.setHours(0, 0, 0)) } )
            }
          }
          $scope.$apply()
          if(servicio.Estatus == 'Confirmado') {
            if(sessionStorage.getItem('preConfirmados')) {
              var preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
              var index = preconf.findIndex(aux => aux.id === servicio.camionetaId)
              if(index !== -1){
                preconf.splice(index, 1)
                if(preconf !== null && preconf.length > 0){
                  sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
                } else {
                  sessionStorage.removeItem('preConfirmados')
                }
              }
            }
          }
          if (servicio.Estatus !== 'Espera') {
            if(localStorage.getItem('camionetasToday')){
              if($rootScope.camionetas[0][$scope.fechaHoy] !== undefined) {
                localStorage.setItem('camionetasToday', JSON.stringify($rootScope.camionetas))
              }
            }
          }
        } else {
          console.log('Error no existe el servicio en la camioneta ' + servicio.camionetaId)
        }
      } else {
        console.log('Error no se encuentra en la fecha especifica')
      }
    }
  })

  socket.on('Confirmacion', function (s) {
        // aqui cambiare el por id el servicio de estatus y por tanto de color
    var servicio = JSON.parse(s)
    if (servicio.Fecha) {
      var fecha = new Date(Date.parse(servicio.Fecha.date))
      var fechaServicio = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
      var camioneta = _.find($rootScope.camionetas, function (c) {
        return c._id == servicio.camionetaId
      })
      if (camioneta[fechaServicio]) {
        if (fecha.getMinutes() == 0) {
          var time = 'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
        } else {
          var time = 'Servicios' + fecha.getHours() + '' + fecha.getMinutes() + 'to' + (fecha.getHours() + 1)
        }
        if (camioneta[fechaServicio][time]) {
          camioneta[fechaServicio][time].servicios[0].Estatus = 'Confirmado'
          $scope.$apply()
        } else {
          console.log('Error no existe el servicio en la camioneta ' + servicio.camionetaId)
        }
      } else {
        console.log('Error no se encuentra en la fecha especifica')
      }
    }
  })

  socket.on('agendar', function (data) {
    // aqui se agregara el nuevo servicio con su camioneta y a la hora indicada
    var d = JSON.parse(data)
    console.log(d)
    // primero se tiene que remover el servicio y despues hacer un push a la hora correcta
    var cam = _.find($rootScope.camionetas, function (c) {
      return c._id == d.CamionetaId
    })
    if (!cam) { return }
    var fecha = new Date(Date.parse(d.Fecha.date))
    var fechaServicio = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
    if ($scope.fechaCamioneta !== fechaServicio) {
      _.forEach($rootScope.camionetas, function (c) {
        _.forEach(c[fechaServicio], function (l) {
          if (l.servicios) {
            if(l.servicios[0]._id === d._id)  
              l.servicios = null
          }
        })
      })
    }
    _.forEach($rootScope.camionetas, function (c) {
      _.forEach(c[$scope.fechaCamioneta], function (l) {
        if (l.servicios) {
          if(l.servicios[0]._id === d._id)  
            l.servicios = null
        }
      })
    })
    if (fecha.getMinutes() == 0) {
      var time = 'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
    } else {
      var time = 'Servicios' + fecha.getHours() + '' + fecha.getMinutes() + 'to' + (fecha.getHours() + 1)
    }
    if (!cam[fechaServicio]) {
      cam[fechaServicio] = {}
    }
    cam[fechaServicio][time] = {}
    cam[fechaServicio][time].drag = false
    cam[fechaServicio][time].drop = false
    cam[fechaServicio][time].servicios = [d]
    $scope.$apply()
    // Aqui se cuenta el número de servicios por camioneta
    $scope.serviciosTotales = 0
    _.forEach($rootScope.camionetas, function (c) {
      c.totalServicios = 0
      if (c.Negocio.Nombre == 'Estética') {
        _.forEach(c[$scope.fechaCamioneta], function (horario) {
          if (horario.servicios) {
            c.totalServicios += _.sumBy(horario.servicios[0].agendado, function (serv) {
              return serv.nombreProducto != 'Descuento' && serv.nombreProducto != 'Incremento' && serv.NegocioId != '2'
            })
          }
        })
        $scope.serviciosTotales += c.totalServicios
      }
    })
    $scope.$apply()
  })

  socket.on('Cancelacion', function (data) {
    var d = JSON.parse(data)
    // console.log(d)
    var fecha = new Date(Date.parse(d.agendado.Fecha.date))
    // var fechaServicio = $scope.fechaCamioneta
    var fechaServicio = fecha.getDayFormatted() + '-' + fecha.getMonthFormatted() + '-' + fecha.getFullYear()
    if (fecha.getMinutes() == 0) {
        var time = 'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
      } else {
        var time = 'Servicios' + fecha.getHours() + '' + fecha.getMinutes() + 'to' + (fecha.getHours() + 1)
      }
    var cam = _.find($rootScope.camionetas, (c) => c._id == d.agendado.CamionetaId)
    if (cam[fechaServicio]) {
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = null
        cam[fechaServicio][time].drag = true
        cam[fechaServicio][time].drop = true
        $scope.$apply()
      } else {
        cam[fechaServicio] = {}
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = null
        cam[fechaServicio][time].drag = true
        cam[fechaServicio][time].drop = true
        $scope.$apply()
      }
    $scope.serviciosTotales = 0
    _.forEach($rootScope.camionetas, function (c) {
      c.totalServicios = 0
      if (c.Negocio.Nombre == 'Estética') {
        _.forEach(c[fechaServicio], function (horario) {
          if (horario.servicios) {
            c.totalServicios += _.sumBy(horario.servicios[0].agendado, function (serv) {
              return serv.nombreProducto != 'Descuento' && serv.nombreProducto != 'Incremento' && serv.NegocioId == '2'
            })
          }
        })
        $scope.serviciosTotales += c.totalServicios
      }
    })
    $scope.$apply()
  })

  $rootScope.setting.layout.pageSidebarMinified = true
  var date = new Date()
  $scope.minDate = date
  var max = new Date(date.getDate() + 1)
  $scope.max = max
  $scope.fecha = date
  $scope.dateOptions = {
    startingDay: 1
  }

  if ($stateParams.fecha) {
    $scope.fecha = (function (fecha) {
      var d = fecha.split('-')
      return new Date(d[2], d[1] - 1, d[0])
    })($stateParams.fecha)
  } else {
    $scope.fecha = date
  }

  $scope.formatDate = 'dd-MMMM-yyyy'
  $scope.openAgendar = function ($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.isOpen = !$scope.isOpen
  }

  dataService.getPista(function (response) {
    var info = response.data
    $rootScope.camionetas = info.Camionetas
    $scope.fechaCamioneta = info.FechaPista
    $scope.$watch('fecha', function (newValue) {
      $scope.fechaCamioneta = newValue.getDayFormatted() + '-' + newValue.getMonthFormatted() + '-' + newValue.getFullYear()
      $scope.serviciosTotales = 0
      var preconf = JSON.parse(sessionStorage.getItem('preConfirmados')) //{id: idCamioneta, servicios: id de serv preconfimados}
      $state.go('.', {fecha: $scope.fechaCamioneta}, {notify: false})
      dataService.getAgendadoElDia($scope.fechaCamioneta, function (response) {
        if($scope.fechaCamioneta === $scope.fechaHoy) {
          localStorage.setItem('camionetasToday', JSON.stringify(response.data.camionetas))
        }
        _.forEach(response.data.camionetas, function (ca) {
          _.find($rootScope.camionetas, function (c) {
            if (ca._id == c._id) {
              c.totalServicios = 0
              c.location = {}
              c.location.error = false
              c[$scope.fechaCamioneta] = ca[$scope.fechaCamioneta] //se asigna la ruta del día a $rootscope.camionetas
              if (sessionStorage.getItem('preConfirmados')) {
                _.forEach(preconf, function(pre){
                  if(c._id == pre.id){
                    _.forEach(c[$scope.fechaCamioneta], function(s){
                      if(s.servicios && s.servicios[0]._id == pre.servicio){
                        s.servicios[0].preconfirmado = true 
                      }
                    })
                  }
                })
              }
              _.forEach(c[$scope.fechaCamioneta], function (horario) {
                if (horario.servicios) {
                  if(horario.servicios[0].tipoPago) $scope.setTipoPago(horario.servicios[0])
                  horario.servicios[0].servicioP = null
                  if(horario.servicios.length > 1){
                    horario.servicios[0].servicioP = horario.servicios[1]._id
                  }
                  else {
                    let lahora = horario.servicios[0].Fecha.date.split(/ |:/)
                    lahora.splice(0, 1)
                    _.forEach(c[$scope.fechaCamioneta], function (subhorario) {
                      if(subhorario.servicios){
                        if(subhorario.servicios[0]._id !== horario.servicios[0]._id) {
                          var slahora = subhorario.servicios[0].Fecha.date.split(/ |:/)
                          slahora.splice(0, 1)
                          if(lahora[0] === slahora[0] && horario.servicios[0].Duracion >= 60 && Number(lahora[1]) < Number(slahora[1])){
                            //no puede haber un servicio cuado otro está en curso
                            horario.servicios[0].servicioP = subhorario.servicios[0]._id
                            // console.log("traslape servicios: "+horario.servicios[0].Colonia +' y '+ subhorario.servicios[0].Colonia)
                          } else if (lahora[0] === slahora[0] && lahora[1] === slahora[1]){
                            //no pueden iniciar a la misma hora
                            horario.servicios[0].servicioP = subhorario.servicios[0]._id
                          } 
                          /*Iteraciones entre mas servicios*/
                          var hserv = Math.trunc(horario.servicios[0].Duracion/60)
                          var mserv = horario.servicios[0].Duracion%60 //aumente de 30 minutos de todas maneras
                          var iteracion = hserv * 2 - 1 
                          if(mserv != 0)
                            iteracion += 1
                          if(lahora[1] == '00'){
                            lahora[1] = 0
                          }
                          var timeaux = []
                          timeaux.push(Number(lahora[0]))
                          timeaux.push(Number(lahora[1]))
                          // debugger
                          for(var i = 0; i < iteracion; i++){
                            timeaux[1] = timeaux[1]+30
                            if(timeaux[1] == 60){ //llega a cambio de hora
                              timeaux[0] = timeaux[0]+1
                              timeaux[1] = 0 //se reinician los minutos en CERO
                            }
                            if(timeaux[0] == slahora[0] && timeaux[1] == slahora[1]){
                              console.log("traslape servicios: "+horario.servicios[0].Colonia +' y '+ subhorario.servicios[0].Colonia)
                              horario.servicios[0].servicioP = subhorario.servicios[0]._id
                            }
                          }
                        }
                      }
                    })
                  } //Si solo hay un servicio
                  if (c.Negocio.Nombre == 'Estética') {
                    c.totalServicios += _.sumBy(horario.servicios[0].agendado, function (serv) {
                      return serv.nombreProducto != 'Descuento' && serv.nombreProducto != 'Incremento' && serv.NegocioId != '2'
                    })
                    horario.servicios[0].Servicios = +_.sumBy(horario.servicios[0].agendado, function (serv) {
                      return serv.nombreProducto != 'Descuento' && serv.nombreProducto != 'Incremento' && serv.NegocioId != '2'
                    })
                  }
                }
              })
              $scope.serviciosTotales += c.totalServicios
            }
            return ca._id == c._id
          })
        })
        if(preconf && $scope.fechaCamioneta === $scope.fechaHoy) { $scope.deletePreconfirmados(preconf) }
      })
    })
  })

  $scope.reacomodarTodos = () => {
    console.log('holi')
  }

  $scope.deletePreconfirmados = function (preconf) {
    _.forEach(preconf, function(p, index) {
      _.find($rootScope.camionetas, ct =>{
        if(p.id === ct._id) {
          _.find(ct[$scope.fechaCamioneta], s => {
            if(s.servicios && s.servicios[0]._id === p.servicio) {
              if(s.Estatus !== 'Espera') {
                preconf.splice(index, 1)
              }
            }
          })
        }
      })
    })
    if (preconf !== null && preconf.length > 0){
      sessionStorage.setItem('preConfirmados', JSON.stringify(preconf))
    } else {
      sessionStorage.removeItem('preConfirmados')
    }
  }

    /**
     * Muestra la información completa del servicio a realizar
     * @param  {Object} servicio Es un objeto con la información completa del servicio a realizar
     * @param  {String} size     Tamaño del modal a abrir
     */
  $scope.showServicio = function (servicio, servicioP, size) {
    if (!servicio) { console.log('There is no service here'); return true }
    if(servicioP !== null && servicioP != undefined){
      dataService.getInfoServicio(servicio._id, servicio.NegocioId, false, function (response) {
        servicio = response.data
        servicio.mascotas = []
        _.forEach(servicio.agendado, function (a) {
          if(servicio.cliente){
            a.mascota = _.find(servicio.cliente.mascotas, function (m) {
            return m._id == a.MascotaId
          })
          }
        
          if (servicio.mascotas.indexOf(a.mascota) < 0) {
            servicio.mascotas.push(a.mascota)
          }
        })
      }) //Obteniendo primer servicio
      dataService.getInfoServicio(servicioP, servicio.NegocioId, false, function (response) {
        servicioP = response.data
        servicioP.mascotas = []
        _.forEach(servicioP.agendado, function (a) {
          if(servicioP.cliente){
            a.mascota = _.find(servicioP.cliente.mascotas, function (m) {
            return m._id == a.MascotaId
          })
          }
          if (servicioP.mascotas.indexOf(a.mascota) < 0) {
            servicioP.mascotas.push(a.mascota)
          }
        })
        var modalInstance = $uibModal.open({
          templateUrl: 'modalShowServicioProblema.html',
          controller: 'showServicioModalInstanceCtrl',
          size: size,
          resolve: {
            servicio: function () {
              return servicio
            },
            servicioP: function () {
              return servicioP
            }, 
            fromServicesGrid: function() {
              return true
            }
          }
        })

        modalInstance.result.then(function (response) {
          if(response.tipoPago) {
            servicio.tipoPago = response.tipoPago
            setTipoPago(servicio)
          }
          if (response.Cancelado) {
            servicio.Estatus = 'Cancelado'
          } else if (response.Reagendado) {
            servicio.Estatus = 'Reagendado'
          } else {
            servicio.Estatus = 'Confirmado'
          }
        }, function () {
          $log.info('Modal dismissed at: ' + new Date())
        })
      })
    } else {
      dataService.getInfoServicio(servicio._id, servicio.NegocioId, false, function (response) {
        var servicioData = response.data
        servicioData.mascotas = []
      if(servicioData.cliente){
        _.forEach(servicioData.agendado, function (a) {
          
              a.mascota = _.find(servicioData.cliente.mascotas, function (m) {
            return m._id == a.MascotaId
          })
          if (servicioData.mascotas.indexOf(a.mascota) < 0) {
            servicioData.mascotas.push(a.mascota)
          }
        })
      }
        var modalInstance = $uibModal.open({
          templateUrl: 'modalShowServicio.html',
          controller: 'showServicioModalInstanceCtrl',
          size: size,
          resolve: {
            servicio: function () {
              return servicioData
            }, 
            servicioP: function(){
              return null
            }, 
            fromServicesGrid: function() {
              return true
            }
          }
        })

        modalInstance.result.then(function (response) {
          if (response.Cancelado) {
            servicioData.Estatus = 'Cancelado'
          } else if (response.Reagendado) {
            servicioData.Estatus = 'Reagendado'
          } else {
            servicioData.Estatus = 'Confirmado' 
            if(sessionStorage.getItem('preConfirmados')){
              var preconf = JSON.parse(sessionStorage.getItem('preConfirmados'))
              var index = preconf.findIndex(p => p.id === servicioData.CamionetaId)
              if (index !== -1) { 
                preconf.splice(index, 1)
                if (preconf !== null && preconf.length > 0 ) { 
                  sessionStorage.setItem('preConfirmados', JSON.stringify(preconf)) 
                } else {
                  sessionStorage.removeItem('preConfirmados')
                } 
              }
            }
          }
          if(response.tipoPago) {
            servicio.tipoPago = response.tipoPago
            $scope.setTipoPago(servicio)
          }
        }, function () {
          $log.info('Modal dismissed at: ' + new Date())
        })
      })
        // $scope.$apply();
    }
  }

  $scope.formatedDuracion = function (d) {
    if (d) {
      return Math.floor(d / 60) + ':' + Math.floor(d % 60)
    }
  }

  $scope.formatedDate = function (f) {
    if (f) {
      return Date.parse(f)
    }
  }

  $scope.setTipoPago = function (servicio) {
    switch (servicio.tipoPago) {
      case 'TPV':
        servicio.tipop = 'T' //inicial del tipo de pago del servicio
        break
      case 'Efectivo':
        servicio.tipop = 'E'
        break
      case 'Transferencia - Depósito':
        servicio.tipop = 'D'
        break
      case 'Terminado no pagado':
        servicio.tipop = 'N'
        break
      case 'Cheque':
        servicio.tipop = 'C'
        break
      default: 
        servicio.tipop = '?'
    }
  }

    /**
     * realiza los calculos necesarios para presentar totales de dinero, mascotas y traslados
     * @param  {Object} newValue  Servicios agendados
     * @param  {Object} oldValue) Servicios agendados
     */
  $scope.$watch('agendado', function (newValue, oldValue) {
    $scope.totalMascotas = 0
    $scope.totalTraslados = 0
    $scope.totalDinero = 0
    _.forEach($scope.agendado, function (a) {
      if (a.servicios) {
        $scope.totalMascotas += a.servicios[0].Servicios
        $scope.totalTraslados += 1
        $scope.totalDinero += a.servicios[0].PrecioTotal
      }
    })
  })
})
