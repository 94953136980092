'use strict'
Ilduomo.controller('clientesGeneradosController', function (
    $scope,
    $rootScope,
    dataService,
    $state) {
  $rootScope.setting.layout.pageSidebarMinified = true
  dataService.clienteGenerado(function (response) {
    $scope.generados = response.data
    console.log($scope.generados)
  })

  $scope.formAddCliente = function (c) {
    console.log('Agregar')
    console.log(c)
    $state.go('clienteAgregar', { cliente: c })
  }

  $scope.parse = function (date) {
    return Date.parse(date)
  }

  $scope.dropClienteGenerado = function (c) {
    c._method = 'DELETE'
    console.log('Eliminar')
    console.log(c._id)
    dataService.dropClienteGenerado(c, function (response) {
      if (response.data.Estatus == 'True') {
        $scope.generados = _.remove($scope.generados, function (g) {
          return g._id != c._id
        })
      }
    })
  }
})
