'use strict'
/**
 * Ilduomo Module
 */
Ilduomo.controller('tablaClientesCtrl', function(
  $scope,
  dataService,
  clienteService,
  $uibModal,
  $log,
  $state,
  $rootScope,
  $cookies,
  $auth,
  $stateParams,
  socket,
  $anchorScroll,
  $window
) {
  if (!$auth.isAuthenticated()) {
    $cookies.remove('user')
    localStorage.removeItem('satellizer_token')
    $rootScope.authenticated = false
    $rootScope.currentUser = null
    $state.go('login')
  }
  angular.element(function() {
    // var windowElement = angular.element($window)
    // windowElement.on('beforeunload', function (event) {
    // })
    $scope.mensaje = "Buenos%20días,%20quisiera%20saber%20si%20va%20a%20necesitar%20servicio.%20Un%20saludo"
    $rootScope.setting.layout.pageSidebarMinified = true
    $scope.data = {}
    $scope.error = {}
    $rootScope.clientes = []
    $scope.periodicidad = {}
    $scope.idClientes = []
    $scope.tiposTelefonos = []
    $scope.negocios = []
    $rootScope.current_page = 1
    $scope.expireDate = new Date()
    $scope.one_day = 1000 * 60 * 60 * 24
    $scope.expireDate.setDate($scope.expireDate.getDate() + 1)
    $scope.today = new Date()
    $scope.today.setHours(9,0,0,0)
    $scope.index_misllamadas = [
      'Cita Telefonica',
      'Llamada programada del día',
      'Periodicidad vencida entre hoy y dos dias posteriores',
      'Periodicidad vencida entre ayer y siete dias atras',
      // 'Periodicidad vencida entre tres meses atras y ocho meses atras',
      "Periodicidad vencida entre ocho dias atras y tres meses atras",
      'Periodicidad vencida entre tres meses atras y seis meses atras',
      'Periodicidad vencida entre seis meses atras al inicio',
      'Sin periodicidad'
    ]
    $scope.colores = {
      Agendadas: '#09961b',
      Programadas: '#07486d',
      Rechazadas: '#bb0303'
    }
    $scope.showAll = true
    $scope.showRemaining=false
    $scope.num = 10
    $scope.isopen = false
    // $scope.tipoAsignacion = null
    $scope.hidePopover = () => {
      var x = document.getElementsByClassName('popover')
      if (x[0]) x[0].style.visibility = 'hidden'
    }
    Date.prototype.addDays = function(days) {
      /* setTime(daysToBeAddedToMonth) */
      let date = new Date(this.valueOf())
      this.setDate(date.getDate() + days)
      return date
    }
    $scope.showMyAsign = which => {
      if (which === 'all') {
        $scope.showAll = !$scope.showAll
        $cookies.remove('inObject')
        $cookies.remove('inOption')
        $scope.tipoAsignacion = null
        $state.go('clienteClientes', { pedir: true }) // modifica el estado cambiado por cookie(inObject)
      } else {
        $scope.showAll = !$scope.showAll
        $scope.filtro = false
        var day = new Date()
        if (which === 'my') {
          $scope.tipoAsignacion = 'obligatoria'
          $cookies.get('inObject') ? null : $cookies.put('inObject', true) // el gerenteCC se encuentra en la tabla de asignacion
          $cookies.remove('inOption') || null
          $cookies.getObject('misAsignadas') ? null : $scope.getAsignadas()
          //console.log($cookies.get('misAsignadas'))
        } else {
          $scope.tipoAsignacion = 'opcional'
          $cookies.get('inOption') ? null : $cookies.put('inOption', true)
          $cookies.remove('inObject') || null
        }
        var fecha =
          day.getDayFormatted() +
          '-' +
          day.getMonthFormatted() +
          '-' +
          day.getFullYear()
        dataService.getObjetivo(fecha, $scope.tipoAsignacion, function(
          response
        ) {
          $rootScope.objetivo = response.data
        })
        $scope.getAllMyCalls($scope.tipoAsignacion)
      }
      // $scope.asignacion = $rootScope.clientes;
      // Pedir los registros de los objetivos e ir quitando poco a poco
    }
    $scope.getAllMyCalls = function(tipoAsignacion) {
      $scope.showRemaining = false
      if (
        (($cookies.get('pag') &&
          $cookies.get('urlAsig') &&
          $cookies.get('inObject') &&
          tipoAsignacion == 'obligatoria') ||
        ($cookies.get('pagOpt') &&
          $cookies.get('urlAsigOpt') &&
          $cookies.get('inOption') &&
          tipoAsignacion == 'opcional')) && !$scope.filtro
      ) {
        if (tipoAsignacion == 'obligatoria') {
          var url = $cookies.get('urlAsig')
          var pag = $cookies.get('pag')
        } else if (tipoAsignacion == 'opcional') {
          var url = $cookies.get('urlAsigOpt')
          var pag = $cookies.get('pagOpt')
        }
        clienteService.getClientesUrl(url + '?page=' + pag, function(response) {
          $scope.myObjetivo = response.data
          $scope.misllamadas = _.groupBy(response.data.data, s => {
            return s.motivos[0]
          })
          _.forEach($scope.misllamadas, mll => {
            _.forEach(mll, ml => {
              _.forEach(ml.cliente.Periodicidades, p => {
                _.forEach(ml.negocios, m => {
                  if (m == p.negocio.Nombre) p.motivo = true
                })
                $scope.getPeriodicidades(ml.cliente, true)
              })
            })
          })
          $anchorScroll()
          if($cookies.getObject('misAsignadas')){
            $scope.asigOblig = true
            $scope.modificaPorcentajes()
          }
        })
      } else {
        var day = new Date()
        var fecha =
          day.getDayFormatted() +
          '-' +
          day.getMonthFormatted() +
          '-' +
          day.getFullYear()
        dataService.getMisLlamadas(fecha, tipoAsignacion, function(response) {
          if (response.data.message) {
            iziToast.error({
              title: 'Error',
              // message: response.data.message,
              message: 'No hay asignación de este tipo',
              position: 'topCenter',
              timeout: 1200
            })
            return
          } else {
            $scope.myObjetivo = response.data
            var p = $scope.myObjetivo.first_page_url.split('=')
            if (tipoAsignacion == 'obligatoria') {
              $cookies.put('urlAsig', p[0])
              $cookies.put('pag', p[1])
            } else if (tipoAsignacion == 'opcional') {
              $cookies.put('urlAsigOpt', p[0])
              $cookies.put('pagOpt', p[1])
            }
            $scope.misllamadas = _.groupBy(response.data.data, s => {
              return s.motivos[0]
            })
            _.forEach($scope.misllamadas, mll => {
              _.forEach(mll, ml => {
                _.forEach(ml.cliente.Periodicidades, p => {
                  _.forEach(ml.negocios, m => {
                    if (m == p.negocio.Nombre) p.motivo = true
                  })
                })
                $scope.getPeriodicidades(ml.cliente, true)
              })
            })
          }
        })
      }
    }
    $scope.changeFromUrl = (myObjetivo, pag) => {
      $scope.tipoAsignacion == 'obligatoria' && !$scope.showRemaining
        ? $cookies.put('pag', pag)
        : $cookies.put('pagOpt', pag)
      $scope.tipoAsignacion == 'obligatoria' && !$scope.showRemaining
        ? $cookies.put('urlAsig', myObjetivo.path)
        : $cookies.put('urlAsigOpt', myObjetivo.path)
      clienteService.getClientesUrl(myObjetivo.path + '?page=' + pag, function(
        response
      ) {
        $scope.myObjetivo = response.data
        $scope.misllamadas = _.groupBy(response.data.data, s => {
          return s.motivos[0]
        })
        _.forEach($scope.misllamadas, mll => {
          _.forEach(mll, ml => {
            _.forEach(ml.cliente.Periodicidades, p => {
              _.forEach(ml.negocios, m => {
                if (m == p.negocio.Nombre) p.motivo = true
              })
            })
            $scope.getPeriodicidades(ml.cliente, true)
          })
        })
        $anchorScroll()
      })
    }

    $scope.find = index => {
      var day = new Date()
      var fecha =
        day.getDayFormatted() +
        '-' +
        day.getMonthFormatted() +
        '-' +
        day.getFullYear()
      $scope.showRemaining = false
      $scope.filtro = true
      var tipoAsignacion = $scope.tipoAsignacion || null
      $scope.index = index
      dataService.getCallFor(index, fecha, tipoAsignacion, function(response) {
        $scope.myObjetivo = response.data
        $scope.misllamadas = _.groupBy(response.data.data, s => {
          return s.motivos[0]
        })
        _.forEach($scope.misllamadas, mll => {
          _.forEach(mll, ml => {
            _.forEach(ml.cliente.Periodicidades, p => {
              _.forEach(ml.negocios, m => {
                if (m == p.negocio.Nombre) p.motivo = true
              })
            })
            $scope.getPeriodicidades(ml.cliente, true)
          })
        })
        if(response.data.data.length == 0){
          iziToast.show({
            color: 'yellow',
            timeout: 900,
            title: 'Mensaje',
            icon: 'ico-warning',
            close: true,
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            layout: 3,
            message: 'No se tienen llamadas por: ' + $scope.index,
            position: 'bottomRight'
          })
        }
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }
    $scope.getRemaining = function(){
      var day = new Date()
      var fecha =
        day.getDayFormatted() +
        '-' +
        day.getMonthFormatted() +
        '-' +
        day.getFullYear()
      $scope.showRemaining = true
      var tipoAsignacion = $scope.tipoAsignacion || null
      dataService.getRemainingCall(fecha,tipoAsignacion,function(response){
        $scope.myObjetivo = response.data
        $scope.misllamadas = _.groupBy(response.data.data, s => {
            return s.motivos[0]
          })
          _.forEach($scope.misllamadas, mll => {
            _.forEach(mll, ml => {
              _.forEach(ml.cliente.Periodicidades, p => {
                _.forEach(ml.negocios, m => {
                  if (m == p.negocio.Nombre) p.motivo = true
                })
                $scope.getPeriodicidades(ml.cliente, true)
              })
            })
          })
          $anchorScroll()
      })
    }
    $scope.ultima = () => {
      $cookies.put('pagina', $rootScope.last_page)
      clienteService.getClientesUrl($rootScope.last_page_url, function(
        response
      ) {
        $rootScope.clientes = response.data.clientes.data
        $rootScope.itemsPerPage = response.data.clientes.per_page
        $rootScope.next_page_url = response.data.clientes.next_page_url
        $rootScope.prev_page_url = response.data.clientes.prev_page_url
        $rootScope.path = response.data.clientes.path
        $rootScope.last_page_url = response.data.clientes.last_page_url
        $rootScope.last_page = response.data.clientes.last_page
        $rootScope.totalClientes = response.data.clientes.total
        $rootScope.current_page = response.data.clientes.current_page
        $scope.getPeriodicidades($rootScope.clientes)
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }
    $scope.primera = () => {
      $cookies.put('pagina', '1')
      clienteService.getClientesUrl($rootScope.path, function(response) {
        $rootScope.clientes = response.data.clientes.data
        $rootScope.itemsPerPage = response.data.clientes.per_page
        $rootScope.last_page = response.data.clientes.last_page
        $rootScope.next_page_url = response.data.clientes.next_page_url
        $rootScope.prev_page_url = response.data.clientes.prev_page_url
        $rootScope.path = response.data.clientes.path
        $rootScope.last_page_url = response.data.clientes.last_page_url
        $rootScope.totalClientes = response.data.clientes.total
        $rootScope.current_page = response.data.clientes.current_page
        $scope.getPeriodicidades($rootScope.clientes)
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }

    $scope.default = function() {
      $rootScope.clientes = null
      clienteService.getClientes(1, function(response) {
        $rootScope.clientes = response.data.clientes.data
        $rootScope.itemsPerPage = response.data.clientes.per_page
        $rootScope.last_page = response.data.clientes.last_page
        $rootScope.next_page_url = response.data.clientes.next_page_url
        $rootScope.prev_page_url = response.data.clientes.prev_page_url
        $rootScope.path = response.data.clientes.path
        $rootScope.last_page_url = response.data.clientes.last_page_url
        $rootScope.totalClientes = response.data.clientes.total
        $rootScope.current_page = response.data.clientes.current_page
        $scope.getPeriodicidades($rootScope.clientes)
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }

    $scope.cambia = pag => {
      $cookies.put('pagina', pag)
      clienteService.getClientesUrl($rootScope.path + '?page=' + pag, function(
        response
      ) {
        $rootScope.clientes = response.data.clientes.data
        $scope.currentPage = response.data.clientes.current_page
        $rootScope.itemsPerPage = response.data.clientes.per_page
        $rootScope.last_page = response.data.clientes.last_page
        $rootScope.next_page_url = response.data.clientes.next_page_url
        $rootScope.prev_page_url = response.data.clientes.prev_page_url
        $rootScope.path = response.data.clientes.path
        $rootScope.last_page_url = response.data.clientes.last_page_url
        $rootScope.totalClientes = response.data.clientes.total
        $rootScope.current_page = response.data.clientes.current_page
        $scope.getPeriodicidades($rootScope.clientes)
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }
    /* Busca en cookies si el usuario se quedó en alguna página de asignaciones */
    $scope.buscaPaginas = function() {
      if ($cookies.get('inObject')) {
        $scope.tipoAsignacion = 'obligatoria'
        var url = $cookies.get('urlAsig') || null
        var pagina = $cookies.get('pag') || null
      } else if ($cookies.get('inOption')) {
        $scope.tipoAsignacion = 'opcional'
        var url = $cookies.get('urlAsigOpt') || null
        var pagina = $cookies.get('pagOpt') || null
      } else {
        return false //no trae clientes porque no está en nigun tipo de asignación
      }
      if (url && pagina) {
        $scope.showAll = !$scope.showAll // si entra directo a la asignacion de llamadas, quita el showall
        var day = new Date()
        var fecha =
          day.getDayFormatted() +
          '-' +
          day.getMonthFormatted() +
          '-' +
          day.getFullYear()
        dataService.getObjetivo(fecha, $scope.tipoAsignacion, function(
          response
        ) {
          $rootScope.objetivo = response.data
        }) // obtiene el numero de llamadas a realizar
        clienteService.getClientesUrl(url + '?page=' + pagina, function(
          response
        ) {
          $scope.myObjetivo = response.data
          $scope.misllamadas = _.groupBy(response.data.data, s => {
            return s.motivos[0]
          })
          _.forEach($scope.misllamadas, mll => {
            _.forEach(mll, ml => {
              _.forEach(ml.cliente.Periodicidades, p => {
                _.forEach(ml.negocios, m => {
                  if (m == p.negocio.Nombre) p.motivo = true
                })
              })
              $scope.getPeriodicidades(ml.cliente, true)
            })
          })
          $anchorScroll()
        })
        return true
      }
    }
    if ($stateParams.pedir) {
      // if ($scope.tipoAsignacion)
      $scope.buscaPaginas()
      var url = $cookies.get('url') || null
      var pagina = $cookies.get('pagina') || null
      if (url && pagina) {
        clienteService.getClientesUrl(url + '?page=' + pagina, function(
          response
        ) {
          $rootScope.clientes = response.data.clientes.data
          $rootScope.itemsPerPage = response.data.clientes.per_page
          $rootScope.next_page_url = response.data.clientes.next_page_url
          $rootScope.prev_page_url = response.data.clientes.prev_page_url
          $rootScope.path = response.data.clientes.path
          $rootScope.last_page_url = response.data.clientes.last_page_url
          $rootScope.last_page = response.data.clientes.last_page
          $rootScope.totalClientes = response.data.clientes.total
          $rootScope.current_page = response.data.clientes.current_page
          $scope.getPeriodicidades($rootScope.clientes)
          $anchorScroll()
          if($cookies.getObject('misAsignadas')){
            $scope.asigOblig = true
            $scope.modificaPorcentajes()
        }
        })
      } else {
        $scope.default()
      }
    }
    if ($stateParams.buscar) {
      $cookies.remove('inObject') || null
      $cookies.remove('inOption') || null
      clienteService.busqueda($stateParams.buscar, function(response) {
        $rootScope.clientes = response.data.clientes.data
        $rootScope.itemsPerPage = response.data.clientes.per_page
        $rootScope.next_page_url = response.data.clientes.next_page_url
        $rootScope.prev_page_url = response.data.clientes.prev_page_url
        $rootScope.path = response.data.clientes.path
        $rootScope.last_page_url = response.data.clientes.last_page_url
        $rootScope.last_page = response.data.clientes.last_page
        $rootScope.totalClientes = response.data.clientes.total
        $rootScope.current_page = response.data.clientes.current_page
        $scope.getPeriodicidades($rootScope.clientes)
        $anchorScroll()
        if($cookies.getObject('misAsignadas')){
          $scope.asigOblig = true
          $scope.modificaPorcentajes()
        }
      })
    }
    $scope.parse = f => {
      return Date.parse(f)
    }

    /**
    * Funcion para calcular los días pasados desde el último contacto
    */

    $scope.diasTranscurridos = function(f, faltantes, diasSobrantes) {
      var parse = new Date(Date.parse(f))
      var diff = parse.getTime() - new Date().getTime()
      return Math.abs(Math.round(diff / $scope.one_day))
    }

    /* Calcula los dìas faltantes para el proximo contacto
     * @param   {String}  contacto     fecha del próximo contacto
     * @param   {Number}  adicionales  días que le sobraban al producto cuando se realizó el servicio
     * @return  {Number}  dias que faltan para el siguiente contacto
     */
    $scope.diasFaltantes = function (contacto, adicionales) {
      let parse = new Date(Date.parse(contacto)).setHours(0,0,0,0)
      let diff = parse - new Date().setHours(0,0,0,0)
      diff += $scope.one_day * adicionales
      return Math.round(diff / $scope.one_day)
    }

    /* Regresa lo que se mostrará al usuario, en función de los dias que falten/sobren del próximo contacto
     * @param {Date|Object}     fechaPeriodicidad  Fecha del siguiente contacto
     * @param {Number}   adicional          Numero de dias de producto sobrante
     * @return {String}  Dias faltantes para el proximo contacto, o aviso de vencido
     */
    $scope.diasFaltantesCaption = function (fechaPeriodicidad, adicionales = 0) {
      let contacto = new Date(Date.parse(fechaPeriodicidad.date))
      contacto.setHours(9,0,0,0)
      contacto = contacto.addDays(adicionales)
      if (contacto > $scope.today)
        return Math.round((contacto - $scope.today) / $scope.one_day) + ' días'
      else if (contacto < $scope.today)
        return 'Vencido'
      else if (contacto.getTime() === $scope.today.getTime())
        return 'Hoy'
      else
        return 'N/A'
    }

    $scope.semanasTranscurridas = f =>
      Math.abs(Math.round($scope.diasTranscurridos(f) / 7))

    /*$scope.verificaProgramada = function(cliente) {
      if (
        cliente.LlamadaProgramada !== undefined &&
        cliente.LlamadaProgramada.length > 0
      ) {
        var i = cliente.LlamadaProgramada.length-1
        while(i >= 0) {
          var llamada = cliente.LlamadaProgramada[i]
            if(llamada.FechaLlamada != undefined){
              if (
                Date.parse(llamada.FechaLlamada.date) >= new Date().setHours(0, 0, 0) &&
                Date.parse(llamada.FechaLlamada.date) <= new Date().setHours(23, 59, 59)
              ){
                return false
              }else{
                if(
                  Date.parse(llamada.FechaLlamada.date) > new Date().setHours(23, 59, 59)
                  )
                return true
              }
            }
          i--
        }
      }
    }*/

    $scope.showDetails = (idServicio, idNegocio, size) => {
      if (!idServicio) {
        iziToast.show({
          color: 'yellow',
          timeout: 900,
          title: 'Mensaje',
          icon: 'ico-warning',
          close: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          layout: 3,
          message: 'No se tiene más información de este servicio',
          position: 'topRight'
        })
      }
      if (idNegocio && idServicio) {
        dataService.getInfoServicio(idServicio, idNegocio, false, function(
          response
        ) {
          if (response.data.Error) {
            iziToast.show({
              color: 'yellow',
              timeout: 900,
              title: 'Mensaje',
              icon: 'ico-warning',
              close: true,
              transitionIn: 'flipInX',
              transitionOut: 'flipOutX',
              layout: 3,
              message: response.data.Error,
              position: 'topRight'
            })
            return
          }
          $scope.servicio = response.data
          $scope.servicio.mascotas = []
          _.forEach($scope.servicio.agendado, function(a) {
            a.mascota = _.find($scope.servicio.cliente.mascotas, function(m) {
              return m._id == a.MascotaId
            })
            if ($scope.servicio.mascotas.indexOf(a.mascota) < 0) {
              $scope.servicio.mascotas.push(a.mascota)
            }
          })
          var modalInstance = $uibModal.open({
            templateUrl: 'modalShowServicio.html',
            controller: 'showServicioModalInstanceCtrl',
            size: size,
            resolve: {
              servicio: function() {
                return $scope.servicio
              },
              servicioP: function() {
                return null
              },
              fromServicesGrid: function() {
                return false
              }
            }
          })

          modalInstance.result.then(
            function(response) {
              if (response.Cancelado) {
                $scope.servicio.Estatus = 'Cancelado'
              } else if (response.Reagendado) {
                $scope.servicio.Estatus = 'Reagendado'
              } else {
                $scope.servicio.Estatus = 'Confirmado'
              }
            },
            function() {
              $log.info('Modal dismissed at: ' + new Date())
            }
          )
        })
      }
    }
    // $scope.setPage = function (pageNo) {
    //     clienteService.getClientesPage(pageNo, function (response) {
    //       $rootScope.clientes = response.data.clientes.data;
    //       $scope.currentPage = response.data.clientes.current_page;
    //       $scope.itemsPerPage = response.data.clientes.per_page;
    //       $rootScope.totalClientes = response.data.clientes.total;
    //     });
    // };

    // clienteService.getClientes(function(response){
    // });

    if (sessionStorage.getItem('negocios')) {
      $scope.negocios = JSON.parse(sessionStorage.getItem('negocios'))
      $rootScope.negocios = JSON.parse(sessionStorage.getItem('negocios'))
      var color =
        _.find($scope.negocios, function(alias) {
          return alias.Nombre == 'Nutrición'
        }) || {}
      $scope.colorCapturar = color.Color
    } else {
      clienteService.getNegocios(function(response) {
        if (response.data.error == 'token_not_provided') {
          $rootScope.authenticated = false
          $rootScope.currentUser = null
          $state.go('login')
        }

        sessionStorage.setItem('negocios', JSON.stringify(response.data))
        $scope.negocios = response.data
        $rootScope.negocios = response.data
        // prueba
        // $scope.negocios.pop();
        // color del boton agregar alimento
        var color =
          _.find($scope.negocios, function(alias) {
            return alias.Nombre == 'Nutrición'
          }) || {}
        // console.log(color);
        $scope.colorCapturar = color.Color
        //
      })
    }

    $scope.historialServicios = function(clienteId, negocioId) {
      // se enruta para ver los servicios agendados en el historial
      $state.go('historicoServicio', {
        clienteId: clienteId,
        negocioId: negocioId
      })
    }

    /**
     * realizado con ES6 para probar compatibilidades
     * @param  {Obj} cancelado Servicio agendado que se cancelo y se mostrará la información
     * @param  {String} size Tamaño del modal que se abrirá
     * @return {Null}
     */
    $scope.muestraCancelacion = (cancelado, size) => {
      if (cancelado) {
        var modalInstance = $uibModal.open({
          templateUrl: 'muestraCancelacionModal.html',
          controller: 'muestraCancelacionModalInstance',
          size: size,
          resolve: {
            servicio: function() {
              return cancelado
            },
            titulo: function() {
              return 'Información de Cancelación'
            }
          }
        })

        modalInstance.result.then(
          function(r) {
            console.log(r)
          },
          function() {
            $log.info('Modal dismissed at: ' + new Date())
          }
        )
      } else {
        console.log('There is no cancel')
      }
    }
    /**
     * Modal para capturar los alimentos que consumen las mascotas de los clientes
     * @param  {Object} cliente Objeto del cliente a modificar
     * @param  {String} size    Tamaño con el cual se va a abrir el modal
     * @return {Null}
     */
    $scope.capturarAlimento = function(cliente, size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'capturarAlimentoModal.html',
        controller: 'capturarAlimentoModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Capturar'
          }
        }
      })

      modalInstance.result.then(
        function(cliente) {
          if (cliente.confirmar) {
            cliente._method = 'PUT'
            clienteService.updateCliente(cliente, function(response) {
              if (response.data.Estatus == 'True') {
                console.log(response.data)
              } else {
                console.log(response.data)
              }
            })
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Solo aparecera la función si es que está logueado con ciertos permisos
     * @param  {Object} cliente [el cliene a borrar para mostrar información al respecto]
     * @return {null}
     */
    $scope.dropClient = function(cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'alertDropClient.html',
        controller: 'dropClientModalInstance',
        size: 'md',
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Eliminar'
          }
        }
      })

      modalInstance.result.then(
        function(cliente) {
          if (cliente.confirmar) {
            cliente._method = 'DELETE'
            clienteService.dropCliente(cliente, function(response) {
              if (response.data.Estatus == 'True') {
                $rootScope.clientes = _.remove($rootScope.clientes, function(
                  c
                ) {
                  return c._id != cliente._id
                })
              } else {
                console.log(response.data)
              }
            })
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * poner alerta a un cliente, para asignar quien le llamará
     * @param {String} size    Tamaño del modal a abrir
     * @param {Object} cliente Cliente a agregar la alarma
     */
    $scope.setAlert = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'setAlertClient.html',
        controller: 'setAlertClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Alerta'
          }
        }
      })

      modalInstance.result.then(
        function(alerta) {
          clienteService.setAlerta(alerta, function(response) {
            if (response.data.Estatus == 'True') {
              cliente.Alerta = true
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * mostrar alerta de un cliente
     * @param {String} size    Tamaño del modal a abrir
     * @param {Object} cliente Cliente
     */
    $scope.showAlert = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'showAlertModal.html',
        controller: 'showAlertClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Alerta'
          }
        }
      })

      modalInstance.result.then(
        function(alerta) {
          if (alerta.confirmar) {
            alerta._method = 'DELETE'
            dataService.removeAlert(alerta, function(response) {
              if (response.data.Estatus == 'True') {
                cliente.Alerta = false
              }
            })
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }
    /**
     * Para editar los telefonos del cliente
     * @param  {String} size  [El tamaño del modal a mostrar]
     * @param  {Array} lista [Lista de los telefonos de contacto del cliente]
     * @return {Null}
     */
    $scope.editTelefonos = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editPhonesClient.html',
        controller: 'editPhonesClientModalInstance',
        size: size,
        resolve: {
          telephones: function() {
            return cliente.telefonos
          },
          title: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(telephones) {
          clienteService.updateTelefonosCliente(cliente, { telephones }, function(response) {
            if (response.data.Estatus !== 'True') {
              iziToast.error({
                title: 'Error',
                message: response.data.message,
                close: false,
                timeout: 2300
              })
            }
            else {
              cliente.telefonos = response.data.listaTelefonos
              iziToast.success({
                title: 'Teléfonos',
                message: 'actualizados correctamente',
                close: false,
                timeout: 2300
              })
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Edit fixed note of the client
     * @param  {String} size    Tamaño del modal a mostrar
     * @param  {Object} cliente objeto completo del cliente a editar
     * @return {Null}
     */
    $scope.updateNota = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editNotaClient.html',
        controller: 'editNotaClientModalInstance',
        size: size,
        resolve: {
          nota: function() {
            return cliente.Nota
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(nota) {
          clienteService.updateNota(cliente._id, { nota }, function(response) {
            if (response.data.Estatus !== 'True') {
              iziToast.error({
                title: 'Error',
                message: response.data.message,
                close: false,
                timeout: 2300
              })
            } else {
              cliente.Nota = nota
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * editar la informacion básica del cliente
     * @param  {String} size    Tamaño del modal a abrir
     * @param  {Object} cliente Objeto completo para modificar la informacion
     * @return {Null}
     */
    $scope.editCliente = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editInfoClient.html',
        controller: 'editInfoClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(c) {
          c._method = 'PUT'
          clienteService.updateInfo(c, function(response) {
            console.log(response.data)
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * edita contactos extras del cliente
     * @param  {String} size    [description]
     * @param  {[type]} cliente [description]
     * @return {[type]}         [description]
     */
    $scope.editContactos = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editContactosClient.html',
        controller: 'editContactosClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(c) {
          c._method = 'PUT'
          clienteService.updateContactos(c, function(response) {
            if (!response.data.Estatus == 'True') {
              iziToast.error({
                title: 'Error',
                message: response.data.message,
                close: false,
                timeout: 2300
              })
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Modal de editar mascotas de un cliente
     * @param  {String} size    Tamaño del modal
     * @param  {Object} cliente Objeto del cliente completo para poder editar sus mascotas
     */
    $scope.editMascotas = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editMascotasClient.html',
        controller: 'editMascotasClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(clienteUpdate) {
          clienteUpdate._method = 'PUT'
          dataService.updateMascotas(clienteUpdate, function(response) {
            if (response.data.Estatus !== 'True') {
              iziToast.error({
                title: response.data.Estatus,
                message: response.data.message,
                timeout: 2300
              })
            } else {
              cliente.listaMascotas = response.data.mascotas
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * modal para editar direccion
     * @param  {String} size    Tamaño del modal
     * @param  {Object} cliente Objeto completo del cliente a editar su ubicacion
     */
    $scope.editDireccion = function(size, cliente) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editDireccionClient.html',
        controller: 'editDireccionClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(
        function(clienteUpdated) {
          clienteUpdated._method = 'PUT'
          clienteService.updateUbicacion(clienteUpdated, function(response) {
            if (response.data.Estatus == 'True') {
              cliente.direccion  = response.data.direccion
              cliente.municipioNombre = response.data.municipioNombre
              cliente.coloniaNombre = response.data.coloniaNombre
              cliente.CP = response.data.CP
              iziToast.success({
                position: 'topRight',
                title: 'Hecho',
                message: 'Dirección actualizada correctamente',
                close: false,
                timeout: 2300
              })
            } else {
              iziToast.error({
                title: response.data.Estatus,
                message: response.data.message,
                close: false,
                timeout: 2300
              })
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    $scope.editMaterial = function(size, client) {
      var modalInstance = $uibModal.open({
        templateUrl: 'editMaterialClient.html',
        controller: 'editMaterialClientModalInstance',
        size: size,
        resolve: {
          material: function() {
            return client.Material
          },
          titulo: function() {
            return 'Material requerido'
          }
        }
      })

      modalInstance.result.then(
        function(equipmentNote) {
          clienteService.updateMaterial(client._id, { equipmentNote }, function(response) {
            if (response.data.Estatus !== 'True') {
              iziToast.error({
                title: response.data.Estatus,
                message: response.data.message,
                timeout: 2300
              })
            } else {
              client.Material = equipmentNote
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }
    /**
     * modal para programar una llamada en una fecha en especifico a un cliente
     * @param  {String} size    tamaño del modal
     * @param  {Object} cliente objeto completo del cliente a programarle la llamada
     * @return {NULL}
     */

    $scope.programarLlamada = function(size, cliente, ...respuestaDeLlamada) {
      var modalInstance = $uibModal.open({
        templateUrl: 'programarLlamadaClient.html',
        controller: 'programarLlamadaClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          negocios: function() {
            return $scope.negocios
          },
          titulo: function() {
            return 'Programar'
          },
          tipoAsignacion: function() {
            return $scope.tipoAsignacion || null
          }
        }
      })

      modalInstance.result.then(
        function(lp) {
          if(respuestaDeLlamada[0]) { //se programó después de realizar una llamada de asignación
            lp.llamadaCompletada = true
            lp.tipoAsignacion = $scope.tipoAsignacion || null
          }
          dataService.agregaLlamadaProgramada(lp, function(response) {
            if (response.data.Estatus == 'True') {
              let negocios = lp.Negocios.map(n => n.NegocioId)
              let llamadas =  []
              _.forEach(cliente.LlamadaProgramada, (clp) => {
                //clp es un arreglo de llamadas programadas
                let filtrados = _.filter(clp,
                  function(lll) {
                    return !negocios.includes(lll.NegocioId)
                  }
                )
                if (filtrados.length > 0) llamadas.push(filtrados)
              })
              cliente.Estatus = true //temp para mostrar color verde
              // $scope.$apply()
              cliente.LlamadaProgramada = llamadas.concat(response.data.LlamadaProgramada)
              if(response.data.Asignacion != undefined && response.data.Asignacion === 'obligatoria'){
                $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
              }
                respuestaDeLlamada[1] && response.data.estatusNuevo ? respuestaDeLlamada[1] = response.data.estatusNuevo : null
              //response.data.SinCambio == -1 && respuestaDeLlamada[1] ? respuestaDeLlamada[1] = 'Realizada' : null
            } else if (response.data.Estatus == 'Error') {
              iziToast.error({
                title: response.data.Estatus,
                message: response.data.mensaje,
                close: false,
                timeout: 2300
              })
            }
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * modal para programar una llamada en una fecha en especifico a un cliente
     * @param  {String} size    tamaño del modal
     * @param  {Object} cliente objeto completo del cliente a programarle la llamada
     * @param  {idLlamadaProgramada} id de la llamada que se va a Reprogramar
     * @return {NULL}
     */
    $scope.reprogramarLlamada = function(
      size,
      cliente,
      llamadas,
      ...llamada
    ) {
      var modalInstance = $uibModal.open({
        templateUrl: 'programarLlamadaClient.html',
        controller: 'reprogramarLlamadaClientModalInstance',
        size: size,
        resolve: {
          titulo: function() {
            return 'Re Programar'
          },
          cliente: function() {
            return cliente
          },
          negocios: function() {
            return $scope.negocios
          },
          negociosReprogramar: function() {
            return llamadas.map(l => l.negocio)
          }
        }
      })

      modalInstance.result.then(
        function(lp) {
          lp.tipoAsignacion = $scope.tipoAsignacion || null
          if($stateParams.buscar && $cookies.getObject('misAsignadas') ){
            lp.tipoAsignacion = 'indefinida'
          }
          _.forEach(lp.Negocios, (negocio) => {
            let llamadaReprogramar = _.find(llamadas, l => l.NegocioId === negocio.NegocioId)
            lp._id = llamadaReprogramar._id
            lp._method = 'PUT'
            dataService.updateLlamadaProgramada(lp, function(response) {
              if (response.data.Estatus == 'True') {
                var obj = response.data.LlamadaProgramada
                obj.respuestaTemporal = llamadaReprogramar.resultado_llamada.Nombre
                cliente.LlamadaProgramada = cliente.LlamadaProgramada || []
                let llamadasNuevas = []
                _.forEach(cliente.LlamadaProgramada, (aux) => {
                  let filtered = _.filter(aux, (l) => l.NegocioId !== obj.NegocioId)
                  if (filtered.length > 0) llamadasNuevas.push(filtered)
                })
                cliente.LlamadaProgramada = llamadasNuevas
                cliente.LlamadaProgramada.push([obj])
                if(response.data.Asignacion != undefined){
                  if(response.data.Asignacion === 'obligatoria'){
                    $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
                  }
                  llamada[0] && response.data.estatusNuevo ? llamada[0].Estatus = response.data.estatusNuevo : null
                  //response.data.SinCambio == -1 && llamada[0] ? llamada[0].Estatus = 'Realizada' : null
                }
              } else if (response.data.Estatus == 'Error') {
                iziToast.error({
                  title: response.data.Estatus,
                  message: response.data.mensaje,
                  close: false,
                  timeout: 2300
                })
              }
            })
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Respuesta de llamada realizada por callcenter
     * @param  {String} size    tamaño del modal a abrir
     * @param  {Object} cliente para poder relacionarlo con la llamada
     */
    $scope.programarRespuestaLlamada = function(
      size,
      cliente,
      objetivo,
      tipoLLamada,
      llamada
    ) {
      var modalInstance = $uibModal.open({
        templateUrl: 'programarRespuestaLlamadaClient.html',
        controller: 'programarRespuestaLlamadaClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          negocios: function() {
            return $scope.negocios
          },
          titulo: function() {
            return 'Programar Respuesta'
          }
        }
      })

      modalInstance.result.then(
        function(rl) {
          rl.respuestallamada.FranquiciaId = $rootScope.currentUser.FranquiciaId
          rl.respuestallamada.tipoLLamada = tipoLLamada || null
          rl.respuestallamada.objetivo = objetivo || null
          rl.respuestallamada.depuracion = rl.cliente.Depuracion
          rl.respuestallamada.tipoAsignacion = $scope.tipoAsignacion || null
          //delete rl.respuestallamada.cliente
          if($stateParams.buscar && $cookies.getObject('misAsignadas')){
              objetivo = true
          }
          dataService.agregaRespuestaLlamada(
            rl.respuestallamada,
            objetivo,
            function(response) {
              if (response.data.Estatus == 'True') {
                let agendados = rl.respuestallamada.respuestas.filter(r => r.RespuestaLlamada.Agendar)
                _.forEach(rl.respuestallamada.respuestas, resp => {
                  if (resp.RespuestaLlamada.Agendar) {
                    if(response.data.Asignacion && response.data.Asignacion === 'obligatoria'){
                      $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
  /*                    var realizadas = JSON.parse($cookies.getObject('misAsignadas'))
                      realizadas['Agendadas'] += 1
                      realizadas['SinCambio'] += response.data.SinCambio
                      $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })*/
                    }
                  } else if (
                      resp.RespuestaLlamada.Nombre ==
                        'Activo, contesto una 3ra persona' ||
                      resp.RespuestaLlamada.Nombre ==
                        'Activo, no por el momento' ||
                      resp.RespuestaLlamada.Nombre ==
                        'Linea ocupada' ||
                      resp.RespuestaLlamada.Nombre ==
                        'Nadie contesta' ||
                      resp.RespuestaLlamada.Nombre ==
                        'Numero fuera de servicio' ||
                      resp.RespuestaLlamada.Nombre ==
                        'Contesto una 3ra persona'
                    ) {
                      if(response.data.Asignacion && response.data.Asignacion === 'obligatoria') {
                        $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
                        // var realizadas = JSON.parse($cookies.getObject('misAsignadas'))
                        // realizadas['SinCambio'] += response.data.SinCambio
                        // $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) }
                        rl.respuestallamada.objetivo = true
                        llamada != undefined && response.data.estatusNuevo ? llamada.Estatus = response.data.estatusNuevo : null
                      }
                      $scope.programarLlamada('md', cliente, rl.respuestallamada.objetivo,llamada) // aqui se lanza el modal de programar llamada
                    } else{
                      if(response.data.Asignacion && response.data.Asignacion === 'obligatoria'){
                        $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
                        /*var realizadas = JSON.parse($cookies.getObject('misAsignadas'))
                        realizadas['Rechazadas'] += 1
                        realizadas['SinCambio'] += response.data.SinCambio
                        $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })
                        $scope.modificaPorcentajes();*/
                      }
                      llamada != undefined && response.data.estatusNuevo ? llamada.Estatus = response.data.estatusNuevo : null
                    }
                    if (agendados.length < 1) {
                      cliente.LlamadaPerdida = cliente.LlamadaPerdida + 1 || 1
                    }
                })
                if(agendados.length > 0) {
                  let date = new Date()
                  date.setDate(date.getDate() + 1)
                  $scope.date =
                    date.getDayFormatted() +
                    '-' +
                    date.getMonthFormatted() +
                    '-' +
                    date.getFullYear()
                  let negociosId = agendados.map(a => a.NegocioId).join('-')
                  $state.go('clienteAgendar', {
                    cliente: cliente,
                    clienteId: cliente._id,
                    negociosId: negociosId,
                    agendado: null,
                    dia: $scope.date,
                    serv: null,
                    fromServicesGrid: false
                    // objetivo: $scope.tipoAsignacion || null
                  })
                }
              } else if (response.data.Estatus == 'Error') {
                iziToast.error({
                  title: response.data.Estatus,
                  message: response.data.mensaje,
                  close: false,
                  timeout: 1500
                })
              }
            }
          )
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Realizar LLamada Programada: una vez que se programa una llamada se debe realizar
     * @param  {String} size    tamaño del modal a abrir
     * @param  {Object}  cliente para poder relacionarlo con la llamada
     * @param  {Array}  lp   arreglo de Llamadaprogramada(s) (objetos) a contestar
     */
    $scope.RealizarLLamadaProgramada = function(
      size,
      cliente,
      lp,
      objetivo = false,
      tipoLLamada = null,
      llamada = null
    ) {
      var modalInstance = $uibModal.open({
        templateUrl: 'RealizarLLamadaProgramada.html',
        controller: 'RealizarLLamadaProgramadaModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return cliente
          },
          llamadaprogramada: function() {
            return lp
          },
          titulo: function() {
            return 'Realizar llamada programada'
          }
        }
      })
      modalInstance.result.then(
        function(respuestaLlamadas) {
          let save = {}
          save._method = 'PUT'
          save.tipoAsignacion = $scope.tipoAsignacion || null
          save.tipoLLamada = tipoLLamada
          if($stateParams.buscar && $cookies.getObject('misAsignadas')){
            save.objetivo = true
          }
          save.respuestaLlamadas = respuestaLlamadas
          _.forEach(save.respuestaLlamadas, function(rl){
            rl.Estatus = 'Realizada'
            rl.ResultadoLlamadaId = rl.ResultadoLlamadaId || rl.Agendado._id
          })
          dataService.agregarRespuestaLlamadasProgramadas(
            save,
            objetivo,
            function(response) {
              if (response.data.Estatus == 'False') {
                iziToast.error({
                  position: 'topRight',
                  title: 'Error',
                  message: response.data.message
                })
                return false
              }
              cliente.LlamadaProgramada = cliente.LlamadaProgramada || []
              // _.remove(cliente.LlamadaProgramada, lp => {
              //   return rl._id == lp._id
              // })
              let negocios = response.data.LlamadasProgramadas.map(lp => lp.NegocioId)
              let llamadasFiltradas = []
              _.forEach(cliente.LlamadaProgramada, function(clp){
                let filtradas = _.filter(clp, (aux) => !negocios.includes(aux.NegocioId))
                if (filtradas.length > 0) llamadasFiltradas.push(filtradas)
              })
              cliente.LlamadaProgramada = llamadasFiltradas
              let llamadas = {
                agendar: [],
                reprogramar: []
              }
              _.forEach(response.data.LlamadasProgramadas, function(llamadaProgramada) {
                var p = _.find(cliente.Periodicidades, function(per) {
                  return per.negocio._id == llamadaProgramada.NegocioId
                })
                p.estatus =
                  llamadaProgramada.nuevoEstatus.CambiaEstatus == 1
                    ? llamadaProgramada.nuevoEstatus
                    : p.estatus
                if (llamadaProgramada.Agendado) {
                  llamadas.agendar.push(llamadaProgramada)
                  if(response.data.Asignacion != undefined && response.data.Asignacion === 'obligatoria'){
                    // $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
                    /*var realizadas = JSON.parse($cookies.getObject('misAsignadas'));
                    realizadas['SinCambio'] += response.data.SinCambio
                    realizadas['Agendadas'] += 1;
                    $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })*/
                  }
                }
                else if (
                  llamadaProgramada.resultado_llamada.Nombre == 'Activo, contesto una 3ra persona' ||
                  llamadaProgramada.resultado_llamada.Nombre == 'Activo, no por el momento' ||
                  llamadaProgramada.resultado_llamada.Nombre == 'Linea ocupada' ||
                  llamadaProgramada.resultado_llamada.Nombre == 'Nadie contesta' ||
                  llamadaProgramada.resultado_llamada.Nombre == 'Numero fuera de servicio' ||
                  llamadaProgramada.resultado_llamada.Nombre == 'Contesto una 3ra persona'
                ) {
                  llamadas.reprogramar.push(llamadaProgramada)
                  if(response.data.Asignacion != undefined ) {
                    if(response.data.Asignacion === 'obligatoria'){
                      $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)
                      /*var realizadas = JSON.parse($cookies.getObject('misAsignadas'));
                      realizadas['SinCambio'] += response.data.SinCambio
                      $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })*/
                    }
                    llamada != undefined && response.data.estatusNuevo ? llamada.Estatus = response.data.estatusNuevo : null
                  }
                  // $scope.reprogramarLlamada(
                  //   'md',
                  //   cliente,
                  //   rl._id,
                  //   rl.Agendado.Nombre,
                  //   llamada
                  // ) // aqui se lanza el modal de reprogramar llamada
                }else{
                  if(response.data.Asignacion != undefined && response.data.Asignacion === 'obligatoria'){
                    $scope.modificaIndicadores(response.data.estatusAnterior,response.data.estatusNuevo)

                      /*var realizadas = JSON.parse($cookies.getObject('misAsignadas'))
                      realizadas['Rechazadas'] += 1;
                      realizadas['SinCambio'] += response.data.SinCambio
                      $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })
                      $scope.modificaPorcentajes();*/
                  }
                  llamada != undefined && response.data.estatusNuevo ? llamada.Estatus = response.data.estatusNuevo : null
                }
              })
              cliente.LlamadaProgramada.push(response.data.LlamadasProgramadas)
              if (llamadas.agendar.length > 0) {
                let date = new Date()
                date.setDate(date.getDate() + 1)
                $scope.date =
                  date.getDayFormatted() +
                  '-' +
                  date.getMonthFormatted() +
                  '-' +
                  date.getFullYear()
                $state.go('clienteAgendar', {
                  cliente: cliente,
                  clienteId: cliente._id,
                  negociosId: llamadas.agendar.map(a => a.NegocioId).join('-'),
                  agendado: null,
                  serv: null,
                  fromServicesGrid: false,
                  dia: $scope.date
                  // objetivo: $scope.tipoAsignacion || null
                })
              }
              else if (llamadas.reprogramar.length > 0) {
                // aqui se lanza el modal de reprogramar llamada
                $scope.reprogramarLlamada(
                  'md',
                  cliente,
                  llamadas.reprogramar,
                  llamada
                )
              }
            }
          )
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * modal para modificar la periodicidad de un servicio para el cliente
     * @param  {String} size Tamaño del modal
     * @param  {Object} p    Periodicidad de servicio
     */
    $scope.modificaPeriodicidad = function(size, p) {
      var periodicityAux = p.Periodicidad
      var modalInstance = $uibModal.open({
        templateUrl: 'modificaPeriodicidadClient.html',
        controller: 'modificaPeriodicidadClientModalInstance',
        size: size,
        resolve: {
          periodicidad: function() {
            p.Periodicidad = Number(p.Periodicidad) / 7
            return p
          },
          subPeriodicidad: function() {
            return null
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(function (periodicidad) {
        periodicidad._method = 'PUT'
        var periodicidadW = periodicidad.Periodicidad
        periodicidad.Periodicidad = periodicidad.Periodicidad * 7
        clienteService.updatePeriodicidad(periodicidad, function(response) {
          periodicidadNotification(response, periodicidadW, 'semana(s)')
          p.PeriodicidadW = periodicidadW
        })
      }, function() {
          $log.info('Modal dismissed at: ' + new Date())
          p.Periodicidad = Number(periodicityAux)
        }
      )
    }

    $scope.modificaSubperiodicidad = function (size, p, sub) {
      var modalInstance = $uibModal.open({
        templateUrl: 'modificaPeriodicidadClient.html',
        controller: 'modificaPeriodicidadClientModalInstance',
        size: size,
        resolve: {
          periodicidad: function() {
            return null
          },
          subPeriodicidad: function() {
            return sub
          },
          titulo: function() {
            return 'Editar'
          }
        }
      })

      modalInstance.result.then(function(subPeriodicidad) {
        subPeriodicidad._method = 'PUT'
        if(p.Conjunto) {
          clienteService.updatePeriodicidadConjunto(subPeriodicidad, function(response) {
            periodicidadNotification(response, subPeriodicidad.Periodicidad, 'dias')
            if(p.TiempoFaltante) {
              var diasDiferencia = p.PeriodicidadD - subPeriodicidad.Periodicidad
              p.TiempoFaltante = p.TiempoFaltante - diasDiferencia
            }
            p.PeriodicidadD = subPeriodicidad.Periodicidad
            _.forEach(p.subPeriodicidad, (s) => s.Periodicidad = subPeriodicidad.Periodicidad) //actualizando todas
          })
        } else {
          clienteService.updatePeriodicidad(subPeriodicidad, function(response) {
            periodicidadNotification(response, subPeriodicidad.Periodicidad, 'dias')
            if(p.inmediatoId === sub._id) {
              if(p.TiempoFaltante) {
                var diasDiferencia = p.PeriodicidadD - subPeriodicidad.Periodicidad
                p.TiempoFaltante = p.TiempoFaltante - diasDiferencia
                sub.TiempoFaltante = p.TiempoFaltante
              }
              p.PeriodicidadD = subPeriodicidad.Periodicidad
            }
          })
        }
      }, function() {
          $log.info('Modal dismissed at: ' + new Date())
      })
    }

    function periodicidadNotification(response, numero, unidades) {
      if(response.data.Estatus == 'True'){
        iziToast.success({
          title: 'Periodicidad actualizada',
          message: numero + ' ' + unidades + '.',
          position: 'bottomRight',
          timeout: 2300
        })
      } else {
        iziToast.error({
          title: 'Error al cambiar periodicidad',
          message: 'Vuelve a intentarlo',
          position: 'bottomRight',
          timeout: 2300
        })
      }
    }

    $scope.getPeriodicidades = function(clientes, ...cliente){      //le da forma a las periodicidades
      if(cliente[0]) clientes = [clientes]
      _.forEach(clientes, c => {
         c.period = {}
         var sub = {}
        _.forEach(c.Periodicidades, p => {
          if(p.negocio.Periodicidad === 'Producto' && !p.ProductoId) {
            c.period.estatus = p.estatus //El estatus debe ser el mismo para todas
            c.period.negocio = p.negocio //el negocio es el mismo
            c.period.PeriodicidadD = Number(p.Periodicidad)
            c.period.Reagendado = p.Reagendado
            c.period.FechaUltimo = p.FechaUltimo
            c.period.CamionetaUltimo = p.CamionetaUltimo
            c.period.ProximoId = p.ProximoId
            c.period.UltimoId = p.UltimoId
            c.period.proximo = p.proximo
            c.period.ultimo = p.ultimo
            c.period._id = p._id
            c.period.Conjunto= p.Conjunto || false
            if (p.FechaPeriodicidad) {
              c.period.TiempoFaltante = $scope.diasFaltantes(p.FechaPeriodicidad.date, 0)
            } else {
              c.period.TiempoFaltante = null
            }
          }
          if(p.negocio.Periodicidad === 'Producto' && p.ProductoId) {
            sub = {}
            if (c.period.negocio === undefined) { c.period.negocio = p.negocio }
            c.period.subPeriodicidad = c.period.subPeriodicidad || []
            sub._id = p._id
            sub.ProductoId = p.ProductoId
            sub.NegocioId = p.NegocioId
            sub.ProductoNombre = p.ProductoNombre
            sub.Periodicidad = Number(p.Periodicidad)
            sub.FechaUltimo = p.FechaUltimo || 'n/a'
            sub.FechaPeriodicidad = p.FechaPeriodicidad
            sub.Reagendado = p.Reagendado
            sub.Remanente = Number(p.Remanente) || 0
            sub.Nota = p.Nota || ""
            sub.CamionetaUltimo = p.CamionetaUltimo
            sub.ProximoId = p.ProximoId
            sub.UltimoId = p.UltimoId
            sub.proximo = p.proximo
            sub.ultimo = p.ultimo
            sub.ClienteId = p.ClienteId
            if(p.PeriodicidadA !== undefined) { sub.PeriodicidadA = p.PeriodicidadA }
            if (p.FechaPeriodicidad) {
              sub.TiempoFaltante = $scope.diasFaltantes(p.FechaPeriodicidad.date, sub.Remanente)
            } else {
              sub.TiempoFaltante = null
            }
            c.period.subPeriodicidad.push(sub)
          }
        })
        if(c.period.subPeriodicidad) {
          $scope.ordenaPeriodicidadProducto(c) //se ordenarán las periodicidades con varios productos
          c.Periodicidades = c.Periodicidades.filter(function(p){
            return p.negocio.Periodicidad !== 'Producto'
          }) //se quita la per. de producto por defecto y se establece la periodicidad modificada
          c.Periodicidades.push(c.period)
          c.period = null
        }
      })
    }

    /**
    * Función que determina el producto más cercano por el que se va a llamar en CC
    * @param cliente    Es el cliente del cual se determinará la periodicidad más próxima
    * @return cliente.Periodicidades    las periodicidades ordenadas
    * Se tiene un rango de +8 días (8 expirados) para la prioridad del producto
    */
    $scope.ordenaPeriodicidadProducto = function (cliente) {
      cliente.period.subPeriodicidad = _.orderBy(cliente.period.subPeriodicidad, sb => sb.TiempoFaltante, 'asc')
      var aux = _.partition(cliente.period.subPeriodicidad, function(sb) {
        return sb.TiempoFaltante && sb.TiempoFaltante > -9
      })
      if (aux[1].length > 0) {
        //ordena los expirados (descendente)
        aux[1] = _.orderBy(aux[1], i => { return i.TiempoFaltante }, 'desc')
      }
      cliente.period.subPeriodicidad = aux[0].concat(aux[1])
      var inmediato = aux[0].length > 0
        ?
        //ordena los no expirados y regresa más cercano
        _.minBy(aux[0], function(sb) {
          if (sb.TiempoFaltante >= -8) {
            return sb.TiempoFaltante || 0
          }
        })
        :
        _.maxBy(aux[1], function(sb) {
          return sb.TiempoFaltante || 0
        })
      if (inmediato) {
        cliente.period.PeriodicidadD = inmediato.Periodicidad
        cliente.period.inmediatoId = inmediato._id
        cliente.period.TiempoFaltante = inmediato.TiempoFaltante
        cliente.period.Reagendado = inmediato.Reagendado
        cliente.period.FechaPeriodicidad = inmediato.FechaPeriodicidad
        // cliente.period.FechaUltimo = inmediato.FechaUltimo
        // cliente.period.CamionetaUltimo = inmediato.CamionetaUltimo
        // cliente.period.ProximoId = inmediato.ProximoId //En estos tres casos, se mostrará el ultimo servicio en general
        // cliente.period.UltimoId = inmediato.UltimoId
        // cliente.period.proximo = inmediato.proximo
        // cliente.period.ultimo = inmediato.ultimo
        cliente.period.Nota = inmediato.Nota
        cliente.period.Remanente = inmediato.Remanente
      } else {
        console.log("No se pudo obtener periodicidad más cercana")
      }
    }

    $scope.getAsignadas = function(){
      var day = new Date()
      var fecha = day.getDayFormatted() +
      '-' +
      day.getMonthFormatted() +
      '-' +
      day.getFullYear()
      $scope.indexes = [
            'Cita Telefonica',
            'Llamada programada del día',
            'Periodicidad vencida entre hoy y dos dias posteriores',
            'Periodicidad vencida entre ayer y siete dias atras',
            'Periodicidad vencida entre ocho dias atras y tres meses atras',
          ];
      dataService.getNumObligatorias(fecha,function(response){
       if(response.data.message === 'still not asigned'){
          $rootScope.asigOblig = false
          return
        }
        $rootScope.asigOblig = true
        var a = response.data[0][0]
        $scope.todas = {}
        $scope.todas['Agendadas'] = 0
        $scope.todas['Rechazadas'] = 0
        $scope.todas['Programadas'] = 0
        $scope.todas['SinCambios'] = 0
        $scope.todas.total = 0
        _.forEach($scope.indexes,function(i){
          if(a.Completadas[i] != undefined && a.Completadas[i] != null){
            $scope.todas['SinCambios'] += a.Completadas[i].Sincambio
            $scope.todas['Agendadas'] += a.Completadas[i].Agendadas
            $scope.todas['Programadas'] += a.Completadas[i].Programadas
            $scope.todas['Rechazadas'] += parseInt(a.Completadas[i].Rechazadas)
          }
          if(a.Completadas[i] != undefined && a.Completadas[i] != null){ $scope.todas.total += a.Asignadas[i] }
        })
        _.forEach(response.data.Asignacion,function(i){
          $scope.todas.total += 1
        })
        $cookies.putObject('misAsignadas',JSON.stringify($scope.todas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })
        $scope.modificaPorcentajes();
      })
    }

    $scope.modificaPorcentajes = function(){
      $scope.todas = JSON.parse($cookies.getObject('misAsignadas'))
      $scope.porcentajes = {}
      $scope.porcentajes['Agendadas'] = parseFloat(($scope.todas['Agendadas']/$scope.todas.total)*100).toFixed(2)
      $scope.porcentajes['Rechazadas'] = parseFloat(($scope.todas['Rechazadas']/$scope.todas.total)*100).toFixed(2)
      $scope.porcentajes['Programadas'] = parseFloat(($scope.todas['Programadas']/$scope.todas.total)*100).toFixed(2)
      //$scope.porcentajes['SinCambios'] = $scope.todas['SinCambios']
      $scope.porcentajes['Total'] = (parseFloat(($scope.todas['Agendadas']/$scope.todas.total)*100) +
                                  parseFloat(($scope.todas['Rechazadas']/$scope.todas.total)*100) +
                                parseFloat(($scope.todas['Programadas']/$scope.todas.total)*100)).toFixed(2)
    }

    $scope.modificaIndicadores = function(estatusAnterior, estatusNuevo){
      let realizadas = JSON.parse($cookies.getObject('misAsignadas'))
      let eAnterior = estatusAnterior.concat("s")
      let eNuevo = estatusNuevo.concat("s")
      if(eAnterior === 'Pendientes'){
        if(eNuevo === 'Agendadas' || eNuevo === 'Programadas' || eNuevo === 'Rechazadas' || eNuevo === 'SinCambios'){
          realizadas[eNuevo] += 1
        }
      }else if(eAnterior != eNuevo){
        if(eAnterior === 'Agendadas' || eAnterior === 'Programadas' || eAnterior === 'Rechazadas' || eAnterior === 'SinCambios'){
          if(eNuevo === 'Agendadas' || eNuevo === 'Programadas' || eNuevo === 'Rechazadas' || eNuevo === 'SinCambios'){
            realizadas[eNuevo] += 1
            realizadas[eAnterior] -= 1
          }
        }
      }
      $cookies.putObject('misAsignadas',JSON.stringify(realizadas),{ 'expires':  new Date($scope.expireDate.setHours(0, 0, 0)) })
      $scope.modificaPorcentajes()
    }

    $scope.muestraAvance = function(){
      var day = new Date()
      var fecha =
          day.getDayFormatted() +
          '-' +
          day.getMonthFormatted() +
          '-' +
          day.getFullYear()
      dataService.getNumObligatorias(fecha, function(response) {
          if(response.data.message === 'still not asigned'){
            return
          }
          var a = response.data[0][0]
          $scope.indexes = [
            'Cita Telefonica',
            'Llamada programada del día',
            'Periodicidad vencida entre hoy y dos dias posteriores',
            'Periodicidad vencida entre ayer y siete dias atras',
            'Periodicidad vencida entre ocho dias atras y tres meses atras',
          ];
          var estatusLlamadas = {}
          estatusLlamadas[0] = {}
          estatusLlamadas[0].total = 0
          estatusLlamadas[0].agendadas = {}
          estatusLlamadas[0].programadas = {}
          estatusLlamadas[0].rechazadas = {}
          estatusLlamadas[0].asignadas = {}
          estatusLlamadas[0].completadas = {}
          estatusLlamadas[0].realizadas = a.Realizadas != undefined && a.Realizadas != null ? a.Realizadas : 0;
          estatusLlamadas[0].sinCambio = 0;
        _.forEach($scope.indexes,function(i){
            estatusLlamadas[0].agendadas[i] = 0;
            estatusLlamadas[0].programadas[i] = 0;
            estatusLlamadas[0].rechazadas[i] = 0;
            estatusLlamadas[0].asignadas[i] = a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
            estatusLlamadas[0].total += a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
            if(a.Completadas[i] != undefined && a.Completadas[i] != null){
              estatusLlamadas[0].sinCambio += a.Completadas[i].Sincambio
              estatusLlamadas[0].agendadas[i] += a.Completadas[i].Agendadas;
              estatusLlamadas[0].programadas[i] += a.Completadas[i].Programadas;
              estatusLlamadas[0].rechazadas[i] += parseInt(a.Completadas[i].Rechazadas);
              if(i==="Periodicidad vencida entre hoy y dos dias posteriores"){
                estatusLlamadas[0].completadas['Periodicidad vencida entre hoy y dos dias posteriores'] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas;
              }else if(i==="Periodicidad vencida entre ayer y siete dias atras"){
                estatusLlamadas[0].completadas['Periodicidad vencida entre ayer y siete dias atras'] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas;
              }else if(i==="Periodicidad vencida entre ocho dias atras y tres meses atras"){
                estatusLlamadas[0].completadas['Periodicidad vencida entre ocho dias atras y tres meses atras'] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas;
              }else if(i==='Cita Telefonica'){
                estatusLlamadas[0].completadas['Cita Telefonica'] =  a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas;
              }else if(i==='Llamada programada del día'){
                estatusLlamadas[0].completadas['Llamada programada del día'] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas;
              }
           }
        })
        if(response.data.message === 'Asignacion obligatoria y opcional'){
          estatusLlamadas = $scope.avanceOpcional(response.data[0][1],estatusLlamadas)
        }
        var modalInstance = $uibModal.open({
          templateUrl:'objetivoAgente.html',
          controller:'avanceAgenteModalInstance',
          size:'md',
          resolve:{
            estatusLlamadas:function(){
              return estatusLlamadas[0]
            }
          }
        })
        modalInstance.result.then(function(){
          $log.info('Modal dismissed at: ' + new Date())
        })
      })
    }

    $scope.avanceOpcional = function(asignacionOpcional, estatusLlamadas){
      var a = asignacionOpcional
      $scope.indexes = [
          'Periodicidad vencida entre tres meses atras y seis meses atras',
          'Periodicidad vencida entre seis meses atras al inicio',
          'Sin periodicidad'
      ];
      var estatusLlamadas = estatusLlamadas
      estatusLlamadas[0].realizadasOpc = a.Realizadas != undefined && a.Realizadas != null ? a.Realizadas : 0
      estatusLlamadas[0].totalOpcional = 0
      estatusLlamadas[0].asignadas['opcionales'] = 0
      estatusLlamadas[0].completadas['opcionales'] = 0
      estatusLlamadas[0].agendadas['opcionales'] = 0;
      estatusLlamadas[0].programadas['opcionales'] = 0;
      estatusLlamadas[0].rechazadas['opcionales'] = 0;
      _.forEach($scope.indexes,function(i){
        estatusLlamadas[0].asignadas['opcionales'] += a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0
        if(a.Completadas[i] != undefined && a.Completadas[i] != null){
          estatusLlamadas[0].sinCambio += a.Completadas[i].Sincambio
          estatusLlamadas[0].agendadas['opcionales'] += a.Completadas[i].Agendadas
          estatusLlamadas[0].programadas['opcionales'] += a.Completadas[i].Programadas
          estatusLlamadas[0].rechazadas['opcionales'] += a.Completadas[i].Rechazadas
        }
      })
      return estatusLlamadas
    }

    $scope.dropPeriodicidad = function(periodicidad, subperiodicidad){
      var modalInstance = $uibModal.open({
        templateUrl:'alertDropPeriodicidad.html',
        controller:'dropPeriodicidadModalInstance',
        size:'md',
        resolve: {
          periodicidad:function(){
            return subperiodicidad
          }
        }
      })
      modalInstance.result.then(
        function(p){
          clienteService.dropPeriodicidadProducto(p._id, function(response) {
            if(response.data.Estatus == 'True') {
              iziToast.success({
                title: 'Periodicidad borrada',
                message: 'Borrado realizado con exito',
                position: 'bottomRight',
                timeout: 2300
              })
              periodicidad.subPeriodicidad = periodicidad.subPeriodicidad.filter(function(per) {
                return per._id !== p._id
              })
              //console.log(periodicidad.subPeriodicidad.length);
              if(periodicidad.subPeriodicidad.length === 0 && periodicidad.Conjunto===true){
                periodicidad.Conjunto = false
                periodicidad._method = 'PUT'
                clienteService.updatePeriodicidad(periodicidad, function(response) {
                  console.log(response.data.Estatus)
                })
              }
            }
          })
        },function(){
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

  })
})

Ilduomo.controller('capturarAlimentoModalInstance', function(
  $scope,
  cliente,
  titulo,
  $uibModalInstance,
  dataService
) {
  $scope.cliente = cliente
  if (!$scope.cliente.AlimentoId) {
    $scope.cliente.AlimentoId = []
    $scope.cliente.Alimento = []
  }
  $scope.titulo = titulo
  dataService.getMarcasAlimento(function(response) {
    $scope.marcas = response.data
    _.forEach($scope.marcas, function(m) {
      _.find($scope.cliente.AlimentoId, function(ai) {
        if (ai == m._id) {
          m.checked = true
        }
      })
    })
  })

  $scope.setAlimento = function(marca) {
    var insertar = $scope.cliente.AlimentoId.indexOf(marca._id)
    if (insertar < 0) {
      console.log($scope.cliente.AlimentoId)
      $scope.cliente.AlimentoId.push(marca._id)
      $scope.cliente.Alimento.push(marca)
    } else {
      console.log($scope.cliente.AlimentoId)
      $scope.cliente.AlimentoId.splice(insertar, 1)
      $scope.cliente.Alimento.splice(insertar, 1)
    }
  }

  $scope.ok = function() {
    $scope.cliente.confirmar = true
    $uibModalInstance.close($scope.cliente)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 * Ilduomo Module
 *
 * modificaPeriodicidadClientModalInstance para editar la periodicidad de
 * los servicios para las mascotas
 */
Ilduomo.controller('modificaPeriodicidadClientModalInstance', function(
  $scope,
  titulo,
  periodicidad,
  subPeriodicidad,
  $uibModalInstance,
  $uibModal
) {
  $scope.titulo = titulo
  $scope.periodicidad = periodicidad || subPeriodicidad
  $scope.subPeriodicidad = subPeriodicidad ? true : false

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
  $scope.ok = function() {
    $uibModalInstance.close($scope.periodicidad)
  }
})

/**
 * Ilduomo Module
 *
 * Controller for alert drop client
 */
Ilduomo.controller('dropClientModalInstance', function(
  cliente,
  titulo,
  $scope,
  $uibModalInstance
) {
  $scope.titulo = titulo
  $scope.cliente = cliente

  $scope.ok = function() {
    $scope.cliente.confirmar = true
    $uibModalInstance.close($scope.cliente)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 * Agrega una alerta al cliente para poder
 * asignarse a algun operador de callcenter a cierta hora con cierta nota.
 * [mostrar notificación]
 */
Ilduomo.controller('setAlertClientModalInstance', function(
  cliente,
  titulo,
  dataService,
  $scope,
  $uibModalInstance
) {
  $scope.titulo = titulo
  $scope.cliente = cliente
  $scope.alerta = {}
  $scope.alerta.ClienteId = cliente._id
  dataService.getAgentesCallcenter(function(response) {
    $scope.users = response.data
  })
  $scope.dateOptions = {
    startingDay: 1
  }

  var date = new Date()
  date.setHours(9)
  date.setMinutes(0)

  $scope.minDate = new Date(new Date().setDate(new Date().getDate()))

  $scope.$watch('alerta.Fecha', function(fl) {
    date = new Date(fl)
    date.setHours(9)
    date.setMinutes(0)
    $scope.alerta.date = date
  })
  $scope.alerta.Fecha = date
  $scope.formatDate = 'dd-MM-yyyy'

  $scope.openAlarmaFecha = function($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.alerta.FechaisOpen = true
  }

  $scope.ok = function() {
    $uibModalInstance.close($scope.alerta)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismisds('cancel')
  }
})

/**
 * muestra una alerta al cliente para poder cancelarse
 * en caso de que sea necesario
 * [mostrar notificación]
 */
Ilduomo.controller('showAlertClientModalInstance', function(
  cliente,
  titulo,
  dataService,
  $scope,
  $uibModalInstance
) {
  $scope.titulo = titulo
  $scope.cliente = cliente
  dataService.getAlertaCliente(cliente, function(response) {
    $scope.alerta = response.data
    $scope.Usuario =
      response.data.user.Nombre + ' ' + response.data.user.Apellidos
  })
  $scope.dateOptions = {
    startingDay: 1
  }

  $scope.minDate = new Date(new Date().setDate(new Date().getDate()))

  $scope.formatDate = 'dd-MM-yyyy'

  $scope.ok = function() {
    $scope.alerta.confirmar = true
    $uibModalInstance.close($scope.alerta)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('muestraCancelacionModalInstance', function(
  $scope,
  servicio,
  titulo,
  $uibModalInstance
) {
  $scope.titulo = titulo
  $scope.servicio = servicio

  $scope.ok = () => {
    $uibModalInstance.close('ok')
  }
  $scope.cancel = () => {
    $uibModalInstance.close('cancel')
  }
})

Ilduomo.controller('capturarAlimentoModalInstance', function(
  $scope,
  cliente,
  titulo,
  $uibModalInstance,
  dataService
) {
  $scope.cliente = cliente
  if (!$scope.cliente.AlimentoId) {
    $scope.cliente.AlimentoId = []
    $scope.cliente.Alimento = []
  }
  $scope.titulo = titulo
  dataService.getMarcasAlimento(function(response) {
    $scope.marcas = response.data
    _.forEach($scope.marcas, function(m) {
      _.find($scope.cliente.AlimentoId, function(ai) {
        if (ai == m._id) {
          m.checked = true
        }
      })
    })
  })

  $scope.setAlimento = function(marca) {
    var insertar = $scope.cliente.AlimentoId.indexOf(marca._id)
    if (insertar < 0) {
      console.log($scope.cliente.AlimentoId)
      $scope.cliente.AlimentoId.push(marca._id)
      $scope.cliente.Alimento.push(marca)
    } else {
      console.log($scope.cliente.AlimentoId)
      $scope.cliente.AlimentoId.splice(insertar, 1)
      $scope.cliente.Alimento.splice(insertar, 1)
    }
  }

  $scope.ok = function() {
    $scope.cliente.confirmar = true
    $uibModalInstance.close($scope.cliente)
  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

/**
 *Muestra un modal con el avance de agente de callcenter
 */
Ilduomo.controller('avanceAgenteModalInstance',function(
  estatusLlamadas,
  $scope,
  $uibModalInstance
  ){
    $scope.estatusLlamadas = estatusLlamadas
    $scope.colores = {
        Agendadas: '#09961b',
        Programadas: '#07486d',
        Rechazadas: '#bb0303'
    }
    $scope.ok = function(){
      $uibModalInstance.close()
    }
})
Ilduomo.filter('abs', function () {
  return function(val) {
    return val ? Math.abs(val) : ''
  }
})
