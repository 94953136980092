'use strict';

Ilduomo.controller('RegisterController', function ($state, $rootScope, $auth, $http) {
    var Roles=['Administrador', 'Gerente Callcenter', 'Operador Responsable', 'Gerente Administrativo', 'Gerente de Operaciones', 'Agente de C.C.', 'Monitor'];
    var EstadosCiviles=['Soltero', 'Casado', 'Viudo', 'Divorciado'];
    var user = JSON.parse(localStorage.getItem('user')); 
    if (!user) {
        $state.go("auth");
    }
    this.Roles = Roles;
    this.newuser={};
    this.setRol = function (rol) {
        this.newuser.Rol= rol;
    }
    this.EstadosCiviles = EstadosCiviles;
    this.newuser={};
    this.setEstadoCivil = function (EstadoCivil) {
        this.newuser.EstadoCivil= CstadoCivil;
    }
});
