'use strict'
/**
 * loginController
 * para hacer el login de la app
 */
Ilduomo.controller('loginController', function(
  $state,
  $scope,
  $location,
  $http,
  $auth,
  $rootScope,
  $timeout,
  $cookies,
  dataService,
  md5,
  clienteService
) {
  $scope.loginError = false
  $scope.loginErrorText
  $cookies.remove('inObject') || null
  $cookies.remove('pag') || null
  $cookies.remove('urlAsig') || null
  $cookies.remove('inOption') || null
  $cookies.remove('pagOpt') || null
  $cookies.remove('urlAsigOpt') || null
  if ($rootScope.currentUser && $rootScope.GerentedeOperaciones) {
    $state.go('operadorInfo')
  } else if ($rootScope.currentUser) {
    $state.go('clienteClientes')
  }

  $scope.submit = function() {
    var credentials = {
      username: $scope.username,
      password: md5.createHash($scope.password)
    }

    $auth
      .login(credentials)
      .then(
        function() {
          return $http.get('api/authenticate/user')
        },
        function(error) {
          $scope.loginError = true
          $scope.loginErrorText = error.data.error
          iziToast.error({
            position: 'topRight',
            title: 'Error',
            message: 'Verifica tu usuario y password'
          })
        }
      )
      .then(function(response) {
        var user = JSON.stringify(response.data.user)
        if (response.data.Alertas) {
          var e = new Date()
          e.setHours(e.getHours() + 8)
          $cookies.putObject('Alertas', response.data.Alertas, { expires: e })
          var Alertas = $cookies.getObject('Alertas')
          _.forEach(Alertas, function(a) {
            var date = new Date()
            var f = new Date(a.Fecha)
            var diff = f - date
            $timeout(function() {
              console.log(a)
              // $mdToast.show({
              //     hideDelay   : 0,
              //     position    : 'bottom right',
              //     controller  : 'alertaController',
              //     resolve: {
              //       alerta: function () {
              //           return a;
              //       },
              //       fecha: function () {
              //           return f;
              //       }
              //     },
              //     templateUrl : 'toast-template.html'
              //   });
              // $.gritter.add({
              //     title: `Alerta de Cliente`,
              //     text: a.cliente.Nombre+
              //             " "+
              //             f+" "+
              //             a.Nota,
              //     sticky: true,
              //     time: '',
              //     function: 'onclick="insertIntoClientsTableAlert('+$rootScope.currentUser._id+')"',
              //     class_name: 'my-sticky-class'
              // });
              var index = Alertas.indexOf(a)
              Alertas.splice(index, 1)
              $cookies.putObject('Alertas', Alertas)
              a._method = 'DELETE'
              dataService.removeAlert(a, function(response) {
                console.log(response.data)
              })
            }, diff - 120000)
          })
        }
        // localStorage.setItem('user',user);/
        if (!response.data.user.isActive) {
          iziToast.error({
            position: 'topRight',
            title: 'Error',
            message: 'No tienes autorización de acceder al sistema'
          })
          $cookies.remove('user')
          $rootScope.authenticated = false
          $rootScope.currentUser = null
          return false
        }
        var ue = new Date()
        ue.setHours(ue.getHours() + 5)
        $cookies.putObject('user', response.data.user, { expires: ue })

        $rootScope.authenticated = true
        $rootScope.currentUser = response.data.user

        _.forEach($rootScope.currentUser.roles, function(r) {
          switch (r.Nombre) {
            case 'Gerente Callcenter':
              $rootScope.GerenteCallcenter = true
              break
            case 'Operador Responsable':
              $rootScope.OperadorResponsable = true
              break
            case 'Gerente Administrativo':
              $rootScope.GerenteAdministrativo = true
              break
            case 'Gerente de Operaciones':
              $rootScope.GerentedeOperaciones = true
              break
            case 'Agente de C.C.':
              $rootScope.AgentedeCC = true
              break
            case 'Monitor':
              $rootScope.Monitor = true
              break
            case 'Franquiciante':
              $rootScope.Franquiciante = true
              break
            case 'Administrador':
              $rootScope.Administrador = true
              break
            default:
              $rootScope.errorRol = true
          }
        })
        if ($rootScope.GerentedeOperaciones) {
          $state.go('operadorInfo')
        } else if (
          $rootScope.GerenteCallcenter ||
          $rootScope.GerenteAdministrativo ||
          $rootScope.AgentedeCC ||
          $rootScope.Monitor ||
          $rootScope.Administrador ||
          $rootScope.Franquiciante
        ) {
          $state.go('clienteClientes')
        } else {
          iziToast.error({
            position: 'topRight',
            title: 'Error',
            message: 'No tienes autorización de acceder al sistema'
          })
          $cookies.remove('user')
          $rootScope.authenticated = false
          $rootScope.currentUser = null
        }
      })

    return false
  }
})
