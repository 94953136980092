'use strict'
Ilduomo.controller('estrategiaGerenteCallCenterCtrl', function(
  $scope,
  $rootScope,
  $stateParams,
  $state,
  dataService,
  $interval
) {
  $rootScope.setting.layout.pageSidebarMinified = true
  /**
   * obtiene el mes formateado a dos digitos
   * @return {Date} mes con formato 01,10, etc..
   */
  Date.prototype.getMonthFormatted = function() {
    var month = this.getMonth() + 1
    return month < 10 ? '0' + month : '' + month // ('' + month) for string result
  }
  /**
   * Obtiene el dia formateado a dos digitos siempre
   * @return {Date} dia con formato 01,02,10,23,etc..
   */
  Date.prototype.getDayFormatted = function() {
    var day = this.getDate()
    return day < 10 ? '0' + day : '' + day // ('' + month) for string result
  }

  var date = new Date()
  $scope.minDate = date
  var max = new Date(date.getDate() + 1)
  $scope.max = max
  $scope.fecha = date
  $scope.dateOptions = {
    startingDay: 1
  }
  $scope.loading = false

  $scope.obligatorias = [
    'Cita Telefonica',
    'Llamada programada del día',
    'Periodicidad vencida entre hoy y dos dias posteriores',
    'Periodicidad vencida entre ayer y siete dias atras',
    'Periodicidad vencida entre ocho dias atras y tres meses atras'
  ]
  $scope.indexes = [
    'Cita Telefonica',
    'Llamada programada del día',
    'Periodicidad vencida entre hoy y dos dias posteriores',
    'Periodicidad vencida entre ayer y siete dias atras',
    // 'Periodicidad vencida entre tres meses atras y ocho meses atras',
    'Periodicidad vencida entre ocho dias atras y tres meses atras',
    'Periodicidad vencida entre tres meses atras y seis meses atras', //aqui empiezan las opcionales
    'Periodicidad vencida entre seis meses atras al inicio',
    'Sin periodicidad'
  ]

  function sortPrioridad(a, b) {
    return $scope.indexes.indexOf(a) - $scope.indexes.indexOf(b)
  }

  $scope.openCalendar = function($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.isOpen = !$scope.isOpen
  }

  $scope.$watch('fecha', () => {
    var btn = document.getElementById('btn-asignar')
    if(btn != null)
      btn.style.pointerEvents = 'none'
    $scope.loading = true
    $scope.verificaAsignaciones()
    $scope.reparte()
  })

  $scope.reparte = function() {
    dataService.getGerenteCCView(
      $scope.fecha.getDayFormatted() +
        '-' +
        $scope.fecha.getMonthFormatted() +
        '-' +
        $scope.fecha.getFullYear(),
      function(response) {
        var info = response.data
        $scope.total = {
          total: 0,
          obl: 0, // totales obligatorias
          opc: 0 // totales 'opcionales'
        }
        if(!$scope.asignacionObligatoria && !$scope.asignacionOpcional){
          $scope.agentes = info.agentes
          $scope.agentes = _.sortBy($scope.agentes, ['_id'])
        }
        $scope.negocios = info.negocios
        $scope.llamadasRepartir = []
        _.forEach($scope.negocios, function(n) {
          _.forEach(info[n.Nombre].citasTelefonicas, function(ct) {
            var inter = _.find($scope.llamadasRepartir, [
              'ClienteId',
              ct.ClienteId
            ])
            if (!inter) {
              $scope.total.total += 1
              ct.motivos = ['Cita Telefonica']
              ct.Estatus = 'Pendiente'
              ct.negocios = [n.Nombre]
              $scope.llamadasRepartir.push(ct)
            } else {
              // if (!._includes(inter.motivos, ''))
              inter.motivos.push('Cita Telefonica')
              inter.motivos.sort(sortPrioridad)
              inter.Estatus = 'Pendiente'
              if (!_.includes(inter.negocios, n.Nombre)) {
                inter.negocios.push(n.Nombre)
              }
            }
          })
          _.forEach(info[n.Nombre].llamadasProgramadas, function(lp) {
            var inter = _.find($scope.llamadasRepartir, [
              'ClienteId',
              lp.ClienteId
            ])
            if (!inter) {
              $scope.total.total += 1
              lp.motivos = ['Llamada programada del día']
              lp.Estatus = 'Pendiente'
              lp.negocios = [n.Nombre]
              $scope.llamadasRepartir.push(lp)
            } else {
              inter.motivos.push('Llamada programada del día')
              inter.motivos.sort(sortPrioridad)
              if (!_.includes(inter.negocios, n.Nombre)) {
                inter.negocios.push(n.Nombre)
              }
            }
          })
          _.forEach(info[n.Nombre].periodicidadTodayToTwoDays, function(lp) {
            var inter = _.find($scope.llamadasRepartir, [
              'ClienteId',
              lp.ClienteId
            ])
            if (!inter) {
              $scope.total.total += 1
              lp.motivos = [
                'Periodicidad vencida entre hoy y dos dias posteriores'
              ]
              lp.Estatus = 'Pendiente'
              lp.negocios = [n.Nombre]
              $scope.llamadasRepartir.push(lp)
            } else {
              inter.motivos.push('Periodicidad vencida entre hoy y dos dias posteriores')
              inter.motivos.sort(sortPrioridad)
              if (!_.includes(inter.negocios, n.Nombre)) {
                inter.negocios.push(n.Nombre)
              }
            }
          })
          _.forEach(info[n.Nombre].periodicidadYesterdayToSevenDays, function(
            lp
          ) {
            var inter = _.find($scope.llamadasRepartir, [
              'ClienteId',
              lp.ClienteId
            ])
            if (!inter) {
              $scope.total.total += 1
              lp.motivos = [
                'Periodicidad vencida entre ayer y siete dias atras'
              ]
              lp.Estatus = 'Pendiente'
              lp.negocios = [n.Nombre]
              $scope.llamadasRepartir.push(lp)
            } else {
              inter.motivos.push('Periodicidad vencida entre ayer y siete dias atras')
              inter.motivos.sort(sortPrioridad)
              if (!_.includes(inter.negocios, n.Nombre)) {
                inter.negocios.push(n.Nombre)
              }
            }
          })
          _.forEach(
            info[n.Nombre].periodicidadminuseigthtominusthreemonths,
            function(lp) {
              var inter = _.find($scope.llamadasRepartir, [
                'ClienteId',
                lp.ClienteId
              ])
              if (!inter) {
                $scope.total.total += 1
                lp.motivos = [
                  'Periodicidad vencida entre ocho dias atras y tres meses atras'
                ]
                lp.Estatus = 'Pendiente'
                lp.negocios = [n.Nombre]
                $scope.llamadasRepartir.push(lp)
              } else {
                inter.motivos.push(
                  'Periodicidad vencida entre ocho dias atras y tres meses atras'
                )
                inter.motivos.sort(sortPrioridad)
                if (!_.includes(inter.negocios, n.Nombre)) {
                  inter.negocios.push(n.Nombre)
                }
              }
            }
          )
        })
        /** asignacion opcional **/
        _.forEach($scope.negocios, function(n) {
          _.forEach(info[n.Nombre].periodicidadminusthreemonthstominussixmonths,
            function(lp) {
              var inter = _.find($scope.llamadasRepartir, [
                'ClienteId',
                lp.ClienteId
              ])
              if (!inter) {
                $scope.total.total += 1
                lp.motivos = [
                  'Periodicidad vencida entre tres meses atras y seis meses atras'
                ]
                lp.Estatus = 'Pendiente'
                lp.negocios = [n.Nombre]
                $scope.llamadasRepartir.push(lp)
              } else {
                if (!$scope.tieneObligatoria(inter)) {
                  inter.motivos.push(
                    'Periodicidad vencida entre tres meses atras y seis meses atras'
                  )
                  inter.motivos.sort(sortPrioridad)
                  if (!_.includes(inter.negocios, n.Nombre)) {
                    inter.negocios.push(n.Nombre)
                  }
                }
              }
            }
          )
          _.forEach(
            info[n.Nombre].periodicidadminussixmonthstoinfinity,
            function(lp) {
              var inter = _.find($scope.llamadasRepartir, [
                'ClienteId',
                lp.ClienteId
              ])
              if (!inter) {
                $scope.total.total += 1
                lp.motivos = [
                  'Periodicidad vencida entre seis meses atras al inicio'
                ]
                lp.Estatus = 'Pendiente'
                lp.negocios = [n.Nombre]
                $scope.llamadasRepartir.push(lp)
              } else {
                if (!$scope.tieneObligatoria(inter)) {
                  inter.motivos.push(
                    'Periodicidad vencida entre seis meses atras al inicio'
                  )
                  inter.motivos.sort(sortPrioridad)
                  if (!_.includes(inter.negocios, n.Nombre)) {
                    inter.negocios.push(n.Nombre)
                  }
                }
              }
            }
          )
          _.forEach(info[n.Nombre].withoutPeriodicity, function(lp) {
            var inter = _.find($scope.llamadasRepartir, [
              'ClienteId',
              lp.ClienteId
            ])
            if (!inter) {
              $scope.total.total += 1
              lp.motivos = ['Sin periodicidad']
              lp.Estatus = 'Pendiente'
              lp.negocios = [n.Nombre]
              $scope.llamadasRepartir.push(lp)
            } else {
              if (!$scope.tieneObligatoria(inter)) {
                inter.motivos.push(
                  'Sin periodicidad'
                )
                inter.motivos.sort(sortPrioridad)
                if (!_.includes(inter.negocios, n.Nombre)) {
                  inter.negocios.push(n.Nombre)
                }
              }
            }
          })
        }) //llenado de llamadas a repartir
        console.log(_.filter($scope.llamadasRepartir, (r) => r.motivos[2]))
        _.forEach($scope.llamadasRepartir, (lr) => {
          $scope.total[lr.motivos[0]] = $scope.total[lr.motivos[0]] 
            ? $scope.total[lr.motivos[0]] += 1
            : 1
        })
        $scope.llamadasRepartir = _.groupBy($scope.llamadasRepartir, function(
          lr
        ) {
          return lr.motivos[0]
        })
        _.forEach($scope.indexes, function(i) {
          var interTotal = $scope.total[i] || 0,
            agenteIndex = 0
          while (interTotal != 0) {
            if (!$scope.agentes[agenteIndex].numeros) {
              $scope.agentes[agenteIndex].numeros = {}
            }
            if (!$scope.agentes[agenteIndex].numeros[i]) {
              $scope.agentes[agenteIndex].numeros[i] = 0
            }
            if((!$scope.asignacionObligatoria && 
                (i === 'Cita Telefonica' ||
                i === 'Llamada programada del día' ||
                i === 'Periodicidad vencida entre hoy y dos dias posteriores' ||
                i === 'Periodicidad vencida entre ayer y siete dias atras'|| 
                i === 'Periodicidad vencida entre ocho dias atras y tres meses atras')
              ) || 
              (!$scope.asignacionOpcional &&
                (i=== 'Periodicidad vencida entre tres meses atras y seis meses atras'||
                i=== 'Periodicidad vencida entre seis meses atras al inicio'||
                i === 'Sin periodicidad')
              )  
            ){
              $scope.agentes[agenteIndex].numeros[i]++   
            }
            if($scope.asignacionObligatoria || $scope.asignacionOpcional){
              $scope.total[i] = $scope.sumaLlamadas(i)
            }

            $scope.agentes[agenteIndex].total = $scope.agentes[agenteIndex]
              .total
              ? $scope.agentes[agenteIndex].total + 1
              : 1
            if (++agenteIndex == $scope.agentes.length) agenteIndex = 0
            interTotal--
          }
        })

        _.forEach($scope.llamadasRepartir, function(lr, index) {
          $scope.llamadasRepartir[index] = _.orderBy(
            $scope.llamadasRepartir[index],
            l => {
              return l.zonaNombre
            },
            'desc'
          )
        })
        $scope.total.obl = $scope.totalAsignar('obl')
        $scope.total.opc = $scope.totalAsignar('opc')
        $scope.loading = false
        var btn = document.getElementById('btn-asignar')
          if(btn != null)
            btn.style.pointerEvents = 'auto'
        //document.getElementById('btn-asignar').style.pointerEvents = 'auto'
      }
    )
  }

  $scope.totalAsignar = function(tipoSuma) {
    var agenteIndex = 0, 
    wantToAsign = 0
    if ($scope.agentes) {
      for (
        agenteIndex = 0;
        agenteIndex < $scope.agentes.length;
        agenteIndex++
      ) {
        wantToAsign += $scope.sumaAgente(agenteIndex, tipoSuma)
      }
    }
    return wantToAsign
  }

  /**
   * Funcion que verifica si una llamada ya tiene un motivo obligatorio
   * @param llamada
  */
  $scope.tieneObligatoria = function (llamada) {
    for(let m of llamada.motivos){
      if (_.includes($scope.obligatorias, m)) return true
    }
    return false
  }


  $scope.sumaLlamadas = function(propiedad) {
    return _.sumBy($scope.agentes, function(a) {
      return a.numeros[propiedad]
    })
  }

  $scope.sumaAgente = function(indexAgente, tipoSuma) {
    var suma = 0
    _.forEach($scope.indexes, function(i) {
      if ($scope.agentes[indexAgente]) {
        if ($scope.agentes[indexAgente].numeros[i] && tipoSuma == 'obl') {
          if (
            i !=
              'Periodicidad vencida entre tres meses atras y seis meses atras' &&
            i != 'Periodicidad vencida entre seis meses atras al inicio' &&
            i != 'Sin periodicidad'
          ) {
            suma += $scope.agentes[indexAgente].numeros[i]
          }
        } else if (
          $scope.agentes[indexAgente].numeros[i] &&
          tipoSuma == 'opc'
        ) {
          if (
            i ==
              'Periodicidad vencida entre tres meses atras y seis meses atras' ||
            i == 'Periodicidad vencida entre seis meses atras al inicio' ||
            i == 'Sin periodicidad'
          ) {
            suma += $scope.agentes[indexAgente].numeros[i]
          }
        }
      }
    })
    return suma
  }

  $scope.validarLlamadas = function(tipoValidacion) {
    $scope.valida = true
    if (tipoValidacion == 'obl') {
      if ($scope.total.obl !== $scope.totalAsignar('obl')) {
        $scope.valida = false
      }
    } else if (tipoValidacion == 'opc') {
      if ($scope.total.opc < $scope.totalAsignar('opc')) {
        $scope.valida = false
      }
    }
  }

  $scope.changePrioridad = function() {
    $scope.saveButton = true
    _.forEach($scope.negocios, function(n, index) {
      n.Prioridad = index + 1
    })
  }

  $scope.asignarEstrategia = function(tipoAsig) {
    var a = $scope.llamadasRepartir
    var ingresa
    if ($scope.diaAsignado) {
      if (tipoAsig == 'obl') {
        if (!$scope.diaAsignado.obl) {
          ingresa = true
        }
      } else {
        if (!$scope.diaAsignado.opc) {
          ingresa = true
        }
      }
    } else {
      ingresa = true
    }
    // $scope.wantToAsign = 0
    // _.forEach($scope.indexes, function(i) {
    //   for(agenteIndex = 0; agenteIndex < $scope.agentes.length; agenteIndex++)
    //     $scope.wantToAsign += $scope.agentes[agenteIndex].numeros[i] || 0
    // })
    if (ingresa) {
      if (vacio($scope.llamadasRepartir)) {
        if ($scope.llamadasRepartirNuevo) {
          $scope.llamadasRepartir = $scope.llamadasRepartirNuevo
        }
      }
      tipoAsig == 'obl'
        ? $scope.validarLlamadas('obl')
        : $scope.validarLlamadas('opc')
      if (!$scope.valida) {
        iziToast.warning({
          title: 'Error',
          timeout: 3000,
          position: 'topCenter',
          message:
            tipoAsig == 'obl'
              ? 'El total de llamadas a asignar debe ser igual a ' +
                $scope.total.obl
              : 'El total de llamadas a asignar debe ser menor o igual que ' +
                $scope.total.opc
        })
      } else {
        $scope.llamadasRepartirNuevo = {} // Obj contendra las llamadas sobrantes sean obligatorias u opcionales
        var servicio
        _.forEach($scope.agentes, function(a) {
          tipoAsig == 'obl'
            ? (a.tipoAsignacion = 'obligatoria')
            : (a.tipoAsignacion = 'opcional')
          a.total = 0
          a.asignacion = []
          _.forEach($scope.indexes, function(i) {
            if (!a.interAsig) {
              a.interAsig = {}
            }
            if (!a.interAsig[i]) {
              a.interAsig[i] = []
            }
          })
        })
        _.forEach($scope.indexes, function(i) {
          if ($scope.llamadasRepartir[i]) {
            $scope.llamadasRepartir[i] = _.shuffle($scope.llamadasRepartir[i]) // mezclo el arreglo
            _.forEach($scope.agentes, function(a) {
              if (
                (tipoAsig == 'obl' &&
                  (
                    i !='Periodicidad vencida entre tres meses atras y seis meses atras' &&
                    i !='Periodicidad vencida entre seis meses atras al inicio' &&
                    i != 'Sin periodicidad')
                  ) ||
                (tipoAsig == 'opc' &&
                  (
                    i =='Periodicidad vencida entre tres meses atras y seis meses atras' ||
                    i =='Periodicidad vencida entre seis meses atras al inicio' ||
                    i == 'Sin periodicidad'
                  )
                )
              ) {
                a.asignacion = _.concat(
                  a.asignacion,
                  $scope.llamadasRepartir[i].splice(0, a.numeros[i])
                )
                a.total = a.asignacion.length
              } 
              else {
                $scope.llamadasRepartirNuevo[i] = $scope.llamadasRepartirNuevo[i] || []
                $scope.llamadasRepartirNuevo[i] = _.concat(
                  $scope.llamadasRepartirNuevo[i],
                  $scope.llamadasRepartir[i].splice(0, a.numeros[i])
                )
              }
            })
          }
        })

        var save = {
          agentes: $scope.agentes,
          fecha:
            $scope.fecha.getDayFormatted() +
            '-' +
            $scope.fecha.getMonthFormatted() +
            '-' +
            $scope.fecha.getFullYear()
        }

        dataService.agregaAsignacionLlamadas(save, function(response) {
          if (response.data.Estatus == 'True') {
            tipoAsig === 'obl' ? $scope.asignacionObligatoria = true : $scope.asignacionOpcional = true
            iziToast.success({
              title: 'Ok',
              timeout: 2000,
              position: 'topCenter',
              message: 'Asignacion realizada'
            })
            $scope.llamadasRepartir = {} // vacío para meterle las llamadas que quedaron en llamadasRepartirNuevo
            _.forEach($scope.agentes, function(a) {
              if (a.asignacion) {
                delete a.asignacion
              } // borrando pila de asignacion que se utilizó para
            })
          } else if (response.data.Estatus == 'False') {
            iziToast.error({
              title: 'Asignacion ya existente',
              timeout: 2000,
              position: 'topCenter',
              message: 'Desasigne e intente de nuevo'
            })
            _.forEach($scope.agentes, function(a) {
              if (a.asignacion) {
                delete a.asignacion
              } // borrando pila de asignacion que se utilizó para
            })
          } else {
            iziToast.warning({
              title: 'Error',
              timeout: 4000,
              position: 'topCenter',
              message: 'al tratar de Asignar, intente nuevamente'
            })
            _.forEach($scope.agentes, function(a) {
              if (a.asignacion) {
                delete a.asignacion
              } // borrando pila de asignacion que se utilizó para
            })
          }
        })
        $scope.diaAsignado = $scope.diaAsignado || {}
        tipoAsig == 'obl'
          ? ($scope.diaAsignado.obl = true)
          : ($scope.diaAsignado.opc = true)
      }
    } else {
      iziToast.warning({
        title: 'Asignacion ya realizada',
        timeout: 2000,
        position: 'topCenter',
        message: 'Desasigne e intente de nuevo'
      })
    }
  }
  $scope.desasignarEstrategia = function(tipoAsig) {
    if (tipoAsig == 'obl') {
      if ($scope.diaAsignado && $scope.diaAsignado.obl) {
        delete $scope.diaAsignado.obl
      } // se borran banderas de asignación
    } else if ($scope.diaAsignado && $scope.diaAsignado.opc) {
      delete $scope.diaAsignado.opc
    }
    var fecha = new Date()
    tipoAsig == 'obl' ? (tipoAsig = 'obligatoria') : (tipoAsig = 'opcional')
    var save = {
      fecha:
        fecha.getDayFormatted() +
        '-' +
        fecha.getMonthFormatted() +
        '-' +
        fecha.getFullYear(),
      tipoAsignacion: tipoAsig
    }
    dataService.deleteAsignacionLlamadas(save, function(response) {
      if (response.data.Estatus == 'True') {
        var btn = document.getElementById('btn-asignar')
          if(btn != null)
            btn.style.pointerEvents = 'none'
        //document.getElementById('btn-asignar').style.pointerEvents = 'none'
        tipoAsig == 'obligatoria' ? $scope.asignacionObligatoria = false : $scope.asignacionOpcional = false;
        if(!$scope.asignacionObligatoria){
          !$scope.editar ? $scope.cleanNumbers('obligatoria') : $scope.asignacionObligatoria = true;
        }
        if(!$scope.asignacionOpcional){
          !$scope.editar2 ? $scope.cleanNumbers('opcional') : $scope.asignacionOpcional = true;
        }
        $scope.reparte()
        iziToast.success({
          title: 'Borrado',
          message: 'La asignación del día ha sido borrada',
          timeout: 2000,
          position: 'topCenter',
          color: 'white' // no existe el color
        })
      } else if (response.data.Estatus == 'False') {
        iziToast.error({
          title: 'Error',
          timeout: 2000,
          position: 'topCenter',
          message: 'no hay asignacion por borrar'
        })
      } else {
        iziToast.warning({
          title: 'Error',
          timeout: 2000,
          position: 'topCenter',
          message: 'al tratar de borrar la asignacion, verifique'
        })
      }
    })
  }
  var date = new Date()
  $scope.minDate = date
  var max = new Date(date.getDate() + 1)
  $scope.max = max
  $scope.fecha = date
  $scope.dateOptions = {
    startingDay: 1
  }
  if ($stateParams.fecha) {
    $scope.fecha = (function(fecha) {
      var d = fecha.split('-')
      return new Date(d[2], d[1] - 1, d[0])
    })($stateParams.fecha)
  } else {
    $scope.fecha = date
  }
  $scope.$watch('fecha', function(newValue) {
    $scope.fechaReporte =
      newValue.getDayFormatted() +
      '-' +
      newValue.getMonthFormatted() +
      '-' +
      newValue.getFullYear()
    $state.go('.', { fecha: $scope.fechaReporte }, { notify: false })
    var btn = document.getElementById('btn-asignar')
    $scope.estatusLlamadasObligatorias()
    if(btn == null){
      var promise = $interval(function() 
        { 
          $scope.estatusLlamadasObligatorias()
        }, 60000);
      $scope.$on('$destroy', function (){ 
      $interval.cancel(promise); 
        });
      }
  })

  $scope.openCalendario = function($event) {
    $event.preventDefault()
    $event.stopPropagation()
    $scope.isOpen = !$scope.isOpen
  }
  $scope.returnSumaTiposLlamada = function(tipoDeLlamada) {
    if ($scope.todas) {
      if ($scope.todas[tipoDeLlamada]) {
        return $scope.todas[tipoDeLlamada]
      } else {
        return 0
      }
    }
    // return _.sumBy($scope.estatusLlamadas, function(a){
    //   return a[tipoDeLlamada]
    // })
  }

  $scope.sumaTotal = function() {
    return _.sumBy($scope.estatusLlamadas, function(a) {
      return a.total
    })
  }

  function vacio (arregloLlamadas) {
    var x = 1
    _.forEach(arregloLlamadas, l => { 
      if(l.length !== 0) {
        x = 0 
      }
    })
    return x
  }

  $scope.verificaAsignaciones = function(){
    var date = new Date()
    var fecha=date.getDayFormatted() +
      '-' +
      date.getMonthFormatted() +
      '-' +
      date.getFullYear()
    $scope.asignacionObligatoria = false
    $scope.asignacionOpcional = false
    dataService.getAsignadas(fecha, function(response) {
      if(response.data.message === 'still not asigned' ){
        return
      }
      $scope.agentes = response.data.agentes
      $scope.agentes = _.sortBy($scope.agentes, ['_id'])
      _.forEach($scope.agentes,function(a){
        a.numeros = {}
      })
      var indices
      //si hay asignacion obligatoria
      if(response.data.asignaciones[0].length > 0)
      {
      indices = [
          'Cita Telefonica',
          'Llamada programada del día',
          'Periodicidad vencida entre hoy y dos dias posteriores',
          'Periodicidad vencida entre ayer y siete dias atras',
          'Periodicidad vencida entre ocho dias atras y tres meses atras'
        ];
        var aOblig = response.data.asignaciones[0]
        aOblig = _.sortBy(aOblig, ['_id'])
          _.forEach(aOblig, function(a,idAgente){
            _.forEach(indices,function(i){
              $scope.agentes[idAgente].numeros[i] = a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
            }) 
          })
      $scope.asignacionObligatoria = true
      }
      //Si hay asignacion opcional
      if(response.data.asignaciones[1].length > 0)
      {
      indices = [
          'Periodicidad vencida entre tres meses atras y seis meses atras',
          'Periodicidad vencida entre seis meses atras al inicio',
          'Sin periodicidad'
          ];
      var aOpc = response.data.asignaciones[1]
        aOpc = _.sortBy(aOpc, ['_id'])
          _.forEach(aOpc, function(a,idAgente){
            _.forEach(indices,function(i){
              $scope.agentes[idAgente].numeros[i] = a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
            }) 
          })
      $scope.asignacionOpcional = true
      }
    })
  }

  $scope.cleanNumbers = function(asig){
    var indices
    if(asig === 'obligatoria'){
      indices = [
          'Cita Telefonica',
          'Llamada programada del día',
          'Periodicidad vencida entre hoy y dos dias posteriores',
          'Periodicidad vencida entre ayer y siete dias atras',
          'Periodicidad vencida entre ocho dias atras y tres meses atras'
        ];
      _.forEach($scope.agentes, function(a){
        _.forEach(indices,function(i){
          a.numeros[i] = 0;
        }) 
      })
    }else{
       indices = [
          'Periodicidad vencida entre tres meses atras y seis meses atras',
          'Periodicidad vencida entre seis meses atras al inicio',
          'Sin periodicidad'
          ];
      _.forEach($scope.agentes, function(a){
        _.forEach(indices,function(i){
          a.numeros[i] = 0;
        }) 
      })
    }
  }

  $scope.estatusLlamadasObligatorias = function (){
    dataService.getEstatusMisLlamadas($scope.fechaReporte, function(response) {

      //checa si se repitio un cliente en las asignaciones diarias
      // let clientes = []

      // response.data[0].forEach(r => {
      //   r.Asignacion.forEach(a => {
      //     if (clientes.find(c => c === a.ClienteId))
      //       debugger //CLIENTE REPETIDO 
      //     clientes.push(a.ClienteId)
      //   })
      // })
      // debugger

        if(response.data.message === 'objetivo does not exist'){
          return
        }
        var info = response.data[0][0]
        info = _.sortBy(info, ['_id'])
        $scope.estatus = info.estatus
        $scope.estatusLlamadas = {}
        $scope.respuestaCita = []
        $scope.todas = {}
        $scope.sinCambioT = 0
        $scope.Sincambio = {}
        $scope.asignadasT = {}
        $scope.formatDate = 'dd-MMMM-yyyy'
        $scope.colores = {
          Agendadas: '#09961b',
          Programadas: '#07486d',
          Rechazadas: '#bb0303'
        }
        $scope.indexes = [
              'Cita Telefonica',
              'Llamada programada del día',
              'Periodicidad vencida entre hoy y dos dias posteriores',
              'Periodicidad vencida entre ayer y siete dias atras',
              'Periodicidad vencida entre ocho dias atras y tres meses atras'
          ];
        _.forEach($scope.indexes,function(i){
            $scope.todas[i] = 0
            $scope.asignadasT[i] = 0
          })
        _.forEach(info,function(a,id){
          $scope.Sincambio[id] = 0
        })
        _.forEach(info, function(a, idAgente) {
          $scope.datos = true
          $scope.estatusLlamadas[idAgente] = {}
          $scope.estatusLlamadas[idAgente].total = 0
          $scope.estatusLlamadas[idAgente].agendadas = {}
          $scope.estatusLlamadas[idAgente].programadas = {}
          $scope.estatusLlamadas[idAgente].rechazadas = {}
          $scope.estatusLlamadas[idAgente].realizadas = a.Realizadas != undefined && a.Realizadas != null ? a.Realizadas : 0;
          $scope.estatusLlamadas[idAgente].asignadas = {}
          $scope.estatusLlamadas[idAgente].completadas = {}
            _.forEach($scope.indexes,function(i){
              $scope.estatusLlamadas[idAgente].agendadas[i] = 0
              $scope.estatusLlamadas[idAgente].programadas[i] = 0
              $scope.estatusLlamadas[idAgente].rechazadas[i] = 0
              $scope.estatusLlamadas[idAgente].asignadas[i] = a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
              $scope.estatusLlamadas[idAgente].total += a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
              $scope.asignadasT[i]+= a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
              if(a.Completadas[i] != undefined && a.Completadas[i] != null){
                $scope.estatusLlamadas[idAgente].agendadas[i] += a.Completadas[i].Agendadas
                $scope.todas[i] += a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                $scope.estatusLlamadas[idAgente].programadas[i] += a.Completadas[i].Programadas
                $scope.estatusLlamadas[idAgente].rechazadas[i] += a.Completadas[i].Rechazadas
                $scope.Sincambio[idAgente] += a.Completadas[i].Sincambio
                $scope.sinCambioT += a.Completadas[i].Sincambio
                if(i==="Periodicidad vencida entre hoy y dos dias posteriores"){
                  $scope.estatusLlamadas[idAgente].completadas[i] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                }else if(i==="Periodicidad vencida entre ayer y siete dias atras"){
                  $scope.estatusLlamadas[idAgente].completadas[i] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                }else if(i==="Periodicidad vencida entre ocho dias atras y tres meses atras"){
                  $scope.estatusLlamadas[idAgente].completadas[i] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                }else if(i==='Cita Telefonica'){
                  $scope.estatusLlamadas[idAgente].completadas[i] =  a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                }else if(i==='Llamada programada del día'){
                  $scope.estatusLlamadas[idAgente].completadas[i] = a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
                }
             }
          })
        })
        if(response.data.message === 'Asignacion obligatoria y opcional'){
          $scope.estatusLlamadasOpcionales(response.data[0][1])
        }
    })
  }

  $scope.estatusLlamadasOpcionales = function(asignacionOpcional){
    var info = asignacionOpcional
    info = _.sortBy(info, ['_id'])
    $scope.indexes = [
          'Periodicidad vencida entre tres meses atras y seis meses atras',
          'Periodicidad vencida entre seis meses atras al inicio',
          'Sin periodicidad'
      ];
    $scope.todas['opcionales'] = 0
    $scope.asignadasT['opcionales'] = 0
    _.forEach(info, function(a, idAgente) {
      $scope.estatusLlamadas[idAgente].agendadas['opcionales'] = 0
      $scope.estatusLlamadas[idAgente].programadas['opcionales'] = 0
      $scope.estatusLlamadas[idAgente].rechazadas['opcionales'] = 0
      $scope.estatusLlamadas[idAgente].realizadas += a.Realizadas != undefined && a.Realizadas != null ? a.Realizadas : 0;
      _.forEach($scope.indexes,function(i){
        $scope.asignadasT['opcionales']+= a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0
        $scope.estatusLlamadas[idAgente].total += a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
        if(!$scope.estatusLlamadas[idAgente].asignadas['opcionales']){
          $scope.estatusLlamadas[idAgente].asignadas['opcionales'] = 0
        }
        if(!$scope.estatusLlamadas[idAgente].completadas['opcionales']){
          $scope.estatusLlamadas[idAgente].completadas['opcionales'] = 0
        } 
        $scope.estatusLlamadas[idAgente].asignadas['opcionales'] += a.Asignadas[i] != undefined && a.Asignadas[i] != null ? a.Asignadas[i] : 0;
        if(a.Completadas[i] != undefined && a.Completadas[i] != null){
          $scope.estatusLlamadas[idAgente].agendadas['opcionales'] += a.Completadas[i].Agendadas
          $scope.estatusLlamadas[idAgente].programadas['opcionales'] += a.Completadas[i].Programadas
          $scope.estatusLlamadas[idAgente].rechazadas['opcionales'] += a.Completadas[i].Rechazadas
          $scope.estatusLlamadas[idAgente].completadas['opcionales'] += a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
          $scope.Sincambio[idAgente] += a.Completadas[i].Sincambio
          $scope.sinCambioT += a.Completadas[i].Sincambio
          $scope.todas['opcionales'] += a.Completadas[i].Agendadas + a.Completadas[i].Programadas + a.Completadas[i].Rechazadas
        }
      })
    })
  }
})

