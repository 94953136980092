'use strict'
Ilduomo.controller('agregaClienteCtrl', function(
    clienteService,
    ubicacionService,
    $rootScope,
    $scope,
    dataService,
    $uibModal,
    $state,
    $stateParams
) {
    $rootScope.setting.layout.pageSidebarMinified = true
    $scope.titulos = []
    $scope.paises = []
    $scope.entidades = []
    $scope.municipios = []
    $scope.colonias = []
    $scope.tamaniosMascota = []
    $scope.cliente = {}
    if ($stateParams.cliente) {
        $scope.cliente.clienteGeneradoId = $stateParams.cliente._id
        $scope.cliente.Nombre = $stateParams.cliente.Nombre
        $scope.cliente.MunicipioId = $stateParams.cliente.MunicipioId
        $scope.cliente.GeneradorId = $stateParams.cliente.OperadorId
        $scope.cliente.listaTelefonos = [
            {
                Numero: $stateParams.cliente.Telefono1
            },
            {
                Numero: $stateParams.cliente.Telefono2
            }
        ]
        $scope.cliente.listaMascotas = [
            {
                Nombre: $stateParams.cliente.nombreMascota
            }
        ]
    } else {
        $scope.cliente.listaTelefonos = [{}]
        $scope.cliente.listaMascotas = []
    }
    $scope.cliente.listaContactos = []
    $scope.cliente.listaDirecciones = []

    $scope.mascotaSexo = clienteService.getSexoMascotas()
    $scope.mascotaEdades = clienteService.getEdadesMascota()

    dataService.getAgregaCliente(function(response) {
        var info = response.data
        $scope.users = info.Users.users
        $scope.semanas = info.Semanas
        $scope.especies = info.Especies
        $scope.razas = info.Razas
        $scope.titulos = info.Titulos
        $scope.paises = info.Paises
        $scope.entidades = info.Entidades
        $scope.municipios = info.Municipios
        $scope.tiposTelefonos = info.TiposTelefonos
        $scope.TipoDirecciones = info.TipoDirecciones
        $scope.TipoParentescos = info.TipoParentescos
        $scope.negocios = info.Negocios
        $scope.cliente.periodicidad = {}
        $scope.cliente.periodicidad.NegocioId =
            _.find($scope.negocios, function(n) {
                return n.Nombre == 'Estética'
            })._id || null
        $scope.cliente.periodicidad.Reagendado = 'false'
        _.forEach($scope.TipoDirecciones, function(td) {
            // if (td.Nombre == 'Calle') {
            //     $scope.cliente.CalleId = td._id;
            // }else if (td.Nombre == 'Numero Externo') {
            //     $scope.cliente.NumeroExternoId = td._id;
            // }else{
            $scope.cliente.listaDirecciones.push({
                TipoDireccionId: td._id,
                TipoDireccion: td.Nombre,
                required: td.Required
            })
            // }
        })
    })

    // ====================
    // clienteService.getSemanas(function (response) {
    //     $scope.semanas = response.data;
    // });
    // clienteService.getEspecies(function (response) {
    //     $scope.especies = response.data;
    // });
    // clienteService.getRazas(function (response) {
    //     $scope.razas = response.data;
    // });
    // clienteService.getTamanioMascota(function (response) {
    //     $scope.tamaniosMascota = response.data;
    // });

    // titulosService.getTitulos(function(response) {
    //     $scope.titulos = response.data;
    // });

    // ubicacionService.getPaises(function(response){
    //     $scope.paises=response.data;
    // });

    // ubicacionService.getEntidades(function(response){
    //     $scope.entidades=response.data;
    // });

    // ubicacionService.getMunicipios(function(response){
    //     $scope.municipios=response.data;
    // });

    // telefonosService.getTipos(function(response){
    //     $scope.tiposTelefonos = response.data;
    // });
    // titulosService.getParentescos(function (response) {
    //     $scope.TipoParentescos = response.data;
    // });
    // clienteService.getNegocios(function (response) {
    //     $scope.negocios = response.data;
    // });
    // ====================

    $scope.saveCliente = function() {
        if ($scope.clienteSaved) return
        $scope.clienteSaved = true            
        $scope.cliente.FranquiciaId = $rootScope.currentUser.FranquiciaId
        $scope.cliente.UserId = $rootScope.currentUser._id
        // if ($stateParams.cliente) {
        //     $stateParams.cliente._method = "DELETE";
        //     dataService.dropClienteGenerado($stateParams.cliente, function (response) {
        //         console.log(response.data);
        //     });
        // }
        clienteService.saveCliente($scope.cliente, function(response) {
            if (response.data.Estatus == 'True') {
                $.gritter.add({
                    title: 'Agregado',
                    text: response.data.mensaje,
                    time: '1000',
                    class_name: 'alert alert-success'
                })
                $scope.cliente._id = response.data._id
                $state.go('clienteAgendar', {
                    cliente: null,
                    clienteId: $scope.cliente._id,
                    negociosId: '1',
                    agendado: null,
                    dia: null,
                    serv: null,
                    fromServicesGrid: false
                })
            }
        })
    }

    // _.forEach($scope.TipoDirecciones, function (td) {
    //     // if (td.required) {
    //         $scope.cliente.listaDirecciones.push(td);
    //     // }
    // });

    $scope.$watch('cliente.MunicipioId', function(newValue) {
        if ($scope.cliente.MunicipioId) {
            ubicacionService.getColoniasMunicipio(newValue, function(response) {
                $scope.colonias = response.data
            })
        }
    })

    // datepicker
    $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1
    }
    $scope.maxDate = new Date()
    $scope.formatDate = 'dd-MMMM-yyyy'
    $scope.openFechaNacimiento = function($event, mascota) {
        $event.preventDefault()
        $event.stopPropagation()
        // console.log(mascota);
        $scope.cliente.listaMascotas[
            $scope.cliente.listaMascotas.indexOf(mascota)
        ].isOpen = true
    }

    $scope.addRowPhone = function() {
        $scope.cliente.listaTelefonos.push({
            TipoTelefonoId: '',
            Numero: ''
        })
    }
    $scope.addRowDireccion = function() {
        var td = _.find($scope.TipoDirecciones, function(td) {
            return td.Nombre == 'Otros'
        })
        $scope.cliente.listaDirecciones.push({
            TipoDireccionId: td._id,
            required: 'false'
        })
    }
    $scope.addRowContacto = function() {
        $scope.cliente.listaContactos.push({})
    }
    $scope.addRowMascotas = function() {
        $scope.cliente.listaMascotas.push({})
    }

    $scope.addRowMascotas()

    $scope.dropTelefono = function(tel) {
        var index = $scope.cliente.listaTelefonos.indexOf(tel)
        if ($scope.cliente.listaTelefonos[index]._id) {
            // llamar al servicio para eliminarlo completamente
        } else {
            $scope.cliente.listaTelefonos.splice(index, 1)
        }
    }
    $scope.dropDireccion = function(dir) {
        var index = $scope.cliente.listaDirecciones.indexOf(dir)
        if ($scope.cliente.listaDirecciones[index]._id) {
            // llamar al servicio para eliminarlo completamente
        } else {
            $scope.cliente.listaDirecciones.splice(index, 1)
        }
    }
    $scope.dropContacto = function(c) {
        var index = $scope.cliente.listaContactos.indexOf(c)
        if ($scope.cliente.listaContactos[index]._id) {
            // llamar al servicio para eliminarlo completamente
        } else {
            $scope.cliente.listaContactos.splice(index, 1)
        }
    }

    $scope.dropMascota = function(m) {
        var index = $scope.cliente.listaMascotas.indexOf(m)
        if ($scope.cliente.listaMascotas[index]._id) {
            // llamar al servicio para eliminarlo completamente
        } else {
            $scope.cliente.listaMascotas.splice(index, 1)
        }
    }

    $scope.capturarAlimentoMascota = function(mascota, size) {
        var index = $scope.cliente.listaMascotas.indexOf(mascota)
        var modalInstance = $uibModal.open({
            templateUrl: 'capturarAlimentoModal.html',
            controller: 'capturarAlimentoMascotaModalInstance',
            size: size,
            resolve: {
                mascota: function() {
                    return mascota
                },
                titulo: function() {
                    return 'Capturar Alimento de ' + mascota.Nombre
                        ? mascota.Nombre
                        : ''
                }
            }
        })

        modalInstance.result.then(
            function(mca) {
                $scope.cliente.listaMascotas.splice(index, 1, mca)
            },
            function() {
                $log.info('Modal dismissed at: ' + new Date())
            }
        )
    }
})

Ilduomo.controller('capturarAlimentoMascotaModalInstance', function(
    $scope,
    mascota,
    titulo,
    $uibModalInstance,
    dataService
) {
    $scope.mascota = mascota
    $scope.mascota.AlimentoId = $scope.mascota.AlimentoId
        ? $scope.mascota.AlimentoId
        : []
    $scope.mascota.Alimento = $scope.mascota.Alimento
        ? $scope.mascota.Alimento
        : []

    $scope.titulo = titulo
    dataService.getMarcasAlimento(function(response) {
        $scope.marcas = response.data
        _.forEach($scope.marcas, function(m) {
            _.find($scope.mascota.AlimentoId, function(ai) {
                if (ai == m._id) {
                    m.checked = true
                }
            })
        })
    })

    $scope.setAlimento = function(marca) {
        var insertar = $scope.mascota.AlimentoId.indexOf(marca._id)
        // indexOf en un array regresa un -1 si no encuentra los pasado como parametro
        // si esta te devuelve el indice en el que se encuentra
        if (insertar < 0) {
            $scope.mascota.AlimentoId.push(marca._id)
            $scope.mascota.Alimento.push(marca)
        } else {
            $scope.mascota.AlimentoId.splice(insertar, 1)
            $scope.mascota.Alimento.splice(insertar, 1)
        }
    }

    $scope.ok = function() {
        $uibModalInstance.close($scope.mascota)
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel')
    }
})
