'use strict'
Ilduomo.controller('agendarController', function(
  $scope,
  $stateParams,
  $rootScope,
  dataService,
  clienteService,
  socket,
  $uibModal,
  $state,
  $log,
  $q,
  $cookies
) {
  socket.on('agendar', function(data) {
    $scope.reload = true
    // aqui se agregara el nuevo servicio con su camioneta y a la hora indicada
    var d = JSON.parse(data)
    // console.log(d)
    // primero se tiene que remover el servicio y despues hacer un push a la hora correcta
    var cam = _.find($scope.camionetas, function(c) {
      return c._id == d.CamionetaId
    })
    var fecha = new Date(Date.parse(d.Fecha.date))
    var fechaServicio =
      fecha.getDayFormatted() +
      '-' +
      fecha.getMonthFormatted() +
      '-' +
      fecha.getFullYear()
    // _.forEach($scope.camionetas, function (c) {
    //   _.forEach(c[fechaServicio], function (l) {
    //     if (l.servicios) {
    //       if(l.servicios[0] != undefined && l.servicios[0]._id == d._id)
    //         delete l.servicios
    //     }
    //   })
    // })
    _.forEach($scope.camionetas, function(c) {
      _.forEach(c[$scope.fechaCamioneta], function(l) {
        if (l.servicios && l.servicios[0] && l.servicios[0]._id === d._id) {
          l.servicios = null
        }
      })
    })
    if (fecha.getMinutes() == 0) {
      var time = 'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
    } else {
      var time =
        'Servicios' +
        fecha.getHours() +
        '' +
        fecha.getMinutes() +
        'to' +
        (fecha.getHours() + 1)
    }
    if (cam) {
      if (cam[fechaServicio]) {
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = [d]
        cam[fechaServicio][time].drag = false
        cam[fechaServicio][time].drop = false
        $scope.$apply()
      } else {
        cam[fechaServicio] = {}
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = [d]
        cam[fechaServicio][time].drag = false
        cam[fechaServicio][time].drop = false
        $scope.$apply()
      }
      $scope.reload = false
      $scope.$apply()
    }
  })

  angular.element(document).ready(function() {
    socket.on('Cancelacion', function(data) {
      $scope.reload = true
      var d = JSON.parse(data)
      // console.log(d)
      var fecha = new Date(Date.parse(d.agendado.Fecha.date))
      var fechaServicio =
        fecha.getDayFormatted() +
        '-' +
        fecha.getMonthFormatted() +
        '-' +
        fecha.getFullYear()
      if (fecha.getMinutes() == 0) {
        var time =
          'Servicios' + fecha.getHours() + 'to' + fecha.getHours() + '30'
      } else {
        var time =
          'Servicios' +
          fecha.getHours() +
          '' +
          fecha.getMinutes() +
          'to' +
          (fecha.getHours() + 1)
      }
      var cam = _.find($scope.camionetas, c => c._id == d.agendado.CamionetaId)

      if (cam[fechaServicio]) {
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = null
        cam[fechaServicio][time].drag = true
        cam[fechaServicio][time].drop = true
        $scope.$apply()
      } else {
        cam[fechaServicio] = {}
        cam[fechaServicio][time] = {}
        cam[fechaServicio][time].servicios = null
        cam[fechaServicio][time].drag = true
        cam[fechaServicio][time].drop = true
        $scope.$apply()
      }

      $scope.reload = false
      $scope.$apply()
    })
    var mapDays = [
      {
        day: 1,
        name: 'Lunes'
      },
      {
        day: 2,
        name: 'Martes'
      },
      {
        day: 3,
        name: 'Miercoles'
      },
      {
        day: 4,
        name: 'Jueves'
      },
      {
        day: 5,
        name: 'Viernes'
      },
      {
        day: 6,
        name: 'Sabado'
      }
    ]

    /**
     * Obtiene el numero de la semana en la que se encuentra un dia
     * @param  {Date} date dia a comprobar el dia de la semana
     * @return {Integer}      entero con la semana del año a la que pertenece el dia enviado
     */
    function getWeekNumber(date) {
      var checkDate = new Date(date)
      checkDate.setDate(checkDate.getDate() + 4 - (checkDate.getDay() || 7)) // Thursday
      var time = checkDate.getTime()
      checkDate.setMonth(0) // Compare with Jan 1
      checkDate.setDate(1)
      return Math.floor(Math.round((time - checkDate) / 86400000) / 7) + 1
    }

    /**
     * Desabilita los domingos para agendar
     */
    $scope.disabledSundays = function(date, mode) {
      return date.mode === 'day' && date.date.getDay() === 0
    }

    function parseMyWeeks(week, myCalendar) {
      if (week > myCalendar) {
        // console.log(week);
        var veces = Math.floor(week / myCalendar)
        return parseMyWeeks(week - myCalendar * veces, myCalendar)
      } else {
        return week
      }
    }

    Date.prototype.addDays = function(days) {
      /* setTime(daysToBeAddedToMonth) */
      let date = new Date(this.valueOf())
      this.setDate(date.getDate() + days)
      return date
    }

        $scope.openAgendar = function($event) {
      $event.preventDefault()
      $event.stopPropagation()
      $scope.isOpen = !$scope.isOpen
    }

    $scope.checkCam = function(
      $event,
      ui,
      camionetaId,
      InicioHours,
      InicioMinutes
    ) {
      $rootScope.overload = null
      $scope.servicio.ClienteId = $scope.cliente._id
      $scope.servicio.ColoniaId = $scope.cliente.ColoniaId
      $scope.servicio.ZonaId = $scope.cliente.ZonaId
      $scope.servicio.UserId = $rootScope.currentUser._id
      $scope.servicio.Colonia = $scope.cliente.coloniaNombre
      $scope.servicio.Zona = $scope.cliente.zonaNombre
      $scope.servicio.CamionetaId = camionetaId + ''
      $scope.servicio.inicio = new Date(
        $scope.fecha.getFullYear(),
        $scope.fecha.getMonth(),
        $scope.fecha.getDate(),
        InicioHours,
        InicioMinutes
      )
      $scope.servicio.startMillis = $scope.servicio.inicio.getTime()
      var cam = _.find(
        $scope.camionetas,
        c => c._id == $scope.servicio.CamionetaId
      )
      let fechaFinal = new Date($scope.servicio.inicio.getTime())
      fechaFinal.setMinutes(
        fechaFinal.getMinutes() + parseInt($scope.servicio.Duracion)
      )
      $scope.servicio.endMillis = fechaFinal.getTime()
      if ($scope.servicio.inicio.getMinutes() == 0) {
        var time =
          'Servicios' +
          $scope.servicio.inicio.getHours() +
          'to' +
          $scope.servicio.inicio.getHours() +
          '30'
      } else {
        var time =
          'Servicios' +
          $scope.servicio.inicio.getHours() +
          '' +
          $scope.servicio.inicio.getMinutes() +
          'to' +
          ($scope.servicio.inicio.getHours() + 1)
      }
      // if(cam[$scope.fechaCamioneta][time].servicios !== undefined)
      var times =
        parseInt(cam[$scope.fechaCamioneta][time].servicios[0].Duracion) / 30
      var intermedio = new Date($scope.servicio.inicio.getTime())
      for (var i = 1; i < times; i++) {
        intermedio.setMinutes(intermedio.getMinutes() + 30)
        if (intermedio.getMinutes() == 0) {
          var stringInter =
            'Servicios' +
            intermedio.getHours() +
            'to' +
            intermedio.getHours() +
            '30'
        } else {
          var stringInter =
            'Servicios' +
            intermedio.getHours() +
            '' +
            intermedio.getMinutes() +
            'to' +
            (intermedio.getHours() + 1)
        }
        // console.log(stringInter);
        if (cam[$scope.fechaCamioneta][stringInter]) {
          if (
            cam[$scope.fechaCamioneta][stringInter].servicios &&
            cam[$scope.fechaCamioneta][stringInter].servicios instanceof
              Array &&
            cam[$scope.fechaCamioneta][stringInter].servicios[0] != undefined
          ) {
            cam[$scope.fechaCamioneta][time].overload =
              'El servicio se transpone con otro a las: ' +
              intermedio.getHours() +
              ':' +
              intermedio.getMinutes()
            $rootScope.overload = true
          }
        }
      }
    }

    $scope.overMe = function($event, ui, list) {
      list.over = true
      $scope.$apply()
      // console.log(list);
    }

    $scope.outMe = function($event, ui, list) {
      delete list.over
      delete list.overload
      $scope.$apply()
    }

    /**
     * Aqui se añade una clase a todas las fechas, se arreglo para poder poner el nombre de la clase como tooltip
     * a la fecha que quiera,
     * se genera un tooltip al regresar el nombre de la clase
     * aqui checar la camioneta,
     * regresa un array, el primer parametro es la clase a agregar, y el segundo es el texto del tooltip
     */
    $scope.customCalendario = function(date) {
      // numero de semana ciclico, con el calendario de rutas
      // se toma el año de 52 semanas, en este caso el 2 de enero queda como primera semana del 2017
      if (date.mode === 'day') {
        var semana = parseMyWeeks(
          getWeekNumber(date.date),
          $scope.semanas.length
        )
        var day =
          _.find(mapDays, function(d) {
            return d.day === date.date.getDay()
          }) || null
        var turno = ['Morning', 'Afternoon']
        if (day) {
          var morning = _.filter($scope.camionetas_calendario, function(c) {
            if (c.Calendario) {
              if (c.Calendario['Semana ' + semana]) {
                return (
                  c.Calendario['Semana ' + semana][day.name + turno[0]] ==
                  $scope.cliente.zonaNombre
                )
              }
            }
          })
          var afternoon = _.filter($scope.camionetas_calendario, function(c) {
            if (c.Calendario) {
              if (c.Calendario['Semana ' + semana]) {
                return (
                  c.Calendario['Semana ' + semana][day.name + turno[1]] ==
                  $scope.cliente.zonaNombre
                )
              }
            }
          })
          var letrero = '['
          if (morning.length != 0) {
            // console.log("morning ");
            _.forEach(morning, function(m) {
              letrero += m.Nombre + ','
            })
            letrero += '] pasan en la mañana '
          }
          if (afternoon.length != 0) {
            letrero += morning.length != 0 ? '[' : ''
            // console.log("afternoon");
            _.forEach(afternoon, function(m) {
              letrero += m.Nombre + ','
            })
            letrero += '] pasan en la tarde'
          }
          if (afternoon.length != 0 || morning.length != 0) {
            return ['other', letrero]
          } else {
            return ['']
          }
        } else {
          return ['']
        }
      }
    }

    //Función para periodicidades por PRODUCTO
    $scope.getPeriodicidades = function(periodicidades, conjunto) { 
      $scope.periodicidadUnica = conjunto || false
      let period = []
      let obj = {}
      _.forEach(periodicidades, p => {
        if(p.ProductoId) {
          obj.periodicidadId = p._id || p.PeriodicidadId
          obj.negocioId = p.NegocioId
          obj.periodicidad = Number(p.Periodicidad)
          obj.productoNombre = p.ProductoNombre
          obj.productoId = p.ProductoId
          obj.reagendado = p.Reagendado
          obj.remanente = Number(p.Remanente) || 0
          if(p.FechaPeriodicidad) {
            obj.FechaPeriodicidad = new Date(Date.parse(p.FechaPeriodicidad.date))
            obj.FechaPeriodicidad = obj.FechaPeriodicidad.addDays(obj.remanente)
          }
          if(p.PeriodicidadA) obj.anterior = p.PeriodicidadA
          period.push(obj)
          obj = {}
        }
      })
      $scope.periodicidades = $scope.ordenaPeriodicidadProducto(period)
    }

    $scope.setPeriodicidadAgendado = function(agendado, periodicidades) {
      agendado.forEach(a => {
        if (a.ProductoId) {
          let p = _.find(periodicidades, p => {
            return p.ProductoId === a.ProductoId
          })
          if (p) {
            a.periodicidadId = p._id
            a.periodicidad = Number(p.Periodicidad)
            a.remanente = Number(p.Remanente)
          } 
        }
        a.remanente = a.remanente || 0
        a.periodicidad = a.periodicidad || 0
      })
      $scope.servicio.agendado = agendado
    }

    $scope.getNegociosAgendado = function (negociosId, negociosTodos){
      return negociosTodos.filter(nt => {
        return negociosId.includes(nt._id)
      })
    }

    $scope.ponerPeriodicidades = () => {
      $scope.negocios = $scope.getNegociosAgendado($scope.negociosId, $scope.negociosTodos)
      _.forEach($scope.negocios, (n) => {
        if (n.Periodicidad === 'Producto') {
          if ($stateParams.serv) {
            $scope.setPeriodicidadAgendado($scope.agendado, $scope.cliente.periodicidades)
          }
          else {
            let nutri = _.find($scope.cliente.Periodicidades, per => {
              return per.negocio.Nombre === 'Nutrición'
            })
            if(nutri) {
              $scope.getPeriodicidades(nutri.subPeriodicidad || null, nutri.Conjunto)
            }
          }
        }
      })
    }

    $scope.ordenaPeriodicidadProducto = function (periodicidades) {
      if (periodicidades.length > 1) {
        let proximos = _.orderBy(periodicidades, p => p.FechaPeriodicidad, 'asc')
        proximos = _.partition(proximos, i => i.FechaPeriodicidad < new Date().addDays(-8))
        if (proximos[0].length > 0) {
          proximos[0] = _.orderBy(proximos[0], i =>  i.FechaPeriodicidad, 'desc') 
        }
        return proximos[1].concat(proximos[0]) //prox. period. hasta +8 días, y luego los mas cercanos a esa fecha
      }
      return periodicidades
    }

    $scope.setAllStuff = function(info, prefix) {
      if(!$scope.negociosTodos) {
        $scope.negociosTodos = info.Negocios
      }
      $scope[prefix] = {}
      $scope[prefix].camionetas_calendario = info.Camionetas
      // $scope[prefix].serviciosOfrecidos = info.Servicios;
      $scope[prefix].productos = info.Productos
      $scope[prefix].otrosProductosBack = info.OtrosProductos
      $scope[prefix].otrosProductos = info.OtrosProductos
      $scope.fechaCamioneta = info.FechaAgendar
      $scope.presentaciones_catalogos = $scope.presentaciones_catalogos || info.presentaciones_catalogos
      $scope.unidades_medida = $scope.unidades_medida || info.unidades_medida
      $scope.semanas = $scope.semanas || info.Semanas

      var date = new Date()
      date.setDate(date.getDate() + 1)
      if ($stateParams.dia) {
        $scope.fecha = (function(dia) {
          var d = dia.split('-')
          return new Date(d[2], d[1] - 1, d[0])
        })($stateParams.dia)
      } else {
        $scope.fecha = date
      }
      $scope.minDate = new Date()
      $scope.dateOptions = {
        customClass: $scope.customCalendario,
        dateDisabled: $scope.disabledSundays,
        minDate: $scope.minDate,
        startingDay: 1
      }

      // $scope.$apply()
      if (info.Marcas && !$scope.marcasCatalogo) {
        $scope.marcasCatalogo = info.Marcas
      }
    }

    $scope.imprimir = function(c) {
      // TODO: sumar la duracion con el inicio de las listas
      // $scope.Fecha  =  $('#fecha').datepicker('getDate');
      // console.log($scope.listaServicios);
      console.log(c)
    }

    $scope.setService = function (service) {
      $scope.servicio = service
      $scope.agendado = $scope.servicio.agendado
      _.forEach($scope.agendado, a =>{
        a.touched = true
      })
      $scope.periodicidadUnica = $scope.servicio.Conjunto || false
      var date = new Date(Date.parse($scope.servicio.Fecha.date))
      var originalDate =
        date.getDayFormatted() +
        '-' +
        date.getMonthFormatted() +
        '-' +
        date.getFullYear()
      $scope.originalDateFormatted = originalDate
    }

    /** Ends function declarations **/

    $rootScope.setting.layout.pageSidebarMinified = true
    $scope.cliente = $stateParams.cliente
    $scope.negociosId = $stateParams.negociosId.split('-')
    $scope.accion = $stateParams.serv ? 'Reagendar' : 'Agendar'
    if (!$scope.negocios && sessionStorage.getItem('negocios')) {
      var info = JSON.parse(sessionStorage.getItem('negocios'))
      $scope.negociosTodos = info.Negocios
    } 
    if ($stateParams.agendado) {
      _.forEach($stateParams.agendado.agendado, function(a) {
        delete a.mascota
      })
    }
      
    $scope.servicio = {}
    $scope.agendado = []

    if ($stateParams.serv) {
      if ($stateParams.agendado) {
        $scope.setService($stateParams.agendado)
      } else {
        //no hay información del servicio
        dataService.getInfoServicio(
          $stateParams.serv,
          $stateParams.negociosId,
          false,
          function(response) {
            $scope.setService(response.data)
          }
        )
      }
    }
    
    let s
    if (!$scope.cliente) { 
      //Se recargó la página
      clienteService.getCliente($stateParams.clienteId, function(response) {
        $scope.cliente = response.data
        s = {
          ClienteId: $scope.cliente._id,
          ColoniaId: $scope.cliente.ColoniaId,
          ZonaId: $scope.cliente.ZonaId,
          UserId: $rootScope.currentUser._id,
          Colonia: $scope.cliente.coloniaNombre,
          Zona: $scope.cliente.zonaNombre,
          NegociosId: $scope.negociosId,
          Servicios: $stateParams.agendado
            ? $stateParams.agendado.Servicios
            : 0,
          Duracion: $stateParams.agendado
            ? $stateParams.agendado.Duracion
            : '60',
          Cliente: $scope.cliente,
          drag: true,
          drop: true
        }
        // $scope.negocio = _.find($scope.negocios, function (n){
        //   return n._id === $scope.negocioId
        // })
        $scope.negocios = $scope.getNegociosAgendado($scope.negociosId, $scope.negociosTodos)
        $scope.listaServicios = s
        if ($stateParams.agendado) {
          $scope.listaServicios = {}
        }
        _.forEach($scope.negocios, (n) => {
          if (n.Periodicidad === 'Producto') {
            if($stateParams.serv) {
              $scope.setPeriodicidadAgendado($scope.agendado, $scope.cliente.Periodicidades)
            } else {
              let productos = _.filter($scope.cliente.Periodicidades, p => {
                return p.negocio.Periodicidad === 'Producto'
              })
              $scope.getPeriodicidades(productos, productos[0].Conjunto || false)
            }
          }
        })
      })
    } else { 
      //No se ha recargado la página
      s = {
        ClienteId: $scope.cliente._id,
        ColoniaId: $scope.cliente.ColoniaId,
        ZonaId: $scope.cliente.ZonaId,
        UserId: $rootScope.currentUser._id,
        Colonia: $scope.cliente.coloniaNombre,
        NegociosId: $scope.negociosId,
        Zona: $scope.cliente.zonaNombre,
        Servicios: $stateParams.agendado ? $stateParams.agendado.Servicios : 0,
        Duracion: $stateParams.agendado ? $stateParams.agendado.Duracion : '60',
        Cliente: $scope.cliente,
        drag: true,
        drop: true
      }
      $scope.listaServicios = JSON.parse(JSON.stringify(s))
      if ($stateParams.agendado) {
        $scope.listaServicios = {}
      }
      if(!$scope.negociosTodos) {
        console.log("No habia negocios")
        dataService.getNegocios(function(response){
          $scope.negociosTodos = response.data
          $scope.negocios = $scope.getNegociosAgendado($scope.negociosId, $scope.negociosTodos)
          $scope.ponerPeriodicidades()
        })
      } else {
        $scope.ponerPeriodicidades()
      }
      // console.log($scope.listaServicios);
    }

    $scope.formatDate = 'EEEE MM/d/y'

    $scope.$watch('fecha', function(newValue) {
      if (!newValue) return
      // $state.go('clienteAgendar', {cliente: $scope.cliente, clienteId: $stateParams.clienteId, negocioId: $stateParams.negocioId, agendado: null, dia:newValue })
      $scope.fechaCamioneta =
        newValue.getDayFormatted() +
        '-' +
        newValue.getMonthFormatted() +
        '-' +
        newValue.getFullYear()
      $state.go('.', { dia: $scope.fechaCamioneta }, { notify: false })
      /** TODO: cargar junto a los datos del cliente **/
      /** TODO: poner precedencia en cuanto a la preferencia de camionetas por negocio **/
      let indexNegocio = $scope.negociosId.indexOf("1")
      let negocioId = indexNegocio < 0 ? $scope.negociosId[0] : $scope.negociosId[indexNegocio]
      dataService.getAgendadoElDiaNegocio(
        $scope.fechaCamioneta,
        negocioId,
        function(response) {
          $scope.reload = true
          $scope.camionetas = response.data
          $scope.duplicado = null
          $scope.duplicadoMensaje = null
          var day =
            _.find(mapDays, function(d) {
              return d.day === newValue.getDay()
            }) || null
          var turno = ['Morning', 'Afternoon']
          var semana = parseMyWeeks(
            getWeekNumber(newValue),
            $scope.semanas.length
          )
          if(s === undefined) {
            setTimeout(function() {
              $scope.listaServicios = JSON.parse(JSON.stringify(s))
              _.forEach(response.data, function(ca) {
              _.forEach($scope.camionetas, function(c) {
                if (c._id == ca._id) {
                  c[$scope.fechaCamioneta] = ca[$scope.fechaCamioneta]
                  if (c.Calendario) {
                    delete c.routeMorning
                    delete c.routeAfternoon
                    if (c.Calendario['Semana ' + semana]) {
                      if (
                        c.Calendario['Semana ' + semana][day.name + turno[0]] ==
                        $scope.cliente.zonaNombre
                      ) {
                        c.routeMorning = true
                      }
                      if (
                        c.Calendario['Semana ' + semana][day.name + turno[1]] ==
                        $scope.cliente.zonaNombre
                      ) {
                        c.routeAfternoon = true
                      }
                    }
                  }
                  _.forEach(c[$scope.fechaCamioneta], function(list) {
                    if (list.servicios !== undefined) {
                      // if ($stateParams.agendado) {
                      _.forEach(list.servicios, function(v, k) {
                        if (v._id == $stateParams.serv) {
                          list.servicios = list.servicios.filter(function(f) {
                            return f._id !== $stateParams.serv
                          })
                          list.drag = true
                          list.drop = true
                          // console.log("1");
                        } else if (
                          v.ClienteId == $scope.listaServicios.ClienteId
                        ) {
                          $scope.duplicado = true
                          $scope.duplicadoMensaje =
                            'Ya se agendo servicio en ' + ca.Nombre
                          list.drag = false
                          list.drop = false
                          list.duplicado = true
                          // console.log("2");
                        } else {
                          // console.log("Ninguno");
                        }
                      })
                    }
                  })
                }
              })
            })
            }, 5)
          } else {
            $scope.listaServicios = JSON.parse(JSON.stringify(s))
            _.forEach(response.data, function(ca) {
              _.forEach($scope.camionetas, function(c) {
                if (c._id == ca._id) {
                  c[$scope.fechaCamioneta] = ca[$scope.fechaCamioneta]
                  if (c.Calendario) {
                    delete c.routeMorning
                    delete c.routeAfternoon
                    if (c.Calendario['Semana ' + semana]) {
                      if (
                        c.Calendario['Semana ' + semana][day.name + turno[0]] ==
                        $scope.cliente.zonaNombre
                      ) {
                        c.routeMorning = true
                      }
                      if (
                        c.Calendario['Semana ' + semana][day.name + turno[1]] ==
                        $scope.cliente.zonaNombre
                      ) {
                        c.routeAfternoon = true
                      }
                    }
                  }
                  _.forEach(c[$scope.fechaCamioneta], function(list) {
                    if (list.servicios !== undefined) {
                      // if ($stateParams.agendado) {
                      _.forEach(list.servicios, function(v, k) {
                        if (v._id == $stateParams.serv) {
                          list.servicios = list.servicios.filter(function(f) {
                            return f._id !== $stateParams.serv
                          })
                          list.drag = true
                          list.drop = true
                          // console.log("1");
                        } else if (
                          v.ClienteId == $scope.listaServicios.ClienteId
                        ) {
                          $scope.duplicado = true
                          $scope.duplicadoMensaje =
                            'Ya se agendo servicio en ' + ca.Nombre
                          list.drag = false
                          list.drop = false
                          list.duplicado = true
                          // console.log("2");
                        } else {
                          // console.log("Ninguno");
                        }
                      })
                      // } else {
                      // if (list.servicios.ClienteId == $scope.listaServicios.ClienteId) {
                      //     $scope.duplicado = true
                      //     $scope.duplicadoMensaje = 'Ya se agendo servicio en ' + ca.Nombre
                      //     list.drag = false
                      //     list.drop = false
                      //     list.duplicado = true
                      //                             // console.log("3");
                      //   }
                      // }
                    }
                  })
                }
              })
            })
          }
          $scope.reload = false
        }
      )
    })

    var estatus = true

    // sessionStorage
    _.forEach($scope.negociosId, (nid) => {
      let prefix = 'negocio' + nid
      if (sessionStorage.getItem(prefix)) {
        $scope.setAllStuff(JSON.parse(sessionStorage.getItem(prefix)), prefix)
      }
      else {
        dataService.getAgendar(nid, function(response) {
          sessionStorage.setItem(prefix, JSON.stringify(response.data))
          $scope.setAllStuff(response.data, prefix)
        })
      }
    })

    $scope.checkResponse = function (response) {
      if (response.data.Estatus == 'True') {
        if ($stateParams.fromServicesGrid === 'true') {
          $state.go('pistaMonitoreo', { fecha: $scope.originalDateFormatted})
        } else {
          $state.go('clienteClientes')
        }
      } else if (response.data.Estatus == 'Error') {
        $scope.lockRequest = false
        iziToast.show({
          color: 'yellow',
          timeout: 1500,
          title: 'Mensaje',
          icon: 'ico-warning',
          close: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          layout: 3,
          message: response.data.message,
          position: 'topRight'
        })
      }
    }

    $scope.AgendarServicio = function() {
      if ($scope.lockRequest) return
      $scope.lockRequest = true
      console.log($scope.servicio)
      _.forEach($scope.servicio.agendado, (a) => {
        if (a.periodicidad && a.periodicidad <= 0) { a.periodicidad = null }
        delete a.touched
        delete a.agendado
      })
      switch ($scope.accion) {
        case 'Agendar':
          clienteService.agendar(
            $scope.servicio, 
            function(response) {
            $scope.checkResponse(response) 
          })
          break
        case 'Reagendar':
          $scope.servicio._method = 'PUT'
          $scope.servicio._id = $stateParams.agendado
            ? $stateParams.agendado._id
            : $stateParams.serv
          clienteService.Reagendar(
            $scope.servicio._id,
            $scope.servicio,
            function(response) {
              $scope.checkResponse(response) 
            }
          )
          break
        default:
          console.log('Error no se encontro la opción especificada')
      }
    }

    /**
     *
     * Modals
     *
     */

    $scope.showCalendario = function(size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'calendarioRutas.html',
        controller: 'calendarioRutasModalInstance',
        size: size,
        resolve: {
          titulo: function() {
            return 'Semana 1'
          },
          negocioId: function() {
            return $scope.negocioId
          }
        }
      })

      modalInstance.result.then(
        function(r) {
          console.log(r)
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Modal para agregar el incremento o descuento del servicio
     * @param {[type]} size [description]
     */
    $scope.DescuentoIncremento = function(size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'descuentoIncremento.html',
        controller: 'descuentoIncrementoModalInstance',
        size: size,
        resolve: {
          titulo: function() {
            return 'Descuento/Incremento'
          }
        }
      })

      modalInstance.result.then(
        function(di) {
          $scope.alteracionPrecio = di
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * modal para programar una llamada en una fecha en especifico a un cliente
     * @param  {String} size    tamaño del modal
     * @return {NULL}
     */
    $scope.programarLlamada = function(size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'programarLlamadaClient.html',
        controller: 'programarLlamadaClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return $scope.cliente
          },
          negocios: function() {
            return $scope.negocios
          },
          titulo: function() {
            return 'Programar'
          }
        }
      })

      modalInstance.result.then(
        function(lp) {
          dataService.agregaLlamadaProgramada(lp, function(response) {
            console.log(response.data)
          })
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    /**
     * Respuesta de llamada realizada por callcenter
     * @param  {String} size    tamaño del modal a abrir
     */
    $scope.programarRespuestaLlamada = function(size) {
      var modalInstance = $uibModal.open({
        templateUrl: 'programarRespuestaLlamadaClient.html',
        controller: 'programarRespuestaLlamadaClientModalInstance',
        size: size,
        resolve: {
          cliente: function() {
            return $scope.cliente
          },
          negocios: function() {
            return $scope.negocios
          },
          titulo: function() {
            return 'Programar Respuesta'
          }
        }
      })

      modalInstance.result.then(
        function(rl) {
          // $scope.AgendarServicio();
          rl.respuestallamada.FranquiciaId = $rootScope.currentUser.FranquiciaId
          console.log(rl)
          // Primero guardar la respuesta de la llamada
          dataService.agregaRespuestaLlamada(rl.respuestallamada, function(
            response
          ) {
            // console.log(response.data);
          })
          if (rl.respuestallamada.RespuestaLlamada.Agendar) {
            var modalInstance = $uibModal.open({
              templateUrl: 'confirmarAgendar.html',
              controller: 'confirmaryAgendarInstanceController',
              size: 'md'
            })

            modalInstance.result.then(
              function(agendar) {
                if (agendar) {
                  clienteService.agendar($scope.servicio, function(response) {
                    if (response.data.Estatus == 'True') {
                      $state.go('clienteAgendar', {
                        cliente: rl.cliente,
                        clienteId: rl.cliente._id,
                        negocioId: rl.respuestallamada.NegocioId
                      })
                    }
                  })
                } else {
                  $state.go('clienteAgendar', {
                    cliente: rl.cliente,
                    clienteId: rl.cliente._id,
                    negocioId: rl.respuestallamada.NegocioId
                  })
                }
              },
              function() {
                $log.info('Modal dismissed at: ' + new Date())
              }
            )
          }
        },
        function() {
          $log.info('Modal dismissed at: ' + new Date())
        }
      )
    }

    $scope.configuraServicio = function(servicio, size) {
      if (!servicio || !servicio.Cliente) {
        console.log('no hay servicios')
        return false
      }
      var modalInstance = $uibModal.open({
        templateUrl: 'modalConfigurarServicio.html',
        controller: 'configurarServicioModalInstanceCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'app-modal-window',
        resolve: {
          negocios: function(){
            return $scope.negocios
          },
          servicio: function() {
            return servicio
          },
          cliente: function() {
            return $scope.cliente
          },
          titulo: function() {
            return 'Configurar'
          },
          productos: function() {
            let productos = {}
            _.forEach($scope.negociosId, nid => {
              if ($scope['negocio' + nid]) {
                productos[nid] = $scope['negocio' + nid].productos
              }
            })
            return productos
          },
          otrosProductos: function() {
            let otros = {}
            _.forEach($scope.negociosId, nid => {
              if ($scope['negocio' + nid]) {
                otros[nid] = $scope['negocio' + nid].otrosProductos
              }
            })
            return otros
          },
          agendado: function() {
            return $scope.agendado
          },
          final: function() {
            return $scope.servicio
          },
          presentaciones_catalogos: function() {
            return $scope.presentaciones_catalogos
          },
          unidades_medida: function() {
            return $scope.unidades_medida
          },
          marcasCatalogo: function() {
            return $scope.marcasCatalogo
          },
          camionetas: function() {
            return $scope.camionetas
          },
          periodicidadesProducto: function () {
            return $scope.periodicidades || null
          },
          periodicidadUnica: function () {
            return $scope.periodicidadUnica || false
          }
        }
      })

      modalInstance.result.then(
        function(servicio) {
          $scope.servicio.agendado = servicio
          $scope.agendado = $scope.servicio.agendado
          $scope.servicio.periodicidadUnica = servicio.periodicidadUnica 
          $scope.periodicidadUnica = servicio.periodicidadUnica
        },
        function(servicio) {
          $log.info('Modal dismissed at: ' + new Date())
          $scope.servicio.agendado = servicio
          $scope.agendado = $scope.servicio.agendado
        }
      )
    }
  })
})

/**
 * Ilduomo Module
 *
 * configurarServicioModalInstanceCtrl para configurar el servicio arrastrado
 */
Ilduomo.controller('configurarServicioModalInstanceCtrl', function(
  $scope, 
  titulo,
  $rootScope,
  servicio,
  cliente,
  final,
  negocios,
  productos,
  otrosProductos,
  agendado,
  dataService,
  clienteService,
  presentaciones_catalogos,
  unidades_medida,
  marcasCatalogo,
  camionetas,
  periodicidadesProducto,
  periodicidadUnica,
  $uibModal,
  $log,
  $uibModalInstance
) {
  $scope.negocios = negocios
  $scope.negocio = negocios[0]
  $scope.negocioId = $scope.negocio._id
  $scope.cliente = cliente
  $scope.titulo = titulo
  $scope.presentaciones_catalogos = presentaciones_catalogos
  $scope.unidades_medida = unidades_medida
  $scope.marcasCatalogo = marcasCatalogo
  $scope.camionetas = camionetas
  $scope.agendado = agendado
  $scope.agendado.PrecioTotal = agendado.PrecioTotal || 0
  $scope.final = final
  $scope.Math = window.Math
  // negocios = _.sortBy(negocios, ['Nombre']);
  $scope.info = {}
  $scope.productos = {}
  $scope.cantidades = ['1','2','3','4','5','6','7','8','9','10']
    
  $scope.aux = {} 
  $scope.aux.periodicidadUnica = periodicidadUnica

  // final.Periodicidad =
  //   _.find($scope.cliente.periodicidades, function(p) {
  //     return p.NegocioId == $scope.negocioId
  //   }) || {}
  // final.PeriodicidadId = final.Periodicidad._id || null
  final.NegociosId = Array.from($scope.negocios, n => n._id)
  final.Negocios = negocios

  let auxServicio = {}

  _.forEach($scope.negocios, (n) => {
    $scope.info[n._id] = {}
    $scope.info[n._id].otrosProductos = otrosProductos[n._id]
    $scope.info[n._id].otrosProductosBack = otrosProductos[n._id]
    $scope.info[n._id].productos = _.sortBy(productos[n._id], 'Nombre')  

    $scope.productos[n._id] = []
    if ($scope.agendado.length > 0) {
      $scope.productos[n._id] = 
        _.filter($scope.agendado, a => {
          return a.NegocioId === n._id
        })
      $scope.productos[n._id].PrecioTotal =
        _.sumBy($scope.productos[n._id], (a) => {
          return a.touched ? a.precioProducto * a.cantidad : 0
        })
    }
  })

  $scope.switchNegocio = function (n) {
    $scope.negocio = n
    $scope.negocioId = n._id
  }
    
  $scope.selectMarca = function($item) {
    // console.log($item);
    $scope.info[$scope.negocioId].otrosProductos = 
      _.filter($scope.info[$scope.negocioId].otrosProductosBack, (opb) => {
        return opb.MarcaId == $item._id
      })
  }
  // console.log($scope.productos);
  $scope.makeLabels = function() {
    _.forEach($scope.info[$scope.negocioId].productos, function(p) {
      if (p.Existencias) {
        p.label = p.Nombre + ' (' + p.Existencias + ')'
      } else {
        p.label = p.Nombre
      }
    })
  }
  $scope.makeLabels()

  $scope.setPeriodicidadUnica = function() {
    if($scope.aux.periodicidadUnica) { 
      if($scope.periodicidadConjunto) { 
        $scope.agendado[0].periodicidad = $scope.periodicidadConjunto
      }
      $scope.agendadoTemp = Object.assign({}, $scope.agendado[0])  
    } else {
      if($scope.agendadoTemp && !$scope.periodicidadConjunto) {
        //si se regresa a per por producto, ya se tenian previamente las demás
        $scope.agendado[0] = Object.assign({}, $scope.agendadoTemp) 
      } else { 
        //si se regresa por primera vez a per por producto, se obtienen las periodicidades pasadas
        _.forEach($scope.agendado, function(agendado){
          _.find(periodicidadesProducto, function(p){
            if(p.periodicidadId === agendado.periodicidadId) {
              $scope.periodicidadConjunto = $scope.periodicidadConjunto || agendado.periodicidad
              //periodicidad anterior (cuando estaba por producto)
              agendado.periodicidad = Number(p.anterior) || 0 
              agendado.remanente = p.remanente || 0
            }
          })
        })
      }
    }  
  }

  $scope.tocar = function (agendado) {
    if ($scope.negocio.Periodicidad !== 'Producto') { return } 
    agendado.touched = !agendado.touched
    $scope.calculaTotal()
  }
  
  //Aqui se obtiene el desc/incremento del agendado y se asigna a la vista 
  var interDescuento = _.find($scope.agendado, a => {
    return a.nombreProducto == 'Descuento' || a.nombreProducto == 'Incremento'
  })
  if (interDescuento) {
    var indexDescuento = $scope.agendado.indexOf(interDescuento)
    $scope.agendado.slice(indexDescuento, 1)
    if (servicio) {
      servicio.descuento = servicio.descuento || {}
      servicio.descuento.tipo = interDescuento.nombreProducto
      servicio.descuento.Cantidad = Math.abs(interDescuento.precioProducto)
    }
  }

  $scope.servicio = servicio
  $scope.servicio.Duracion = $scope.servicio.Duracion || 30

  /**
   * Funcion que calcula el precio total de los servicios agendados
   * Modifica el NúMERO de servicios y el precio TOTAL
   */
  $scope.calculaTotal = function() {
    $scope.agendado.PrecioTotal = 
      _.sumBy($scope.agendado, function(a) {
        return a.touched || a.nombreProducto === 'Incremento' || a.nombreProducto === 'Descuento' ? a.precioProducto * a.cantidad : 0
      })
    $scope.productos[$scope.negocioId].PrecioTotal = 
      _.sumBy($scope.productos[$scope.negocioId], function(a) {
        return a.touched ? a.precioProducto * a.cantidad : 0
      })
    // servicio.Servicios = _.sumBy($scope.agendado,(a)=>{
    //   return a.nombreProducto != "Descuento" && a.nombreProducto != "Incremento";
    // });
    servicio.Servicios = 0
    _.forEach($scope.agendado, function(a) {
      if (
        a.nombreProducto != 'Descuento' &&
        a.nombreProducto != 'Incremento'
      ) {
        servicio.Servicios++
      }
    })
    final.PrecioTotal = $scope.agendado.PrecioTotal
    final.Servicios = servicio.Servicios
  }

  $scope.descuentoTipo = function(t) {
    if (t && servicio.descuento.Cantidad && servicio.descuento.Cantidad > 0) {
      // Siempre solamente tiene que regresar un objeto
      var i = $scope.agendado.findIndex(a => a.nombreProducto === 'Descuento' || a.nombreProducto === 'Incremento') 
      if (i !== -1) {
        $scope.agendado[i].precioProducto =
          t == 'Descuento'
            ? -servicio.descuento.Cantidad
            : servicio.descuento.Cantidad
        $scope.agendado[i].nombreProducto = 
          t === 'Descuento' ? 'Descuento' : 'Incremento'
      } else {
        $scope.agendado.push({
          nombreProducto:
            t == 'Descuento'
              ? 'Descuento'
              : 'Incremento',
          cantidad: '1',
          precioProducto: t == 'Descuento' ? -servicio.descuento.Cantidad : servicio.descuento.Cantidad,
        })
      }
      $scope.calculaTotal()
    }
  }

  $scope.$watch('servicio.descuento.Cantidad', function(n) {
    if(!n || servicio.descuento.Cantidad <= 0) {
      $scope.agendado = _.filter($scope.agendado, function(a){
        return a.nombreProducto !== 'Descuento' && a.nombreProducto !== 'Incremento'
      })
      $scope.calculaTotal()
    } 
    else if (n > 0) {
      // Siempre solamente tiene que regresar un objeto
      var i = $scope.agendado.findIndex(a => a.nombreProducto === 'Descuento' || a.nombreProducto === 'Incremento') 
      if (i !== -1) {
        $scope.agendado[i].precioProducto =
          servicio.descuento.tipo == 'Descuento' ? -n : n          
        $scope.calculaTotal()
      } else {
        if ($scope.servicio.descuento.tipo) {
          $scope.agendado.push({
            nombreProducto:
              servicio.descuento.tipo == 'Descuento'
                ? 'Descuento'
                : 'Incremento',
            cantidad: '1',
            precioProducto: servicio.descuento.tipo == 'Descuento' ? -n : n,
          })
          $scope.calculaTotal()
        }
      }
    }
  })

  $scope.calculaTotal()

  // $scope.select = function (negocio) {
  //   $scope.selected = negocio;
  //   if (negocio.Nombre == 'Estética') {
  //     $scope.productos = serviciosOfrecidos;
  //     $scope.hazLabels();
  //   }else {
  //     if (!negocio.productos) {
  //       dataService.getProductosNegocio($scope.selected._id, function (response) {
  //         $scope.productos = response.data;
  //         negocio.productos = response.data;
  //         $scope.hazLabels();
  //       });
  //     }else{
  //       $scope.productos = negocio.productos;
  //       $scope.hazLabels();
  //     }
  //   }
  // }

  $scope.selectServicioEstetica = $item => {
    $scope.selectServicio = $item
  }

  $scope.getPrecio = function(mascota) {
    auxServicio.MascotaId = mascota._id
    auxServicio.nombreMascota = mascota.Nombre
    auxServicio.razaMascota = mascota.Raza.Nombre
    auxServicio.touched = true //servicios de Estética por defecto seleccionados
    if (!mascota[$scope.selectServicio.Nombre]) {
      var unselectedService = _.find($scope.agendado, function(a) {
        return (
          a.nombreProducto === $scope.selectServicio.Nombre &&
          a.MascotaId === mascota._id
        )
      })
      if (unselectedService) {
        $scope.dropAgendadoServicio(unselectedService)
      }
    } else {
      if (
        $scope.negocio.Nombre == 'Estética'
      ) {
        dataService.getPrecioEstetica(
          cliente.FranquiciaId,
          mascota.Raza.TamanoId,
          $scope.selectServicio.Nombre,
          function(response) {
            auxServicio.nombreProducto = response.data.Nombre
            auxServicio.precioProducto = response.data.Precio
            auxServicio.ProductoId = response.data._id
            auxServicio.cantidad = '1'
            auxServicio.NegocioId = $scope.negocioId
            $scope.agendado.push(auxServicio)
            $scope.productos[$scope.negocioId].push(auxServicio)
            $scope.calculaTotal()
            auxServicio = {}
          }
        )
      }
    }
  }

  $scope.getPrecioCompra = function(m) {
    if (m.compraDeProductos) {
      $scope.agendado.push({
        MascotaId: m._id,
        nombreMascota: m.Nombre,
        nombreProducto: $scope.servicio.compras.Nombre,
        ProductoId: $scope.servicio.compras.productoId,
        precioProducto: $scope.servicio.compras.Precio,
        cantidad: '1',
        dejar: true
      })
      $scope.calculaTotal()
      setTimeout(function() {
        delete m.compraDeProductos
        $scope.$apply()
      }, 50)
    }
  }

  function calculaFinal(duracion) {
    $rootScope.overload = null
    final.Duracion = duracion
    let horaFinal = new Date(final.inicio.getTime())
    horaFinal.setMinutes(horaFinal.getMinutes() + parseInt(duracion))
    final.endMillis = horaFinal.getTime()
    var cam = _.find($scope.camionetas, c => c._id == final.CamionetaId)

    if (final.inicio.getMinutes() == 0) {
      var time =
        'Servicios' +
        final.inicio.getHours() +
        'to' +
        final.inicio.getHours() +
        '30'
    } else {
      var time =
        'Servicios' +
        final.inicio.getHours() +
        '' +
        final.inicio.getMinutes() +
        'to' +
        (final.inicio.getHours() + 1)
    }
    $scope.fechaCamioneta =
      horaFinal.getDayFormatted() +
      '-' +
      horaFinal.getMonthFormatted() +
      '-' +
      horaFinal.getFullYear()
    var times =
      parseInt(cam[$scope.fechaCamioneta][time].servicios.Duracion) / 30
    var intermedio = new Date(final.inicio.getTime())
    delete cam[$scope.fechaCamioneta][time].overload
    for (var i = 1; i < times; i++) {
      intermedio.setMinutes(intermedio.getMinutes() + 30)
      if (intermedio.getMinutes() == 0) {
        var stringInter =
          'Servicios' +
          intermedio.getHours() +
          'to' +
          intermedio.getHours() +
          '30'
      } else {
        var stringInter =
          'Servicios' +
          intermedio.getHours() +
          '' +
          intermedio.getMinutes() +
          'to' +
          (intermedio.getHours() + 1)
      }
      if (cam[$scope.fechaCamioneta][stringInter]) {
        if (cam[$scope.fechaCamioneta][stringInter].servicios) {
          cam[$scope.fechaCamioneta][time].overload =
            'El servicio se transpone con otro a las: ' +
            intermedio.getHours() +
            ':' +
            intermedio.getMinutes()
          $rootScope.overload = true
        }
      }
    }
    // console.log($scope.final);
  }
  calculaFinal($scope.servicio.Duracion)

  $scope.$watch('servicio.Duracion', function(newValue) {
    calculaFinal(newValue)
  })

  $scope.dropAgendadoServicio = function(service) {
    let index = $scope.agendado.indexOf(service)
    $scope.agendado.splice(index, 1)
    index = $scope.productos[$scope.negocioId].indexOf(service)
    $scope.productos[$scope.negocioId].splice(index, 1)
    $scope.removePetCheckmark($scope.cliente.listaMascotas, service)
    $scope.calculaTotal()
  }

  $scope.removePetCheckmark = function (pets, service) {
    let pet = _.find(pets, pet => pet._id === service.MascotaId)
    pet[service.nombreProducto] = false
  }

  $scope.getMedida = function(mid) {
    // console.log(mid);
    if (mid == null || mid == undefined) return ''
    var n =
      _.find($scope.unidades_medida, function(um) {
        return um._id == mid
      }) || {}
    return n.Nombre || ''
  }
  $scope.getPresentacion = function(pid) {
    if (!pid) return ''
    var n =
      _.find($scope.presentaciones_catalogos, function(pc) {
        return pc._id == pid
      }) || {}
    return n.Nombre || ''
  }

  /**
   *
   * Funcion que selecciona los productos de NUTRI (ya con marca y producto)
   *
   */
  $scope.selectOption = function(item, negocioId, periodicidad = null) {
    // $scope.servicio[$scope.negocio.Nombre] = item
    // auxServicio.nombreProducto =
    //   $scope.servicio[$scope.negocio.Nombre].producto.Nombre +
    //   ' ' +
    //   $scope.servicio[$scope.negocio.Nombre].Presentacion +
    //   ' ' +
    //   $scope.getMedida($scope.servicio[$scope.negocio.Nombre].MedidaId) +
    //   ' (' +
    //   $scope.getPresentacion(
    //     $scope.servicio[$scope.negocio.Nombre].PresentacionId
    //   ) +
    //   ')'
    // auxServicio.precioProducto = $scope.servicio[$scope.negocio.Nombre].Precio
    // auxServicio.ProductoId = $scope.servicio[$scope.negocio.Nombre]._id
    // auxServicio.marcaNombre =
    //   $scope.servicio[$scope.negocio.Nombre].MarcaNombre
    auxServicio.nombreProducto =
      item.producto.Nombre +
      ' ' +
      item.Presentacion +
      ' ' +
      $scope.getMedida(item.MedidaId) +
      ' (' +
      $scope.getPresentacion(item.PresentacionId) +
      ')'
    auxServicio.precioProducto = item.Precio
    auxServicio.marcaNombre = item.MarcaNombre
    auxServicio.cantidad = '1'
    auxServicio.remanente = 0
    if (periodicidad) { 
      auxServicio.periodicidadId = periodicidad.periodicidadId
      auxServicio.reagendado = periodicidad.reagendado 
      auxServicio.remanente = periodicidad.remanente
    } 
    auxServicio.ProductoId = item._id
    auxServicio.periodicidad = periodicidad ? periodicidad.periodicidad : 0 //dias de la periodicidad
    auxServicio.touched = periodicidad ? false : true
    auxServicio.NegocioId = negocioId
    $scope.agendado.push(auxServicio)
    $scope.productos[negocioId].push(auxServicio)
    $scope.calculaTotal()
    auxServicio = {}
  }

  if (periodicidadesProducto && $scope.agendado.length === 0) {
    _.forEach(periodicidadesProducto, (periodicidad) => {
      if ($scope.info[periodicidad.negocioId].otrosProductos) {
        let producto = _.find($scope.info[periodicidad.negocioId].otrosProductos, function(op) {
          return periodicidad.productoId === op._id
        })
        if (producto) {
          $scope.selectOption(producto, periodicidad.negocioId, periodicidad)
        }
      }
    })
  }

  $scope.dropAgendadoPeriodicidad = function (agendado) {
    let payload = {
      ProductoNombre: agendado.marcaNombre + ' - ' + agendado.nombreProducto,
      Periodicidad: agendado.periodicidad 
    }
    let modalInstance = $uibModal.open({
      templateUrl:'alertDropPeriodicidad.html',
      controller:'dropPeriodicidadModalInstance',
      size:'md',
      resolve: {
        periodicidad:function() {
          return payload
        }
      }
    })
    modalInstance.result.then(
      function(periodicidad){
        clienteService.dropPeriodicidadProducto(agendado.periodicidadId, function(response) {
          if(response.data.Estatus === 'False') {
            iziToast.error({
              title: 'Error',
              message: 'Al borrar periodicidad de producto',
              position: 'bottomRight',
              timeout: 2000
            })
          } else {
            iziToast.success({
              title: 'Periodicidad borrada',
              message: 'Borrado realizado con exito',
              position: 'bottomRight',
              timeout: 2300
            })
            $scope.dropAgendadoServicio(agendado)
          }
        })
      }, function() {
        $log.info('Modal dismissed at: ' + new Date())
      }
    )
  }

  $scope.refresh = function(buscar) {
    if (!buscar) return
    dataService.cargarProductos(buscar, function(response) {
      $scope.info[$scope.negocioId].otrosProductos = response.data
    })
  }

  $scope.checkNegocios = function() {
    let value = true
    _.forEach($scope.negocios, (negocio) => {
      if (
        !_.find($scope.productos[negocio._id], p => p.touched === true)
       ) {
        iziToast.show({
          color: 'yellow',
          timeout: 1000,
          title: 'Mensaje',
          icon: 'ico-warning',
          close: true,
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          layout: 3,
          message: "No se agendó " + negocio.Nombre,
          position: 'topRight'
        })
        value = false
      }
    })
    return value
  }

  $scope.cancel = function() {
    if($scope.aux && $scope.aux.periodicidadUnica && $scope.agendadoTemp) { 
      $scope.agendado[0] = Object.assign({}, $scope.agendadoTemp) 
    }
    $uibModalInstance.dismiss($scope.agendado)
  }
  $scope.ok = function() {
    if (!$scope.checkNegocios()) return
    $scope.agendado = _.filter($scope.agendado, a => {
      return (
        a.nombreProducto != 'Incremento' && a.nombreProducto != 'Descuento' && a.touched
      )
    })
    if (
      $scope.servicio.descuento &&
      $scope.servicio.descuento.Cantidad != 0
    ) {
      $scope.agendado.push({
        nombreProducto: $scope.servicio.descuento.tipo,
        cantidad: '1',
        NegocioId: $scope.negocioId,
        precioProducto:
          $scope.servicio.descuento.tipo == 'Descuento'
            ? -$scope.servicio.descuento.Cantidad
            : $scope.servicio.descuento.Cantidad
      })
    }
    $scope.calculaTotal()
    if($scope.negocio.Periodicidad === 'Producto') { 
      $scope.agendado.periodicidadUnica = $scope.aux.periodicidadUnica
      if($scope.aux.periodicidadUnica) { //Si se maneja como periodicidad única
        _.forEach($scope.agendado, function (a, key) {
          if(
            typeof $scope.agendado[key] === 'object' && 
            $scope.agendado[key].nombreProducto !== 'Descuento' && 
            $scope.agendado[key].nombreProducto !== 'Incremento'
          ) {
            $scope.agendado[key].periodicidad = $scope.agendado[0].periodicidad
            if($scope.agendado[0].remanente && $scope.agendado[0].remanente > 0) {
              $scope.agendado[key].remanente = $scope.agendado[0].remanente
            }
          }
        })
      }
    }
    $uibModalInstance.close($scope.agendado)
  }
})

Ilduomo.controller('confirmaryAgendarInstanceController', function(
  $scope,
  $uibModalInstance
) {
  $scope.ok = function() {
    $uibModalInstance.close(true)
  }

  $scope.cancel = function() {
    $uibModalInstance.close(false)
  }
})

Ilduomo.controller('descuentoIncrementoModalInstance', function(
  $scope,
  titulo,
  $uibModalInstance
) {
  $scope.r = {}
  $scope.r.tipo = 'Descuento'
  $scope.titulo = titulo
  $scope.ok = function() {
    $uibModalInstance.close($scope.r)
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

Ilduomo.controller('calendarioRutasModalInstance', function(
  $scope,
  titulo,
  $uibModalInstance,
  negocioId,
  dataService
) {
  $scope.titulo = titulo
  $scope.camionetas = []
  $scope.semanaRuta = titulo
  dataService.getCamionetaNegocioId(negocioId, function(response) {
    $scope.camionetas = response.data
  })
  $scope.ok = function() {
    $uibModalInstance.close()
  }
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel')
  }
})

// Ilduomo.directive('negociosRequeridos', function())

// Ilduomo.controller('confirmarDropPeriodicidadInstanceController', function(
//   $scope,
//   $uibModalInstance
// ) {
//   $scope.ok = function() {
//     $uibModalInstance.close(true)
//   }

//   $scope.cancel = function() {
//     $uibModalInstance.close(false)
//   }
// })
